.membership-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $alabaster-second;
    padding: 16px 0;
    &.special-icon {
        .fa-tag {
            width: 14px;
            height: 16px;
        }
        .fa-address-book {
            width: 15px;
            height: 16px;
        }
        .membership-cta__text {
            padding-left: 8px;
        }
        .membership-cta__bottom {
            padding-left: 7px;
        }
        .membership-cta__top {
            margin-bottom: 2px;

            @include mq($lg) {
                margin-bottom: 0;
            }
        }
    }
}

.membership-cta__top {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: $boulder2;
    margin-bottom: 10px;
}

.membership-cta__icon {
    line-height: 1;
    margin-bottom: 8px;

    svg {
        width: 13px;
        height: 14px;

        @include mq($lg) {
            height: 12px;
        }
    }
}

.membership-cta__text {
    font-size: 12px;
    font-family: $font-primary;
    line-height: 1;

    @include mq($xl) {
        font-size: 14px;
        font-weight: 500;
    }

    @include mq($lg) {
        font-size: 12px;
    }
}

.membership-cta__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
}

.membership-cta__link {
    color: $jungle-green2;
    position: relative;
    padding: 0;
    font-size: 14px;
    line-height: 1;
    height: auto;

    @include mq($lg) {
        font-size: 10px;
        line-height: 16.61px;
    }

    &::after {
        content: "";
        height: 1px;
        width: 0;
        opacity: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $jungle-green2;
        transition: $dur $ease;
    }

    &:focus,
    &:visited {
        color: $jungle-green2;
    }

    @include hover {
        color: $jungle-green2;

        &::after {
            opacity: 1;
            width: 100%;
        }
    }
}
