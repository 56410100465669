#cookie__bckgrnd {
    height: 100vh;
    width: 100vw;
    background-image: URL("../../public/listing-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
}

#cookie__mdl {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    height: 412px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
        0px 0px 24px rgba(0, 0, 0, 0.22);
    border-radius: 10px;
}

#cookie__img {
    position: absolute;
    top: 84px;
    left: 222px;
    width: 156.77px;
    height: 136px;
}

#cookie__hdr {
    position: absolute;
    top: 236px;
    width: 100%;
    font-family: $font-third;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: #03363d;
}

#cookie__wrpr {
    position: absolute;
    top: 280px;
    left: 111px;
}

#cookie__prgrph {
    width: 378px;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #757575;
}

.cookies__brwn-txt {
    color: #5a412f;
}
