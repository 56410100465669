/*--------------------------------------------------------------
## Main Variables
--------------------------------------------------------------*/
// Fonts
$font-primary: "ABCMonumentGrotesk", Arial, Helvetica, sans-serif;
$font-secondary: "Times New Roman", Arial, Helvetica, sans-serif;
$font-safari: "Work Sans", Arial, Helvetica, sans-serif;
$font-third: "Gilroy", Arial, Helvetica, sans-serif;

$font-size: 14px;
$line-height: 20px;

// Colors
$white: #fff;
$black: #000;
$dark-gray: #2f3034;
$orange: #d4461a;

$container: 1240px;
$container-md: 1570px;
$container-large: 1820px;
$basePadding: 25px;

$green-light: #e2f6f3;
$blue-light: #dcf8ff;
$blue-whale: #03363d;
$aqua-spring: #e9f9f7;

$main-dark: #040707;
$main-green: #3ebfac;
$jungle-green: #1f9f8a;

$main-light: #fbf6f0;
$main-medium: #e4d2be;
$catskill-white: #e8edf3;
$burning-orange: #fa6939;
$piper: #cc522a;
$boulder: #777777;
$boulder2: #757575;
$alto: #dedede;
$alto-dark: #d8d8d8;
$mercury: #e4e4e4;
$alabaster: #f8f8f8;
$alabaster-light: #f7f7f7;
$silver: #c3c3c3;
$viking: #6fc0d5;
$cloudy: #cbcbcb;
$clear-cloud: #e0e0e0;
$gray: #a2acba;
$jungle-green2: #22aa94;
$burn-orange: #fa6c3d;
$alabaster-second: #fafafa;
$red: #e91c1c;

// Transitions
$dur: 300ms;
$ease: ease;

/*--------------------------------------------------------------
## Grid Variables
--------------------------------------------------------------*/
$enable-grid-classes: true !default;

$grid-breakpoints: (
    xs: 416px,
    sm: 576px,
    md: 768px,
    lg: 993px,
    xl: 1201px,
    xxl: 1400px
) !default;

// Grid containers
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1286px
) !default;

/*
	Breakpoints for max-width.

	These variables are mapped with $grid-breakpoints SCSS map variable which is used for bootstrap.
	The reason for this setup is that it is needed to have variables that reflect bootstrap's breakpoints.
	Bootstrap is using min-with and this starter max-with approach, so bootstrap's breakpoint at 992px (min-width)
	is reflected as 991px (max-width), thus the mapped variables bellow.
 */

$xs: map-get($grid-breakpoints, xs) - 1;
$sm: map-get($grid-breakpoints, sm) - 1;
$md: map-get($grid-breakpoints, md) - 1;
$lg: map-get($grid-breakpoints, lg) - 1;
$xl: map-get($grid-breakpoints, xl) - 1;
$xxl: map-get($grid-breakpoints, xxl) - 1;
$xs-phone: 400px;
$phone: 660px;
$tablet-md: 991px;
$tablet-large: 1024px;
$lap-small: 1280px;
$lap-mid: 1310px;
$lap-large: 1440px;
$lap-big: 1620px;

// Grid columns
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
