/*--------------------------------------------------------------
## Toggle Component Style
--------------------------------------------------------------*/

.toggle {
    margin: 10px;
    z-index: 1;
    position: absolute;
    cursor: pointer;
    right: 51px;
    top: 70px;
    border-radius: 8px;
    bottom: 35px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    display: flex;
    transition: 0.4s ease-in-out;
    height: 40px;
    border-radius: 4px;
    background: var(--white, #FFF);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    @media screen and (max-width: 767px) {
        top: 50px;
    }
    span{
        color: var(--grayscale-primary-gray, #757575);
        text-align: right;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family:  Roboto, Arial, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        padding: 14px;
    }
    span.active{
        text-align: right;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family:  Roboto, Arial, sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        padding: 14px;
        color: rgb(0, 0, 0); 
        font-weight: 500;
    }
    span:hover{
        cursor: pointer;
        color: var(--Primary, #FFF);
        text-align: right;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family:  Roboto, Arial, sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        background-color: #03363D;
    }
    .gmnoprint{
        display: none;
    }
}
