#interest {
    overflow-y: auto;
    @include scroll-style($boulder, $alabaster, 0, 0.4vw, 0);
    @media screen and (max-width: 767px) {
        &.ratesOpen {
            z-index: inherit;
            position: fixed;
            top: 70px;
            background-color: $white;
            height: calc(100vh - 70px);
            overflow: hidden;
            padding: 0 15px;
            width: 100%;
        }
        &.ratesClosed {
            height: 0;
        }

        &.interest__tbody {
            transform: translateY(-2px);
        }
    }

    // Supports query for dvh
    @supports (height: 100dvh) {
        @media screen and (max-width: 767px) {
            &.RatesOpen {
                height: calc(100dvh - 70px);
            }
        }
    }
}

.interest__contact {
    background: #eff7f9;
    border-radius: 4px;
    color: #03363d;
    padding: 10px 15px;
    p {
        position: relative;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        span {
            color: $jungle-green;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }
}

.interest__scrollable-table {
    overflow-x: scroll;
    height: calc(100vh - 310px);
    @include scroll-style($boulder, $alabaster, 0, 0.4vw, 0);
    padding-bottom: 15px;
}

@media screen and (max-width: 767px) {
    .interest__scrollable-table {
        height: calc(100vh - 230px);
    }
    .interest__term-column {
        max-width: 25vw;
        box-sizing: border-box;
    }
    .interest__loan-program-column {
        max-width: 25vw;
    }
    .interest__rate-min-column {
        max-width: 25vw;
    }
    .interest__rate-max-column {
        max-width: 25vw;
    }
    .interest__disclaimer {
        max-width: 100px;
        text-align: left !important;
    }
    .interest__table {
        margin-left: 0px !important;
        width: 100%;
    }
    .interest__info {
        position: absolute;
    }
    // .interest__display-table {
    //     position: relative;
    // }
}

.interest__table {
    margin-left: 14px;
    thead {
        tr {
            th {
                padding-bottom: 12px;
            }
        }
    }
}

.interest__info-loan {
    max-height: 14px;
    margin-left: 2px;
    color: $gray;
}

.interest__info-fannie-mae {
    position: relative;
    div {
        position: absolute;
        top: -87px;
        left: -113px;
        width: 245px;
        height: 76px;
        font-size: 12px;
        line-height: 18px;
        background-color: $blue-whale;
        font-weight: 400;
        color: $white;
        z-index: 100000000000;
        display: none;
        transition: 0.2s ease;
        font-weight: 400;
        font-family: $font-primary;
        &::after {
            content: "";
            position: absolute;
            width: 10;
            height: 8;
            border-width: 11px 7px 0 7px;
            border-color: $blue-whale transparent transparent transparent;
            border-style: solid;
            top: 74px;
            left: 115px;
        }
        p {
            padding: 14px;
            font-family: $font-primary;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }
    }

    svg {
        &:hover + div {
            opacity: 1;
            display: block;
            visibility: visible;
        }
    }
}

.interest__info-freddie-mac {
    position: relative;
    div {
        position: absolute;
        top: -87px;
        right: -115px;
        width: 245px;
        height: 76px;
        font-size: 12px;
        line-height: 18px;
        background-color: $blue-whale;
        font-weight: 400;
        color: $white;
        z-index: 100000000000;
        display: none;
        transition: 0.2s ease;
        font-weight: 400;
        font-family: $font-primary;
        &::after {
            content: "";
            position: absolute;
            width: 10;
            height: 8;
            border-width: 13px 8px 0 8px;
            border-color: $blue-whale transparent transparent transparent;
            border-style: solid;
            top: 72px;
            left: 115px;
        }
        p {
            padding: 14px;
            font-family: $font-primary;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }
    }

    svg {
        &:hover + div {
            opacity: 1;
            display: block;
            visibility: visible;
        }
    }
}

.interest__info-CMBS {
    position: relative;
    div {
        position: absolute;
        top: -71px;
        left: -112px;
        width: 245px;
        height: 60px;
        font-size: 12px;
        line-height: 18px;
        background-color: $blue-whale;
        font-weight: 400;
        color: $white;
        z-index: 100000000000;
        display: none;
        transition: 0.2s ease;
        font-weight: 400;
        font-family: $font-primary;
        &::after {
            content: "";
            position: absolute;
            width: 10;
            height: 8;
            border-width: 11px 7px 0 7px;
            border-color: $blue-whale transparent transparent transparent;
            border-style: solid;
            top: 58px;
            left: 115px;
        }
        p {
            padding: 14px;
            font-family: $font-primary;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }
    }

    svg {
        &:hover + div {
            opacity: 1;
            display: block;
            visibility: visible;
        }
    }
}

.interest__info {
    margin-left: 4px;

    color: $gray;
    .interest__rate-min-info {
        background-color: #03363d;
        color: #fff;
        display: none;
        font-size: 12px;
        font-weight: 400;
        height: 122px;
        line-height: 18px;
        padding: 12px;
        position: absolute;
        right: 28px;
        top: 124px;
        transition: 0.2s ease;
        width: 386px;
        z-index: 100000000000;
        font-family: $font-primary;
        &::after {
            content: "";
            position: absolute;
            width: 10;
            height: 8;
            border-width: 11px 7px 0 7px;
            border-color: $blue-whale transparent transparent transparent;
            border-style: solid;
            top: 122px;
            left: 256px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 10px;
        }

        .interest__perfect-deal {
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
        }
    }

    .interest__rate-min-info-mobile {
        background-color: #03363d;
        color: #fff;
        display: none;
        font-family: ABCMonumentGrotesk, Arial, Helvetica, sans-serif;
        font-size: 12px;
        font-weight: 400;
        height: 122px;
        line-height: 18px;
        padding: 12px;
        position: absolute;
        right: -29vw;
        top: 30px;
        transition: 0.2s ease;
        width: 386px;
        z-index: 100000000000;
        font-family: $font-primary;

        &::after {
            content: "";
            position: absolute;
            width: 10;
            height: 8;
            border-width: 11px 7px 11px 7px;
            border-color: transparent transparent $blue-whale transparent;
            border-style: solid;
            top: -22px;
            right: 28.8vw;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 10px;
        }

        .interest__perfect-deal {
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
        }
    }

    @include hover {
        .interest__rate-min-info-all {
            z-index: 101;
            display: block !important;
        }
    }
    svg {
        &:hover + div {
            opacity: 1;
            display: block;
            visibility: visible;
        }
    }
}

.interest__gray-row {
    text-align: left;
    //padding-top: 8px;
    min-width: 122px;
    text-align: left;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 4px;
}

.interest__term-column {
    max-height: 18px;
    text-align: left;
    padding-top: 8px;
    padding-left: 16px;
    min-width: 106px;
    text-align: left;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
}

.interest__loan-program-column {
    text-align: left;
    width: 211px;
    padding-top: 8px;
    text-align: left;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 4px;
}

.interest__rate-min-column {
    text-align: left;
    overflow: hidden;
    width: 97px;
    padding-top: 8px;
    text-align: left;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 4px;
}

.interest__rate-max-column {
    text-align: left;
    padding-top: 8px;
    text-align: left;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 4px;
}

.interest__rate-headers {
    padding-top: 16px;
    color: #03363d;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500 !important;
}

.interest__tbody {
    width: 100%;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25),
        0px 0px 24px rgba(0, 0, 0, 0.08);
}

.interest__gray-row {
    background-color: #eff7f9;
    color: $boulder2;
}

.interest__white-row {
    padding-top: 8px;
    background-color: #ffffff;
    color: $blue-whale;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    td {
        font-family: $font-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: $blue-whale;
        input {
            margin-right: 27px;
            width: 70px;
            height: 22px;
            border: 1px solid #c9cccf;
            border-radius: 4px;
        }
        .interest__percent-sign {
            font-family: $font-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            top: 17px;
            left: 57px;
            color: #555;
            position: relative;
            z-index: 1;
        }
    }
}

.interest__line {
    display: block;
    margin-left: 18px;
    height: 115px;
    width: 1px;
    background: #e0e0e0;
    background-position: center;
    //margin-bottom: 16px;
}

.interest__spanned {
    max-height: 12px;
    padding: 12px 0 0 0;
    font-size: 12px;
    line-height: 1px;
    display: table-cell;
    box-sizing: content-box;
    max-height: 24px;
}

.interest__spanned-info {
    padding-top: 11px;
}

.interest__spanned-CMBS {
    padding-top: 12px;
}

.interest__spanned-first {
    padding-top: 3px;
}

.interest__spanned-last-row {
    padding-bottom: 18px;
}

.interest__spanned-last {
    padding-bottom: 16px;
}

.interest__disclaimer {
    vertical-align: bottom;
    box-sizing: border-box;
    padding-right: 16px;
    min-width: 390px;
    text-align: right;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
}
