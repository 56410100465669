.dashboard {
    position: absolute;
    z-index: 2;
    background: $alabaster-second;
    top: 0;
    bottom: 0;
    height: 100dvh;
    width: 100%;
    padding: 87px 24px 85px;
    box-shadow: inset 0px 3px 6px 0px #e3e3e3;
    overflow: scroll;
    h2 {
        font-size: 14px;
        line-height: 14px;
        color: $blue-whale;
        font-family: $font-primary;
        margin-bottom: 8px;
    }
    @media screen and (min-width: 768px) {
        display: none;
    }
}

.dashboard__tools-wrapper {
    margin-bottom: 8px;
}

.dashboard-search {
    margin-bottom: 32px;
}

.dashboard-tool {
    display: flex;
    align-items: center;
    background-color: $white;
    margin-bottom: 12px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    position: relative;

    &:nth-last-child(1) {
        margin-bottom: 0;
    }

    span {
        margin-left: 16px;
        font-size: 14px;
        line-height: 14px;
        color: $blue-whale;

        @include mq(350px) {
            font-size: 12px;
            margin-left: 10px;
        }
    }

    svg {
        color: $jungle-green2;
        margin-left: 1px;
        font-size: 16px;
    }

    @include mq(384px) {
        padding-right: 10px;
    }
}

.dashboard-tool--disabled {
    span {
        color: #757575;

        @include mq(445px) {
            max-width: 114px;
        }
    }
}

.dashboard-tool__pill {
    width: 50px;
    height: 22px;
    background: rgba(34, 170, 148, 0.22);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 12px;
    color: $jungle-green2;
    margin-left: 12px;
}

.dashboard-tool__pill--acquisition-calc {
    width: 50px;
    height: 22px;
    background: rgba(34, 170, 148, 0.22);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 12px;
    color: $jungle-green2;
    margin-left: 4px;
}

.dashboard-tool__pill--cooming-soon {
    width: auto;
    border-radius: 12px;
    padding: 2px 12px;
    background: rgba(51, 102, 204, 0.15);
    color: #3366cc;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq(445px) {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.dashboard-tool__pill--news {
    width: auto;
    border-radius: 12px;
    padding: 2px 12px;
    background: none;
    color: $blue-whale;

    @include mq(767px) {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.dashboard-tool__new-feature-new {
    padding: 4px 12px;
    display: inline-block;
    height: 22px;
    background: rgba(34, 170, 148, 0.22);
    border-radius: 12px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #22aa94 !important;
    &.portfolio-new {
        flex-shrink: 0;
        margin: 0 10px 0 16px;
    }
}

.dashboard-tool__new-feature-prop {
    padding: 4px 12px;
    display: inline-block;
    height: 22px;
    background: rgba(34, 170, 148, 0.22);
    border-radius: 12px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #22aa94 !important;
}

.dashboard-tool__icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    background: linear-gradient(90deg, #6fc0d5 0%, #3ebfac 100%);
    border-radius: 4px 0px 0px 4px;
    svg {
        font-size: 24px;
        color: $white;
    }

    @include mq(350px) {
        width: 60px;
        height: 60px;
    }
}

.dashboard-tool__icon--leasing-admin {
    svg, path {
        fill: $white;
    }
}

.dashboard-tooltip {
    background-color: $white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 8px 7px 8px 16px;
}

.dashboard-tooltip__text-bold {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $blue-whale;
    margin-bottom: 18px;

    svg {
        color: $jungle-green2;
        margin-right: 9px;
    }
}

.dashboard-tooltip__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $boulder2;
}

.mobile-map-btn {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
    z-index: 6;
    width: 82px;
    height: 40px;
    box-shadow:
        0px 4px 4px rgba(0, 0, 0, 0.25),
        0px 4px 5px rgba(0, 0, 0, 0.14),
        0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    background-color: $blue-whale;
    color: $white;
    font-size: 14px;
    line-height: 14px;
    border: none;
    svg {
        font-size: 14px;
        margin-right: 4px;
    }
    @media screen and (min-width: 768px) {
        display: none;
    }
}

.dashboard__interest-tool {
    color: $jungle-green2;
    position: relative;
    margin-left: 0px !important;

    .dashboard-interest-info {
        margin-left: 16px;
        box-shadow:
            0px 4px 5px rgba(0, 0, 0, 0.14),
            0px 1px 10px rgba(0, 0, 0, 0.12),
            0px 2px 4px -1px rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        position: absolute;
        top: 50px;
        left: -211px;
        width: 90vw;
        height: fit-content;
        border-radius: 8px;
        padding-left: 16px;
        display: none;
        h5 {
            padding-top: 8px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: $blue-whale;
            padding-right: 8px;
            .dashboard__font-icon {
                color: $jungle-green2;
                margin-right: 8.09px;
                margin-left: 0;
            }
        }
        p {
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            margin-top: 16px;
            color: $boulder2;
            padding-bottom: 8px;
            padding-right: 8px;
            span {
                color: $boulder2;
                font-weight: 400;
                padding: 0;
                margin: 0;
            }
        }
    }

    &:hover {
        .dashboard-interest-info {
            display: block;
        }
    }
}
