@include mq($md) {
    .property-card {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        right: auto;
        max-width: 360px;
        min-width: 360px;
        z-index: 100;
    }

    .property-card__address-text {
        font-size: 16px;
        line-height: 18px;
    }

    .property-card__heading {
        padding: 12px 40px 10px 14px;

        .property-card__address-icon {
            display: flex;
            margin-right: 8px;
        }
    }

    .property-card__close {
        top: 32px;
        right: 20px;
    }

    .property-card__type {
        span {
            font-size: 12px;
        }
    }

    .property-card__public-group {
        h4 {
            font-family: $font-primary;
            padding-bottom: 1px;
        }
    }

    .property-card__tab {
        padding: 20px 16px 40px;
    }

    .property-card__public-name,
    .property-card__public-value {
        font-family: $font-primary;
    }

    .property-card__public-group {
        margin-bottom: 21px;
    }
}

@include mq(400px) {
    .property-card {
        position: fixed;
        top: 70px;
        left: 0;
        right: 0;
        transform: translate(0);
        min-width: 100%;
        right: auto !important;
        border-radius: 0;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.22),
            0px 24px 24px rgba(0, 0, 0, 0.3);
        z-index: 199;
    }
}

.property-card__tabs {
    @media screen and (max-width: 640px) and (max-height: 618px) {
        .ant-tabs-content-holder {
            height: 212px;
        }
    }
}
