// NOTES
.property-card__tab--notes {

    * {
        font-family: $font-primary;
    }
    min-height: 236px;
    padding: 15px 24px;
    .property-card__link {
        font-weight: 500;
        padding: 0;
        width: unset;
        background-color: transparent;
        margin-bottom: 14px;
        color: $jungle-green2;
        svg {
            width: 13px;
            height: 14px;
            margin-right: 3px;
            margin-top: -1px;
        }
        @include hover {
            opacity: 0.8;
        }
    }
    .ant-spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        svg {
            color: $jungle-green2;
        }
    }
}

.property-card__notes-wrap {
    padding-bottom: 1px;
}

.property-card__note {
    margin-bottom: 16px;
    p {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $blue-whale;
        margin-bottom: 4px;
    }
}

.property-card__note-wrap {
    display: flex;
    align-items: center;
}

.property-card__note-date,
.property-card__note-edit {
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: $boulder2;
}

.property-card__note-edit {
    margin-left: 8px;
    color: $jungle-green2;
}

.property-card__no-notes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    > svg {
        font-size: 60px;
        color: $clear-cloud;
        margin-bottom: 8px;
    }
    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $boulder2;
        &:last-of-type {
            font-size: 12px;
        }
    }
    button {
        font-size: 14px;
        line-height: 20px;
        margin-top: 24px;
        svg {
            margin-top: 1px;
        }
    }
}

.property-card__add-note {
    h3 {
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        color: $blue-whale;
        margin-bottom: 15px;
    }
    textarea {
        height: 128px;
        border: 1px solid #c9cccf !important;
        box-shadow: none;
        border-radius: 4px;
        padding: 8px 12px;
        margin-bottom: 4px;
        font-family: $font-third;
        font-weight: 400;
        color: $boulder2;
        @include scroll-style($boulder, $alabaster, 0, 0.36vw, 0);
        &::placeholder {
            font-family: $font-third;
            color: $boulder2;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
        }
    }
    p {
        font-size: 12px;
        line-height: 16px;
        color: $boulder2;
    }
}

.property-card__note-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 19px;
    .btn--secondary {
        margin-left: auto;
        margin-right: 24px;
        color: $boulder2;
    }
}

.property-card__note-edit {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}
