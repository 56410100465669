.dashboard__property-search-wrapper {
    margin-bottom: 32px;
}

.mobile-property-search {
    position: fixed;
    z-index: 10000000000;
    background-color: $white;
    top: 0;
    bottom: 0;
    width: 100%;
    padding: 86px 24px 18px;
    height: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: $dur $ease;
    overflow: scroll;
    &.visible {
        height: 100%;
        visibility: visible;
        opacity: 1;
        pointer-events: all;
    }
    h2 {
        font-size: 14px;
        line-height: 14px;
        color: $blue-whale;
        font-family: $font-primary;
        margin-bottom: 8px;
    }
    .filters__dropdown {
        display: none;
    }
    .filters__form {
        visibility: visible;
        opacity: 1;
        position: static;
        box-shadow: none;
        background-color: transparent;
        pointer-events: all;
    }
    .checkbox-dropdown__switch {
        position: static;
    }

    // Responsive styles for listing types checkboxes

    .filters__form-checkboxes .form-element {
        padding: 4px 8px 2px 0;
    }

    .filters__form-checkbox span {
        font-size: 10px;
    }

    .filters__property-types {
        margin-top: -8px;

        .ant-checkbox {
            top: 4px;
        }
    }

    .autocomplete-search .css-cuqrlb-singleValue,
    .autocomplete-search .css-1wa3eu0-placeholder {
        left: 31px;
        transform: translateY(-45%);
    }
    // End

    // Responsive styles for Property typs checkboxes
    .mobile-property-search__property-types-wrapper {
        .checkbox-dropdown__switch {
            margin-top: 12px;
        }

        .ant-checkbox-wrapper {
            max-width: 33.333%;
            width: 100%;
            margin-bottom: 11px;
            padding-right: 8px;
            margin-right: 0;
            align-items: center;
            span {
                font-size: 10px;
                line-height: 11px;
            }

            .ant-checkbox {
                top: -3px;
            }
        }
    }

    .mobile-property-search__button-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 17px;
    }

    .property-search-button {
        width: 77px;
        height: 36px;
        background-color: $blue-whale;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        color: $white;
        border: none;
        margin: 0 auto 0;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $blue-whale;
        border: 2px solid $blue-whale;
    }
    .ant-checkbox-checked::after {
        display: none;
    }

    .filter-lock__holder {
        left: 0;
    }

    // End

    @media screen and (min-width: 768px) {
        display: none;
    }
}

.dashboard__search {
    background: #ffffff;
    border: 1px solid #c9cccf;
    border-radius: 4px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 11px;
}

.dashboard__font-icon-wrapper {
    svg {
        color: $boulder2;
    }
}

.dashboard__search-input {
    border: none;
    margin-left: 8px;
    width: 100%;
    pointer-events: none;

    &::placeholder {
        color: $boulder2;
        font-weight: 400;
        font-size: 14px;
    }

    &:disabled {
        background-color: transparent;
    }
}

// Expended search styles

.mobile-property-search__search-wrapper {
    margin-bottom: 32px;
}

.mobile-property-search__title {
    margin-bottom: -1px;
}

.mobile-property-search__filters-wrapper {
    margin-bottom: 12px;
}

.mobile-small-title {
    display: none;
    font-weight: 500;
    font-size: 8px;
    color: $boulder2;
    margin: 17px 0 8px;
    @include mq(767px) {
        display: block;
    }
}
