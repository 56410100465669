#fannie-freddie-lenders {
    overflow-x: hidden;
    position: relative;
    padding-left: 14px;
    height: 100%;
    overflow-y: auto;
    transition: $dur $ease;
    padding: 8px 20px;
    @include scroll-style($boulder, $alabaster, 0, 0.4vw, 0);
    @media screen and (max-width: 767px) {
        &.open {
            z-index: inherit;
            position: fixed;
            top: 70px;
            background-color: $white;
            height: calc(100vh - 70px);
            overflow: scroll;
        }
        &.closed {
            height: 0;
        }

        tbody {
            transform: translateY(-2px);
        }
    }

    // Supports query for dvh
    @supports (height: 100dvh) {
        @media screen and (max-width: 767px) {
            &.open {
                height: calc(100dvh - 70px);
            }
        }
    }
}

.fannie-freddie-lenders {
    padding-bottom: 12px;

    &__header {
        text-align: left;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px !important;
        color: $boulder2;
        vertical-align: middle !important;
    }

    &__header-wrap {
        display: none;
        @include mq($md) {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 100vw;
            padding: 0 44px 16px 30px;

            button {
                background: transparent;
                border: none;
                padding: 0;
                font-size: 10px;
                font-family: $font-primary;
                color: $jungle-green2;
                position: absolute;
                left: 0;
                top: 2px;
                padding-left: 16px;

                svg {
                    font-size: 15px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            h3 {
                font-size: 10px;
                font-family: $font-primary;
                font-weight: 400;
                line-height: 14px;
                color: $blue-whale;
            }
        }
    }

    &__info {
        margin-bottom: 18px;
    }

    &__collapse {
        &__panel {
            &__header {
                display: flex;
                gap: 25px;
                align-items: center;
                // justify-content: space-between;

                img {
                    // height: 25px;
                    width: 50px;
                }
                @include mq($md) {
                    img {
                        height: auto;
                        width: 50px;
                    }
                }
            }
        }
    }
}

.fannie-freddie-lenders__lender {
    &__table {
        table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 15px 1px;

            th,
            td {
                width: 100px;

                img {
                    width: 100%;
                }
            }

            td {
                text-align: center;
            }

            th {
                text-align: left;
                font-size: 13px;
            }
        }
    }

    &__contact-info {
        padding: 8px 15px;

        > div {
            padding: 8px 0;
        }
    }

    &__primary-contact-info {
        padding: 8px 15px;

        h3 {
            font-size: 15px;
            font-weight: 500;
        }

        > div {
            padding: 8px 0;
        }
    }
}
