/*--------------------------------------------------------------
## Base Layout Style
--------------------------------------------------------------*/
%font-adjustments {
    font-family: $font-primary;
    font-style: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-feature-settings: normal;
}

html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    height: 100%;
    overscroll-behavior: none;
    @extend %font-adjustments;
}

html {
    box-sizing: border-box;
    overflow: auto;
}

input,
textarea {
    -webkit-text-size-adjust: none; /* disable text zooming */
}

body {
    font-variant: normal;
    font-feature-settings: normal;
    overflow: hidden;

    @media only screen and (min-width: 768px) and (max-width: 1000px) {
        overflow: auto;
        width: 100vw;
    }
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

.app-container {
    display: flex;
}

.main-container {
    position: relative;
    flex: 1 0 calc(100% - 540px);
    transition: width 0.5s ease;
    z-index: 1;
    @media only screen and (min-width: 768px) {
        &.sidebar-collapsed {
            width: calc(100% - 60px);
            .map {
                width: calc(100% - 60px);
            }
            .legend {
                left: 84px;
            }
            .site-sidebar__logo--L {
                opacity: 0;
                pointer-events: none;
            }
            .header__search {
                margin-left: auto;
            }
        }
    }
    @include mq($xl) {
        &.sidebar-collapsed {
            .site-header__container {
                width: 100%;
            }
        }
    }
    @include mq($md) {
        z-index: 6;
        width: 100%;
    }
    @media only screen and (min-width: 768px) and (max-width: 1000px) {
        flex: 1 0 1000px;
        overflow: hidden;
    }
}

.map {
    position: relative;
    width: calc(100% - 540px);
    margin-left: auto;
    height: 100vh;
    padding-top: 70px;
    transition: all 0.5s ease;
    @media only screen and (max-width: 767px) {
        width: 100%;
        padding-top: 50px;
        height: calc(100dvh - 63px);
    }

    @include mq(767px) {
        @include mq(670px, max, height) {
            &.card-visible {
                height: 100vh;
            }
        }
    }
}

.street-view__wrapper {
    position: absolute !important;
    width: calc(100% - 120px);
    height: calc(100vh - 260px);
    top: 40px;
    left: 60px;
    margin-top: 100px;
    background-color: $white;
    z-index: 100;
    border-radius: 4px;
    @media only screen and (max-width: 767px) {
        z-index: 1000000001;
        width: 90vw;
        height: 82vh;
        top: -32px;
        left: 50%;
        transform: translateX(-50%);
    }
}

#street-view {
    width: calc(100% - 50px);
    height: calc(100% - 55px);
    top: 30px;
    left: 25px;
}

.street-view__close {
    position: absolute;
    right: 7px;
    top: 7px;
    cursor: pointer;
    svg {
        width: 12px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: normal;
}

a,
p {
    margin: 0;
}
