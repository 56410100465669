// ABCMonumentGrotesk
@font-face {
    font-family: "ABCMonumentGrotesk";
    src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Bold.eot");
    src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Bold.woff")
            format("woff"),
        url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Bold.ttf")
            format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "ABCMonumentGrotesk";
    src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Regular.eot");
    src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Regular.woff")
            format("woff"),
        url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Regular.ttf")
            format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "ABCMonumentGrotesk";
    src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Light.eot");
    src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Light.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Light.woff")
            format("woff"),
        url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Light.ttf")
            format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "ABCMonumentGrotesk";
    src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Ultra.eot");
    src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Ultra.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Ultra.woff")
            format("woff"),
        url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Ultra.ttf")
            format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "ABCMonumentGrotesk";
    src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Thin.eot");
    src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Thin.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Thin.woff")
            format("woff"),
        url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Thin.ttf")
            format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "ABCMonumentGrotesk";
    src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Medium.eot");
    src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Medium.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Medium.woff")
            format("woff"),
        url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Medium.ttf")
            format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

// Work Sans font
@font-face {
    font-family: "Work Sans";
    src: url("../../assets/fonts/WorkSans/WorkSans-Black.woff2") format("woff2"),
        url("../../assets/fonts/WorkSans/WorkSans-Black.woff") format("woff"),
        url("../../assets/fonts/WorkSans/WorkSans-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Work Sans";
    src: url("../../assets/fonts/WorkSans/WorkSans-ExtraBold.woff2")
            format("woff2"),
        url("../../assets/fonts/WorkSans/WorkSans-ExtraBold.woff")
            format("woff"),
        url("../../assets/fonts/WorkSans/WorkSans-ExtraBold.ttf")
            format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Work Sans";
    src: url("../../assets/fonts/WorkSans/WorkSans-Bold.woff2") format("woff2"),
        url("../../assets/fonts/WorkSans/WorkSans-Bold.woff") format("woff"),
        url("../../assets/fonts/WorkSans/WorkSans-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Work Sans";
    src: url("../../assets/fonts/WorkSans/WorkSans-Thin.woff2") format("woff2"),
        url("../../assets/fonts/WorkSans/WorkSans-Thin.woff") format("woff"),
        url("../../assets/fonts/WorkSans/WorkSans-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Work Sans";
    src: url("../../assets/fonts/WorkSans/WorkSans-Regular.woff2")
            format("woff2"),
        url("../../assets/fonts/WorkSans/WorkSans-Regular.woff") format("woff"),
        url("../../assets/fonts/WorkSans/WorkSans-Regular.ttf")
            format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Work Sans";
    src: url("../../assets/fonts/WorkSans/WorkSans-SemiBold.woff2")
            format("woff2"),
        url("../../assets/fonts/WorkSans/WorkSans-SemiBold.woff") format("woff"),
        url("../../assets/fonts/WorkSans/WorkSans-SemiBold.ttf")
            format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Work Sans";
    src: url("../../assets/fonts/WorkSans/WorkSans-Light.woff2") format("woff2"),
        url("../../assets/fonts/WorkSans/WorkSans-Light.woff") format("woff"),
        url("../../assets/fonts/WorkSans/WorkSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Work Sans";
    src: url("../../assets/fonts/WorkSans/WorkSans-Medium.woff2")
            format("woff2"),
        url("../../assets/fonts/WorkSans/WorkSans-Medium.woff") format("woff"),
        url("../../assets/fonts/WorkSans/WorkSans-Medium.ttf")
            format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Work Sans";
    src: url("../../assets/fonts/WorkSans/WorkSans-ExtraLight.woff2")
            format("woff2"),
        url("../../assets/fonts/WorkSans/WorkSans-ExtraLight.woff")
            format("woff"),
        url("../../assets/fonts/WorkSans/WorkSans-ExtraLight.ttf")
            format("truetype");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gilroy";
    src: url("../../assets/fonts/Gilroy/Gilroy-Medium.eot");
    src: url("../../assets/fonts/Gilroy/Gilroy-Medium.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/Gilroy/Gilroy-Medium.woff2") format("woff2"),
        url("../../assets/fonts/Gilroy/Gilroy-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gilroy";
    src: url("../../assets/fonts/Gilroy/Gilroy-Light.eot");
    src: url("../../assets/fonts/Gilroy/Gilroy-Light.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/Gilroy/Gilroy-Light.woff2") format("woff2"),
        url("../../assets/fonts/Gilroy/Gilroy-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gilroy";
    src: url("../../assets/fonts/Gilroy/Gilroy-Bold.eot");
    src: url("../../assets/fonts/Gilroy/Gilroy-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/Gilroy/Gilroy-Bold.woff2") format("woff2"),
        url("../../assets/fonts/Gilroy/Gilroy-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
