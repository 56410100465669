.header__search--focused {
    .css-yk16xz-control {
        border-bottom-left-radius: 0 !important;
    }

    .property-types {
        .ant-select-selector {
            border-bottom-right-radius: 0 !important;
        }
    }
}

.autocomplete-search {
    max-width: 415px;
    position: relative;
    z-index: 9;
    min-width: 415px;
    width: 100%;

    @include mq($lap-big) {
        max-width: 332px;
        min-width: 332px;
    }

    @include mq(1000px) {
        max-width: 330px;
        min-width: 330px;
    }

    @include mq($md) {
        margin-bottom: 15px;
        max-width: 100%;
        min-width: 100%;
    }

    &.autocomplete-search--focused {
        .css-1hwfws3 {
            padding-top: 2px;
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512' fill='%2303363d'%3E%3C!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M272 192C272 236.2 236.2 272 192 272C147.8 272 112 236.2 112 192C112 147.8 147.8 112 192 112C236.2 112 272 147.8 272 192zM192 160C174.3 160 160 174.3 160 192C160 209.7 174.3 224 192 224C209.7 224 224 209.7 224 192C224 174.3 209.7 160 192 160zM384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384zM192 48C112.5 48 48 112.5 48 192C48 204.4 52.49 223.6 63.3 249.2C73.78 274 88.66 301.4 105.8 329.1C134.2 375.3 167.2 419.1 192 451.7C216.8 419.1 249.8 375.3 278.2 329.1C295.3 301.4 310.2 274 320.7 249.2C331.5 223.6 336 204.4 336 192C336 112.5 271.5 48 192 48V48z'/%3E%3C/svg%3E");
            }
        }

        .css-1pahdxg-control {
            background-color: $alabaster;
            border-bottom-left-radius: 0;

            &::after {
                opacity: 1;
            }
        }

        div {
            border-right: none !important;
        }

        @include mq(767px) {
            // Disable all focused styles
            .css-1pahdxg-control {
                background-color: inherit;
                border-radius: 4px;
                border-right: 1px solid rgba($black, 0.12) !important;
                min-height: 36px;
                &::after {
                    opacity: 0;
                }
            }

            .css-1hwfws3 {
                padding-top: 10px;
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512' fill='%2303363d'%3E%3C!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M272 192C272 236.2 236.2 272 192 272C147.8 272 112 236.2 112 192C112 147.8 147.8 112 192 112C236.2 112 272 147.8 272 192zM192 160C174.3 160 160 174.3 160 192C160 209.7 174.3 224 192 224C209.7 224 224 209.7 224 192C224 174.3 209.7 160 192 160zM384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384zM192 48C112.5 48 48 112.5 48 192C48 204.4 52.49 223.6 63.3 249.2C73.78 274 88.66 301.4 105.8 329.1C134.2 375.3 167.2 419.1 192 451.7C216.8 419.1 249.8 375.3 278.2 329.1C295.3 301.4 310.2 274 320.7 249.2C331.5 223.6 336 204.4 336 192C336 112.5 271.5 48 192 48V48z'/%3E%3C/svg%3E");
                }
            }

            // div {
            //     border-right: 1px solid !important;
            // }
        }
    }

    .css-1wy0on6 {
        display: none;
    }

    .css-yk16xz-control {
        min-height: auto !important;
        border-radius: 4px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-right: none;
        transition: $dur $ease;
        position: relative;

        @include mq($md) {
            border-right: 1px solid rgba(0, 0, 0, 0.12);
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
        }

        &:hover {
            border-color: rgba(0, 0, 0, 0.12);
        }
    }

    .css-1pahdxg-control {
        box-shadow: none;
        border-radius: 4px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-right: none;

        &::after {
            content: "";
            height: 1px;
            width: 100%;
            background-color: $boulder;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 2;
            opacity: 0;
            transition: $dur $ease;
        }

        @include mq($md) {
            border-right: 1px solid rgba(0, 0, 0, 0.12);
            border-bottom-right-radius: 8px;
            border-top-right-radius: 8px;
        }

        &:hover {
            border-color: rgba(0, 0, 0, 0.12);
            border-right: none;
        }
    }

    .css-1hwfws3 {
        position: relative;
        padding: 4.2px 19px 4.3px 12px;
        display: flex;
        max-width: 434px;
        align-items: center;
        height: 100%;
        flex: none;
        flex-wrap: nowrap;

        @include mq($lap-big) {
            max-width: 332px;
        }

        @include mq($xl) {
            max-width: 100%;
        }

        @include mq(1000px) {
            height: 34px;
        }

        @include mq($lg) {
            padding: 10px 11px;
        }

        @include mq($md) {
            max-width: calc(100% - 12px);
        }

        &::before {
            display: inline-block;
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512' fill='%23777777'%3E%3C!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M272 192C272 236.2 236.2 272 192 272C147.8 272 112 236.2 112 192C112 147.8 147.8 112 192 112C236.2 112 272 147.8 272 192zM192 160C174.3 160 160 174.3 160 192C160 209.7 174.3 224 192 224C209.7 224 224 209.7 224 192C224 174.3 209.7 160 192 160zM384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384zM192 48C112.5 48 48 112.5 48 192C48 204.4 52.49 223.6 63.3 249.2C73.78 274 88.66 301.4 105.8 329.1C134.2 375.3 167.2 419.1 192 451.7C216.8 419.1 249.8 375.3 278.2 329.1C295.3 301.4 310.2 274 320.7 249.2C331.5 223.6 336 204.4 336 192C336 112.5 271.5 48 192 48V48z'/%3E%3C/svg%3E");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 16px;
            width: 16px;
            margin-right: 8px;
            margin-top: -1px;
        }
    }

    .css-cuqrlb-singleValue,
    .css-1wa3eu0-placeholder {
        display: block;
        font-family: $font-primary;
        font-size: 14px;
        line-height: 14px;
        color: $boulder;
        left: 32px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;

        @include mq($lg) {
            left: 33px;
        }

        @include mq(767px) {
            color: $boulder2;
            font-weight: 400;
            font-size: 14px;
            left: 30px;
        }
    }

    .css-1wyfeij {
        margin-left: 0;
    }

    .css-1wyfeij,
    input {
        font-family: $font-primary;
        font-size: 14px !important;
        line-height: 14px !important;
        color: $blue-whale !important;
    }
}

// map dropdown
.css-26l3qy-menu {
    margin-top: 0 !important;
    max-width: 535px;
    min-width: 535px;
    border-top: none;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    background-color: $alabaster !important;
    z-index: 9;

    @include mq($lg) {
        min-width: auto;
        max-width: none;
    }

    @include mq($lg) {
        max-width: 100%;
    }
}

.css-11unzgr {
    div {
        color: $blue-whale;
        font-family: $font-primary;
        line-height: 14px;
        font-size: 14px;
        padding: 12px 36px;
        cursor: pointer;

        @include hover {
            background-color: rgb(3, 54, 61, 15%);
        }
    }
}

.css-1gl4k7y {
    color: $blue-whale;
    font-family: $font-primary;
    line-height: 14px;
    font-size: 14px;
    padding: 20px 36px;
    cursor: context-menu !important;

    @include hover {
        background-color: transparent !important;
    }
}

.header__search {
    display: flex;
    transition: $dur $ease;
    margin-left: auto;
    height: 37.6px;
    position: relative;

    @include mq(1060px) {
        margin-left: 20px;
    }

    .ant-select-dropdown {
        z-index: 99999;
    }

    .anticon-close {
        display: none;
    }

    .property-types {
        display: flex;
        flex-direction: row-reverse;
        margin-right: 16px;
        position: relative;
        height: 100%;

        @include mq($lg) {
            height: 50px;
        }

        @include mq(1000px) {
            height: 37px;
        }

        @include mq($md) {
            margin-right: 0;
            margin-bottom: 15px;
        }

        &.property-types--focused {
            .ant-select-selector {
                background-color: $alabaster;
                border-bottom-right-radius: 0;
                border-bottom: 1px solid $boulder;

                &::before {
                    opacity: 1;
                }

                @include mq(767px) {
                    background-color: $white;
                    border-bottom: none;
                }
            }

            .property-types__icon {
                svg {
                    fill: $blue-whale;
                    color: $blue-whale;
                }
            }
        }

        .ant-select {
            position: relative;
            width: 100%;

            &::before {
                content: "";
                width: 1px;
                height: 34px;
                background-color: $alto-dark;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                display: inline-block;
                position: absolute;
                z-index: 9;

                @include mq($xl) {
                    height: 80%;
                }

                @include mq($md) {
                    display: none;
                }
            }

            &.ant-select-focused {
                .ant-select-selector {
                    border-color: rgba(0, 0, 0, 0.12);
                    box-shadow: none;
                }
            }
        }

        .ant-select-selector {
            border-radius: 4px;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border: 1px solid rgba(0, 0, 0, 0.12);
            border-left: none;
            min-width: 187px;
            max-width: 187px;
            width: 100%;
            padding: 6px 16px 6px 40px;
            height: 100%;

            &::before {
                content: "";
                height: 1px;
                width: 100%;
                background-color: $boulder;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 2;
                opacity: 0;
                transition: $dur $ease;
            }

            @include mq($lg) {
                height: 51px;
            }

            @include mq(1000px) {
                height: 37px;
            }

            @include mq($md) {
                max-width: 100%;
                min-width: 100%;
                margin-bottom: 15px;
                border-bottom-left-radius: 8px;
                border-top-left-radius: 8px;
                border-left: 1px solid rgba(0, 0, 0, 0.12);
            }
        }
    }

    .ant-select-multiple .ant-select-selection-placeholder {
        left: 42px;
        right: auto;
        color: $blue-whale;
        font-size: 12px;
        background: rgba(150, 204, 212, 0.15);
        border-radius: 4px;
        padding: 2px 8px;
    }

    .ant-select-selection-search {
        margin-inline-start: 3px;
    }

    .ant-select-multiple .ant-select-selection-item-remove > .anticon {
        vertical-align: text-bottom;
    }

    // property type pills
    .ant-select-selection-overflow-item {
        .ant-select-selection-item {
            background-color: rgba(150, 204, 212, 0.15);
            border-radius: 4px;
            border: none !important;
            height: auto;
            margin: 1px 0;
        }

        .ant-select-selection-item-content {
            font-size: 12px;
            line-height: 14px;
            color: $blue-whale;
            font-family: $font-primary;
            display: flex;
            align-items: center;
            padding: 4px 0;
        }

        .anticon-close {
            svg {
                fill: $blue-whale;
                transition: $dur $ease;
            }

            @include hover {
                fill: $jungle-green;
            }
        }
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.property-types__count {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    background-color: rgba(150, 204, 212, 0.15);
    padding: 4px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    font-family: $font-primary;
}

.property-types__icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    pointer-events: none;

    svg {
        height: 16px;
        fill: $boulder;
        color: $boulder;
        transition: $dur $ease;
    }
}

// .search-btn {
//     box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
//     border-radius: 6px;
//     background-color: $blue-whale;
//     box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
//         0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
//     height: 40px;
//     width: 40px;
//     min-width: 40px;
//     max-width: 40px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     transition: $dur $ease;
//     border: none;
//     position: relative;

//     @include mq($lg) {
//         height: 50px;
//         width: 100%;
//         margin: 0 auto;
//         max-width: 50px;
//         min-width: 50px;
//     }

//     @include mq($md) {
//         max-width: 100%;
//     }

//     @include hover {
//         background-color: $jungle-green;
//     }

//     svg {
//         height: 16px;
//         fill: $white;
//         color: $white;
//     }
// }

.ant-select-dropdown {
    z-index: 99999999;
}

// property types dropdown
.property-types-dropdown {
    display: flex;
    align-items: center;
    width: 100% !important;
    max-width: 517px;
    padding: 75px 10px 16px 36px;
    border-radius: 0 0 8px 8px;
    background-color: $alabaster;
    margin-top: -5px;

    &.dropdown-disabled {
        .checkbox-group::before {
            opacity: 1;
        }
    }

    &.not-disabled {
        .checkbox-group::before {
            opacity: 1 !important;
        }
    }

    .checkbox-group::before {
        opacity: 0.5 !important;
    }

    .form-element {
        margin-left: -15px;

        @include mq($lap-big) {
            margin-left: 0;
        }
    }

    &.ant-select-dropdown-hidden {
        display: none;
    }

    @include mq($lg) {
        min-width: none !important;
        max-width: auto !important;
        width: initial !important ;
    }

    @include mq($lg) {
        max-width: 100% !important;
    }

    label {
        position: relative;
        margin-bottom: 10px;
        margin-right: 0;

        &::before {
            content: attr(data-value);
            position: absolute;
            bottom: 7px;
            left: 36px;
            font-family: $font-primary;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 12px;
            color: $boulder;

            @include mq(1600px) {
                left: 34px;
            }
        }
    }

    span {
        color: $blue-whale;
        font-family: $font-primary;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 14px;
    }

    .checkbox-group {
        &::before {
            content: "OR SELECT PROPERTY TYPES";
            font-family: $font-primary;
            font-size: 8px;
            line-height: 8;
            color: $boulder2;
            margin-bottom: 14px;
            width: 100%;
            position: absolute;
            left: 34px;
            top: 38px;
            opacity: 1;

            @include mq($lap-big) {
                left: 28px;
                top: 34px;
            }
        }
    }

    .ant-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;

        @include mq($lg) {
            flex-direction: column;
        }
    }

    .ant-checkbox-group-item {
        flex: 0 0 157px;
        max-width: 157px;
        height: 42px;
        min-height: 42px;
        max-height: 42px;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        border-radius: 8px;

        @include mq($lap-big) {
            flex: 0 0 150px;
            max-width: 150px;
        }

        @include hover {
            background-color: rgba(150, 204, 212, 0.15);
        }
    }

    .ant-checkbox {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
    }

    .ant-checkbox-wrapper {
        padding: 14px 5px 12px 14px;

        @include mq($lap-big) {
            padding: 12px 5px 12px 26px;
        }

        &:hover {
            .ant-checkbox-inner {
                border-color: $blue-whale;
            }
        }

        &.ant-checkbox-wrapper-checked {
            &:hover {
                background-color: transparent;
            }
        }
    }

    .ant-checkbox-wrapper-disabled {
        pointer-events: none;
        opacity: 0.7;
    }

    .ant-checkbox + span {
        padding-left: 25px;
        padding-right: 0;
    }

    .ant-checkbox-inner {
        border: 2px solid $mercury;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $blue-whale;
        border: 2px solid $blue-whale !important;

        &:hover {
            border: 2px solid $blue-whale;
        }
    }

    .ant-checkbox-checked::after {
        border: 2px solid $blue-whale;
    }

    .ant-checkbox-inner::after {
        left: 20.5%;
    }
}

.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $mercury;
}

.ant-checkbox-checked {
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: $blue-whale;
    }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: rgba(0, 0, 0, 0.12);
}

.empty-dropdown {
    display: flex;
    align-items: center;
    margin-top: -15px;
    margin-left: 7px;
    padding: 5px 0;

    @include mq($lap-big) {
        margin-top: 0;
        margin-left: 15px;
        padding: 15px 0;
    }

    svg {
        color: $boulder;
        padding: 0;
    }

    .empty-dropdown__text {
        margin-left: 8px;
        display: inline-block;
        font-family: $font-primary;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $boulder2;
    }
}

.brokers-dropdown {
    .ant-select-item-empty {
        padding: 0;
    }

    .empty-dropdown {
        padding: 17px 15px;
        margin-left: 0;
        margin-top: 0;
    }
}

.ant-checkbox-inner {
    height: 18px;
    width: 18px;
}
