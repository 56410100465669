.favorite-overlay-cta {
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.94) 12%,
        rgba(255, 255, 255, 0.57) 100%
    );
    z-index: 1000;
    padding: 103px 15px 15px;
    // pointer-events: none;
    transition: all $dur $ease;

    &.open {
        top: 70px;

        @include mq(767px) {
            top: 54px;
        }
    }
}

.favorite-overlay-cta__content {
    max-width: 620px;
    margin: 0 auto;
}

.favorite-overlay-cta__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}
.favorite-overlay-cta__title {
    color: $blue-whale;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 16px;

    @include mq(411px) {
        font-size: 18px;
        line-height: 18px;
    }
}

.favorite-overlay-cta__text {
    color: $blue-whale;
    text-align: left;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 24px;
}

.favorite-overlay-cta__btn {
    margin: 0 auto;
    border-radius: 6px;
    background: $blue-whale;
    min-width: 140px;
    height: 46px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 16px 40px;
    color: $white;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border: none;
    transition: all $dur $ease;

    &:hover {
        background-color: $jungle-green;
        color: $white;
    }
}
