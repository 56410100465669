.lender-portfolios {
    padding: 1px 46px 14px 52px;
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $boulder2;
        margin-bottom: 16px;
    }
    &.open {
        z-index: inherit;
        position: fixed;
        left: 0;
        right: 0;
        top: 70px;
        background-color: #fff;
        height: calc(100vh - 70px);
        padding: 10px;
    }

    &.closed {
        @include mq(767px) {
            display: none;
        }
    }
}

.lender-portfolios__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
    padding-right: 11px;
    button {
        background-color: $blue-whale;
        border: none;
        box-shadow: none;
        border-radius: 6px;
        color: $white;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 27px;
        transition: all 0.2s ease;
        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
        @include hover {
            background-color: $jungle-green2;
        }
    }
}

.lender-portfolios__clear {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $jungle-green2;
    text-decoration: underline;
    margin-right: 24px;
    cursor: pointer;
}

.lender-portfolios__dot {
    display: inline-block;
    position: relative;
    top: 4px;
    border: 1px solid #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 19px;
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    margin-right: 16px;
    &.navy {
        background-color: #3366cc;
    }
    &.red {
        background-color: #fa6c3d;
    }
    &.yellow {
        background-color: #ffcb77;
    }
}

.lender-portfolios__dropdown {
    margin-bottom: 16px;
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
        font-weight: 400;
        font-size: 14px;
        color: $boulder2;
        line-height: 35px !important;
    }
    .ant-select-item {
        padding: 6px 13px;
    }
    .ant-select {
        .ant-select-selector {
            border-radius: 4px;
        }
        .ant-select-selector,
        .ant-select-selector .ant-select-selection-search-input {
            height: 36px;
        }
        &.ant-select-focused .ant-select-selector {
            box-shadow: none !important;
            border-color: #c9cccf !important;
        }
    }
    .ant-select-arrow {
        svg {
            width: 13px;
            height: 14px;
            color: $boulder2;
        }
    }
}

.lender-portfolios__select {
    width: 401px;
    @include mq($md) {
        width: calc(100% - 40px);
    }
}

.empty-lender-portfolio-dropdown__text {
    margin-left: 5px;
}
