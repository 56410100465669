.mobile-popup {
    position: fixed;
    background: rgba(255, 255, 255, 0.85);
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999999999;
    align-items: center;
    justify-content: center;
    display: none;
    pointer-events: none;
    transition: $dur $ease;

    @include mq($md) {
        display: flex;
        pointer-events: all;
    }
}

.mobile-popup__inner {
    max-width: 360px;
    min-height: 77vh;
    margin: auto;
    padding: 60px 0 72px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include mq(350px) {
        max-width: 95%;
    }
}

.mobile-popup__logo {
    width: 56px;
    height: 60px;
    margin-bottom: 16px;
}

.mobile-popup__body-title {
    font-family: $font-third;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: $blue-whale;
    text-align: center;
    max-width: 160px;
    margin: 0 auto 18px;
}

.mobile-popup__body-content {
    font-family: $font-primary;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: $blue-whale;
    max-width: 296px;
    margin: 0 auto 20px;
}

.mobile-popup__footer-text {
    font-family: $font-primary;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $blue-whale;
    margin-bottom: 16px;
}

.mobile-popup__footer {
    margin-top: auto;
    width: 100%;
}

.mobile-popup__footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;

    .btn-clasic {
        margin-bottom: 12px;
    }
}

.btn-clasic {
    font-family: $font-primary;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $white;
    padding: 9px;
    border-radius: 6px;
    width: 100%;
    max-width: 296px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: $dur $ease;

    &:hover {
        color: $white;
    }

    &--green {
        border: 2px solid $blue-whale;
        background-color: $blue-whale;

        @include hover {
            background-color: $jungle-green;
            border-color: $jungle-green;
            color: $white;
        }
    }

    &--orange {
        border: 2px solid $burn-orange;
        background-color: $burn-orange;

        @include hover {
            background-color: $piper;
            border-color: $piper;
            color: $white;
        }
    }
}
