/*--------------------------------------------------------------
## Tenant Leads Section Style
--------------------------------------------------------------*/

.tenant-leads {
    display: flex;
    flex-direction: column;
    padding: 13px 14px 0 15px;
    // height: calc(100vh - 307px);
    // height: calc(100vh - 256px);
    overflow-y: auto;
    position: relative;
    @include scroll-style($boulder, $alabaster, 0, 0.4vw);

    h1 {
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
        margin-bottom: 20px;
        text-align: center;
    }

    .calculator-slideout__header {
        position: fixed;
        visibility: hidden;
        // opacity: 0;
        pointer-events: none;
        margin: -13px 0 0 -14px;
        width: 100%;
        max-width: 761px;
        z-index: 1;
    }

    @media screen and (max-width: 767px) {
        &.open {
            display: block;
            position: fixed;
            z-index: 101;
            background-color: $white;
            max-width: 100%;
            width: 100%;
            height: 100vh;
            top: 0;
            padding: 83px 0 0;
            overflow-x: hidden;
        }
        &.closed {
            display: none;
        }

        h1 {
            display: none;
        }

        .calculator-slideout__header {
            display: none;
        }
    }
}

.tenant-leads__has-cards {
    position: fixed;
    top: 70px;
    left: 0;
    height: calc(100% - 70px);
    max-width: 762px;
    width: 100%;
    background-color: #fff;
    z-index: 1000;

    .calculator-slideout__header {
        visibility: visible;
        // opacity: 1;
        pointer-events: all;
    }

    .tenant-leads__description,
    h1 {
        display: none;
    }
}

.tenant-leads__wrap {
    padding: 0 15px 30px;
    .ant-spin {
        display: block;
        margin: 60px auto 23px;
        svg {
            fill: $jungle-green2;
        }
    }
    @media screen and (max-width: 767px) {
        padding-bottom: 110px;
    }
}

.tenant-leads__description {
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: $boulder2;
    max-width: 433px;
    span {
        color: $jungle-green2;
        text-decoration: underline;
        cursor: pointer;
        &.with-icon {
            text-decoration: none;
            cursor: unset;
            svg {
                position: relative;
                top: -1px;
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        position: relative;
        top: 19vh;
    }
}

.tenant-leads__data {
    padding-top: 73px;
    margin: 0 -14px;
    @media screen and (max-width: 767px) {
        padding: 10px 12px;
    }
}

.tenant-leads__data-inner {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -24px;
}

.tenant-leads__card {
    position: relative;
    width: calc(33% - 16px);
    margin-right: 24px;
    margin-bottom: 24px;
    border-radius: 10px;
    border: 1px solid #6fc1d54c;
    background: $white;
    &:nth-child(3n) {
        margin-right: 0;
    }
    .tenant-leads__unconfirmed {
        display: none;
    }
    &.unconfirmed {
        border: 1px solid #6fc1d526;
        .tenant-leads__unconfirmed {
            display: flex;
        }
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0;
        &:nth-child(3n) {
            margin-right: 0;
        }
    }
}

.tenant-leads__unconfirmed {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(#fff, 0.7);
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    color: $burn-orange;
    span {
        font-size: 14px;
        font-weight: 500;
    }
    svg {
        margin-right: 8px;
        margin-top: -1px;
    }
}

.tenant-leads__card-heading {
    display: flex;
    justify-content: space-between;
    padding: 11px 10px 10px;
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    border-radius: 10px 10px 0px 0px;
    background: linear-gradient(90deg, #6fc1d52f 0%, #3ebfac43 100%);
    color: $blue-whale;
    svg {
        margin-right: 5px;
        font-size: 11px;
        color: $jungle-green2;
    }
}

.tenant-leads__card-data {
    padding: 11px 10px 5px;
}

.tenant-leads__card-row {
    display: flex;
    span {
        display: block;
        flex-shrink: 0;
        width: 50%;
        margin-bottom: 10px;
        margin-right: 10px;
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        color: $blue-whale;
        a {
            color: $jungle-green2;
            font-weight: 700;
        }
        &:last-child {
            font-weight: 400;
        }
        &.tenant-leads__view-note {
            margin-bottom: 0;
            text-decoration: underline;
            cursor: pointer;
            color: $jungle-green2;
        }
    }
}

.tenant-leads__note-popup {
    .overlay {
        right: unset;
        width: 762px;
        z-index: 10;
    }
    svg {
        position: absolute;
        top: 24px;
        right: 26px;
        font-size: 24px;
        color: #757575;
        cursor: pointer;
    }
}

.tenant-leads__note-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    width: 300px;
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0px 24px 24px 0px rgba(0, 0, 0, 0.3),
        0px 0px 24px 0px rgba(0, 0, 0, 0.22);
    padding: 66px 0 42px 24px;
    overscroll-behavior: contain;
    p {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: $blue-whale;
        max-height: 280px;
        padding-right: 27px;
        overflow: auto;
        overscroll-behavior: contain;
        @include scroll-style($boulder, $alabaster, 0, 0.4vw);
    }
    @media screen and (max-width: 767px) {
        position: fixed;
    }
}
