/*--------------------------------------------------------------
## Legend Component Style
--------------------------------------------------------------*/
.legend {
    display: flex;
    position: absolute;
    bottom: 35px;
    left: 564px;
    background-color: rgba($white, 0.6);
    transition: 0.4s ease-in-out;
    z-index: 1;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 8px;

    svg {
        width: 24px;
        height: 24px;

        @include mq($md) {
            width: 18px;
            height: 18px;
        }
    }

    @include mq($md) {
        bottom: 25px;
        left: 20px;
    }
}

.legend__title {
    display: flex;
    align-items: center;
    padding: 12px 0 12px 12px;

    span {
        font-size: 16px;
        line-height: 14px;
        margin-left: 8px;
        margin-right: 8px;

        @include mq($md) {
            font-size: 12px;
            letter-spacing: 0.4px;
            margin-left: 7px;
            margin-right: 5px;
        }
    }

    @include mq($md) {
        padding: 10px 0 9px 10px;
    }
}

.legend__item-left {
    display: flex;
}

.legend__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $clear-cloud;
    padding: 0 8px;
    position: relative;
    transition: $dur $ease;

    svg {
        margin-left: 4px;
        margin-top: -2px;
        color: $boulder2;
        width: 9px;
        height: 12px;
    }

    &:last-child {
        border-bottom: 0;
        padding-right: 12px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    &.legend__item-right {
        margin-left: auto;
        padding-left: 30px;
    }
    &:hover {
        background-color: rgba(34, 170, 148, 0.22);
        span {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
    }

    @include mq($md) {
        padding: 0 6px;
    }
}

.legend__item-first {
    display: flex;
    justify-content: center;
    margin-right: 4px;

    @include mq($md) {
        margin-right: 3px;
    }
}

.legend__item-value {
    margin-left: auto;
    font-size: 12px;
    font-weight: 500;
    color: $blue-whale;
    padding-top: 1px;

    @include mq($md) {
        font-size: 10px;
    }
}

.legend__item-tooltip {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    background-color: $blue-whale;
    padding: 14px;
    font-size: 12px;
    line-height: 16px;
    color: $white;
    z-index: 1;
    top: -56px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s $ease;
    white-space: nowrap;

    &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-width: 7px 6px 0 6px;
        border-color: $blue-whale transparent transparent transparent;
        border-style: solid;
    }
}

.legend__item-cicle {
    height: 14px;
    width: 14px;
    border-radius: 19px;
    position: relative;
    border: 1px solid $white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);

    &--green {
        background-color: #459689;
    }

    &--blue {
        background-color: #37b1c3;
    }

    &--grey {
        background-color: #a2acba;
    }

    &--navy {
        background-color: #3366cc;
    }

    &--red {
        background-color: #fa6c3d;
    }

    &--yellow {
        background-color: #ffcb77;
    }

    @include mq($md) {
        width: 12px;
        height: 12px;
    }
}

.legend__item-bubble {
    width: 18px;
    height: 14px;
    background: #03363d;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 19px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq($md) {
        font-size: 6px;
        line-height: 12px;
    }

    svg {
        color: $white;
        font-size: 8px;
        width: 11px;
        margin: 0;
    }
}

.legend-banner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 500px;
    margin-left: auto;
    color: $blue-whale;
    font-weight: 500;

    svg {
        width: 18px;
        fill: $blue-whale;
        margin-right: 10px;
    }
}

.legend-banner__icon {
    display: flex;
    align-items: center;
}

.legend-banner__text {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    line-height: 1.3;

    a {
        position: relative;
        color: $jungle-green;

        &::before {
            content: "";
            height: 1px;
            width: 0;
            opacity: 0;
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: $jungle-green;
            transition: $dur $ease;
        }

        &:focus,
        &:visited {
            color: $jungle-green;
        }

        &:hover {
            &::before {
                opacity: 1;
                width: 100%;
            }
        }
    }
}

.legend__remove {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    position: absolute;
    top: -51px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $blue-whale;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    background-color: rgba($white, 0.6);
    cursor: pointer;
    transition: all 0.2 ease;
    svg {
        width: 14px;
        height: 14px;
        margin-right: 6px;
    }
    @include hover {
        color: $jungle-green2;
    }
}
