/*--------------------------------------------------------------
## Popup Components Style
--------------------------------------------------------------*/

.popup {
    border-top-right-radius: 10px;
    box-shadow:
        0px 4px 4px rgba(0, 0, 0, 0.25),
        0px 0px 24px rgba(0, 0, 0, 0.22);
    border-radius: 10px;
    background-color: $white;
    z-index: 100000000;
    box-sizing: border-box;
    min-height: 250px;
    height: 676px;

    iframe {
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        border: 0;
        overflow-x: hidden !important;
    }

    .popup__close {
        position: absolute;
        top: 40px;
        right: 24px;
        color: $boulder2;
        font-size: 24px;
        z-index: 1;
        cursor: pointer;
        transition: opacity 0.2s ease;

        @include hover {
            opacity: 0.8;
        }

        @include mq(767px) {
            top: 41px;
            right: 16px;
        }
        &.missing-property-close {
            top: 15px !important;
            right: 25px !important;
        }
    }

    .popup__back-mobile {
        position: absolute;
        top: 12px;
        left: 18px;
        color: $blue-whale;
        flex-direction: column;
        align-items: flex-start;
        font-size: 10px;
        display: none;
        width: 12px;
        svg {
            font-size: 16px;
            padding-bottom: 2px;
            width: 100%;
        }

        @include mq(767px) {
            display: flex;
        }
    }

    .popup__tools-mobile {
        position: absolute;
        top: 19px;
        left: 14px;
        color: $jungle-green2;
        align-items: center;
        font-size: 10px;
        display: none;
        svg {
            width: 13px;
            height: 14px;
            padding-right: 4px;
        }

        @include mq(767px) {
            display: flex;
        }
    }

    .popup__top-line {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $jungle-green2;
        height: 8px;
        width: 100%;
        z-index: 1;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    &.popup--financing {
        .popup__inner,
        iframe {
            height: 100%;
        }
    }

    &.popup--filters {
        height: 804px;
        max-height: 90vh;
        width: 704px;
        box-shadow:
            0px 0px 24px rgba(0, 0, 0, 0.22),
            0px 24px 24px rgba(0, 0, 0, 0.3);
        border-radius: 12px;
        overflow: auto;
        .popup__close {
            top: 20px;
            right: 40px;
        }
        form {
            display: flex;
            flex-direction: column;
            height: 100%;

            @include mq(767px) {
                height: 700px;
                justify-content: flex-start;
            }
        }
        &.non-paid {
            .filters__select:hover {
                background-color: transparent;
            }
        }

        @include mq(767px) {
            max-height: 100%;
            min-height: 100%;
            .popup__close {
                display: none;
            }

            &.closed {
                overflow: scroll;
            }

            &.open {
                overflow: hidden;
            }
        }
    }
}

.popup__section-price {
    margin-top: 38px;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 32px;
    .ant-slider-rail {
        background-color: $clear-cloud;
        height: 4px;
    }

    .ant-slider-track {
        background-color: $boulder2;
        height: 4px;
        top: 4px;
    }

    .ant-slider-handle {
        background-color: $white;
        border: solid 2px $boulder2;
        width: 28px;
        height: 28px;
        top: -2px;
        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 6px;
            height: 16px;
            border-left: 2px solid $boulder2;
            border-right: 2px solid $boulder2;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .ant-slider:hover {
        .ant-slider-rail {
            background-color: $clear-cloud;
        }

        .ant-slider-track {
            background-color: $boulder2;
        }

        .ant-slider-handle {
            background-color: $white;
            border: solid 2px $boulder2;
        }
    }

    .ant-slider-handle:focus {
        box-shadow: 0 0 0 5px rgba(3, 54, 61, 0.12);
    }

    .ant-collapse-content-box {
        padding-top: 16px !important;
        padding-bottom: 0 !important;
    }

    .ant-slider {
        margin: 0 0 16px;
    }
}

// header-wrapper

.popup-filters__header-wrapper {
    padding: 18px 0 9px;
    position: relative;

    h3.popup__heading {
        text-align: center;
        margin-bottom: 23px;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: $blue-whale;
        margin-bottom: 4px;
        margin-top: 6px;
        text-transform: uppercase;
    }

    @media screen and (max-width: 767px) {
        padding: 9px 0 8px;
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 6px;
            left: 50%;
            transform: translateX(-50%);
            width: 48px;
            height: 4px;
            background-color: $clear-cloud;
            border-radius: 4px;
        }
    }
}

.popup__wrap-top {
    padding: 25px 40px 32px;
    h3:not(.filters__select-title) {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: $blue-whale;
        margin-bottom: 4px;
    }

    p {
        color: $boulder2;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 12px;
        b {
            font-style: italic;
        }
    }
    h3.popup__heading-brokerages {
        margin-bottom: 32px;
    }

    @include mq(767px) {
        padding: 0 13px 0 16px;
    }
}

.popup__wrap-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 40px;
    border-top: 1px solid #d9d9d9;
    .btn {
        padding: 8px 16px;
        background-color: $blue-whale;
        border: none;
        border-radius: 4px;
        color: $white;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        transition: $dur $ease;
        @include hover {
            background-color: $jungle-green2;
        }
    }

    @include mq(767px) {
        border: none;
    }
}

.popup-financing__wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999999999;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .popup--financing {
        transform: unset;
        top: unset;
        left: unset;
        max-height: 90vh;
        height: 100%;
    }
    .popup__inner {
        border-radius: 12px;
        overflow: hidden;
        max-height: 90vh;
        height: 100%;
        position: unset;
        transform: none;
    }
    iframe {
        max-height: none !important;
    }
}

.finance-height {
    height: 752px !important;
    //overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.alert-height {
    //height: 404px !important;
    //overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.nonListingCredit-height {
    //height: 576px !important;
    //overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.missingProperty-height {
    //height: 350px !important;
    width: 385px !important;
    //overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.missingProperty {
    height: 100% !important;
    width: 100% !important;
}

.credits-height {
    height: 676px !important;
    //overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.join-height {
    //height: 784px !important;
    //overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.demo-height {
    // height: 784px !important;
    //overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.popup_alert {
    //height: 404px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.popup,
.popup__inner {
    padding: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    //height: 784px;
    max-height: 98vh;
    overflow-x: hidden !important;
    @media screen and (min-width: 768px) {
        @include scroll-style($thumb-color: #777, $track-color: #faf9f7, $radius: 0, $width: 0px);
    }
    @media screen and (max-width: 767px) {
        width: 97vw;
    }
}

.popup--trial {
    height: 312px;
    width: 700px;
    padding: 40px 24px 30px;
    font-family: $font-primary;
    h2 {
        font-family: $font-third;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: $blue-whale;
        margin-bottom: 0;
    }
    a {
        color: $jungle-green2;
        text-decoration: underline;
    }
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $boulder2;
    }
    .heading {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        padding-right: 27px;
        span {
            display: none;
            padding: 6px 12px 5px;
            font-size: 16px;
            font-weight: 400;
            background-color: #e91c1c;
            border-radius: 4px;
            margin-left: 12px;
            color: $white;
        }
        & + p {
            margin-bottom: 22px;
        }
    }
    .days-left {
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        span {
            color: #fa6c3d;
        }
        svg {
            margin-right: 10px;
            color: $blue-whale;
            margin-bottom: 11px;
        }
    }
    .expiration-info {
        background-color: $blue-whale;
        border-radius: 4px;
        padding: 8px 14px;
        margin-bottom: 16px;
        p {
            font-size: 16px;
            color: $white;
            line-height: 26px;
            font-weight: 500;
        }
        span {
            color: $jungle-green2;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .bold {
        margin-bottom: 12px;
        font-weight: 700;
        color: $blue-whale;
    }
    ul {
        margin-bottom: 20px;
    }
    li {
        position: relative;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $boulder2;
        margin-bottom: 4px;
        padding-left: 13px;
        &::before {
            content: "";
            display: block;
            width: 2px;
            height: 2px;
            border-radius: 100%;
            background-color: $boulder2;
            position: absolute;
            left: 2px;
            top: 9px;
        }
    }
    .popup-footer {
        text-align: center;
        a {
            display: inline-block;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 22px;
        }
        button {
            padding: 17px 68px;
            background-color: $blue-whale;
            border: none;
            color: $white;
            border-radius: 6px;
            font-size: 16px;
            font-weight: 700;
        }
    }
    &.expired {
        h2 {
            text-decoration-line: line-through;
            text-decoration-thickness: 1px;
        }
        .heading span {
            display: block;
        }
    }
    &:not(.expired) {
        .days-left {
            margin-bottom: 10px;
        }
    }

    @include mq($md) {
        height: fit-content;
        max-width: 95%;
        width: 100%;
        padding: 40px 15px 30px;
    }
}

.popup__upgrade-content {
    margin-top: 37px;
    margin-bottom: 60px;
    display: flex;
    gap: 52px;
    flex-direction: row;
    height: 63px;
    svg {
        min-width: 60px;
    }
    p {
        max-width: 97px;
        color: $blue-whale;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */

        display: flex;
        align-items: center;
    }
    @media screen and (max-width: 767px) {
        gap: 8px;
    }
    @media screen and (max-width: 600px) {
        flex-direction: column;
        gap: 12px;
        height: 213px;
        margin-top: 37px;
        margin-bottom: 24px;
    }
}

.popup__upgrade-box {
    display: flex;
    flex-direction: row;
}
.alert {
    height: 404px !important;
    //overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.finance {
    height: 752px !important;
    //overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.join {
    height: 784px !important;
    overflow-y: hidden;
    overflow-x: hidden !important;
}

.demo {
    // height: 784px !important;
    overflow-y: hidden;
    overflow-x: hidden !important;
}

.credits {
    height: 676px !important;
    //overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.lenderCredits-height {
    height: 500px !important;
    //overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.lenderCredits {
    height: 500px !important;
    //overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.nonListingCredit {
    height: 576px !important;
    //overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($black, 0.32);
    z-index: 100000000;
    transition: $dur $ease;
}

.filters-popup {
    @include mq($md) {
        // style for filters below 768px
        // display: block;

        .popup--filters {
            top: auto;
            left: auto;
            transform: translate(0);
            left: 0;
            width: 100%;
            border-radius: 8px 8px 0 0;
            transition: 1s $ease;
            bottom: calc(-100% + 49px);
            min-height: 100%;
            &.open {
                bottom: calc(-100% + 49px);
            }
            &.closed {
                bottom: 0;
                min-height: 0;
            }
            &.hidden {
                display: none;
            }
        }

        // .popup__wrap-top {
        //     padding: 20px;
        // }

        .popup__section-price {
            margin-top: 24px;

            .ant-slider {
                max-width: 95%;
                margin: 0 auto 6px;
            }
        }

        .filters__date-range-dds {
            flex-wrap: wrap;
            justify-content: space-between;
            padding-bottom: 9px;
            margin: 0 0 12px;

            > div {
                max-width: 48%;
                width: 100%;
                padding: 0;
                margin: 0;
                margin-bottom: 9px;
            }
        }

        .filters__select {
            .form-element {
                width: 100%;
            }

            .ant-select {
                width: 100% !important;
            }

            .select-options {
                font-size: 14px;
                line-height: 16px;
            }

            .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                padding: 11px 13px 7px;
            }

            .ant-select-selector {
                height: 50px;
            }

            .ant-select-selection-search {
                width: 100% !important;
                max-width: 100%;
                margin: 0;
                margin-inline-start: 0px;

                input {
                    font-size: 14px;

                    &::placeholder {
                        font-size: 14px;
                    }
                }
            }
        }

        .popup__wrap-bottom {
            padding: 19px 20px;
        }
    }

    @include mq(400px) {
        .popup--filters {
            height: 701px;
            form {
                height: 650px;
            }
        }

        .popup__heading {
            font-size: 14px !important;
            margin-bottom: 13px;
        }

        .popup__wrap-top {
            // padding: 0 13px 0 16px;

            h3:not(.filters__select-title) {
                font-size: 16px;
                letter-spacing: -0.15px;
                margin-bottom: 3px;
            }

            p {
                font-size: 12px;
                line-height: 17px;
            }
        }

        .popup__section-price {
            margin-bottom: 13px;

            .ant-slider {
                max-width: 91%;
            }
        }

        .filters__price-inputs {
            label {
                font-size: 10px;
            }

            .form-element {
                width: 130px;
            }

            .form-element--input {
                .form-element__prefix {
                    font-size: 12px;
                    top: 6px;
                    left: 6px;
                }

                input {
                    font-size: 12px;
                    padding: 5px 12px 4px 16px;
                    height: 28px;
                    margin-top: 2px;
                }
            }
        }

        .popup__wrap-top p b {
            font-size: 10px;
            line-height: 16px;
        }

        .filters__date-range-dds {
            flex-wrap: wrap;
            justify-content: space-between;
            padding-bottom: 9px;
            margin: 0 0 12px;

            > div {
                max-width: 44%;
                width: 100%;
                padding: 0;
                margin: 0;
                margin-bottom: 9px;

                @include mq(353px) {
                    max-width: 48%;
                }
            }
        }

        .filters__select {
            .form-element {
                width: 100%;
            }

            .ant-select {
                width: 100% !important;
            }

            .select-options {
                font-size: 12px;
                line-height: 14px;
            }

            .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                padding: 11px 13px 7px;
            }

            .ant-select-selector {
                height: 49px;
            }

            .ant-select-selection-search {
                width: 100% !important;
                max-width: 100%;
                margin: 0;
                margin-inline-start: 0px;

                input {
                    font-size: 12px;

                    &::placeholder {
                        font-size: 12px;
                    }
                }
            }
        }

        .filters__select-title {
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 6px;

            @include mq(353px) {
                font-size: 11px;
            }
        }

        .ant-select-arrow {
            right: 12px;
        }

        .filters__select-legend--listing {
            transform: translateY(-2px);
        }

        .filters__select-legend {
            margin-left: 12px;
            transform: translateY(-1px);
        }

        .popup__heading-brokerages {
            margin-bottom: 10px !important;
        }

        .filters-dropdowns .ant-select-selection-search-input {
            width: 100%;
            padding-left: 8px !important;
            padding-right: 30px !important;
        }

        .filters__dropdowns-brokerages .filters-dropdown__icon {
            top: 42px;
            right: 17px;
        }

        .popup__wrap-bottom {
            align-items: flex-end;
            padding: 16px;
        }
    }
}

.filters-popup-open {
    display: block;
}

.filters-popup-closed {
    display: none;

    @include mq($md) {
        display: block;
        .overlay {
            display: none;
        }
    }
}

.popup--member {
    height: 584px;

    .popup__inner {
        height: 100%!important;
    }
}
