.calculator-mini-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.32);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    &--overwrite {
        text-align: center;
    }
}

.calculator-mini-popup__box {
    position: relative;
    overflow: hidden;
    max-width: 620px;
    width: 100%;
    // height: 299px;
    border-radius: 12px;
    background-color: $white;
    box-shadow: 0px 24px 24px 0px rgba(0, 0, 0, 0.3),
        0px 0px 24px 0px rgba(0, 0, 0, 0.22);
    padding: 24px 40px 30px;

    @include mq(500px) {
        padding: 24px 15px 30px;
    }
}

.calculator-mini-popup__box-title {
    color: $blue-whale;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    font-family: $font-primary;
    margin-bottom: 10px;
    line-height: 23px;
    &--overwrite {
        margin-bottom: 33px;
    }
}

.calculator-mini-popup__box-text {
    color: $boulder2;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 27px;
    &--overwrite {
        text-align: center;
        max-width: 465px;
        margin: 0 auto 16px;
        line-height: 20px;
        color: $blue-whale;
    }
}

.calculator-mini-popup__box-copy-title {
    color: $blue-whale;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    font-family: $font-primary;
    font-size: 13px;
    display: block;
    margin-bottom: 4px;
}

.calculator-mini-popup__box-copy-field {
    border-radius: 4px;
    border: 1px solid #c9cccf;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    width: 100%;
    padding: 0 7px 0 11px;
}

.calculator-mini-popup__box-copy-field-link-holder {
    color: $boulder2;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    max-width: 448px;
    width: 100%;
    position: relative;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
}

.calculator-mini-popup__box-copy-field-link-copy-btn {
    border-radius: 2px;
    border: 1px solid #c9cccf;
    background-color: $white;
    min-width: 69px;
    height: 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: $boulder2;
    position: relative;

    svg {
        margin-right: 6px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -25px;
        transform: translateY(-50%);
        width: 24px;
        height: 100%;
        // Make a white transition background from left to right, left is 0% and right is 100%
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        );
    }
}

.calculator-mini-popup__box-socials-wrapper {
    margin-top: 27px;
}

.calculator-mini-popup__box-socials-title {
    color: $blue-whale;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 18px;
    display: block;
}

.calculator-mini-popup__box-socials-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include mq(380px) {
        justify-content: space-between;
    }
}

.calculator-mini-popup__box-socials-icon {
    margin-right: 24px;
    display: block;
    cursor: pointer;

    @include mq(380px) {
        max-width: 20px;
    }

    svg {
        transition: transform 0.3s ease-in-out;
    }
    &:hover svg {
        transform: scale(1.2);
    }
}

.calculator-mini-popup__close {
    position: absolute;
    right: 40px;
    top: 24px;
    cursor: pointer;
    svg {
        font-size: 23px;
        fill: $boulder2;
        color: $boulder2;
        transition: all 0.3s ease-in-out;
    }

    @include mq(500px) {
        right: 15px;
        top: 10px;
    }

    &:hover {
        svg {
            fill: $blue-whale;
            color: $blue-whale;
        }
    }
}

.calculator-mini-popup__address {
    display: inline-block;
    border-radius: 4px;
    background: rgba(34, 170, 148, 0.1);
    padding: 8px 11px 8px 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: $blue-whale;
    margin-bottom: 32px;
    svg {
        margin-top: -2px;
        margin-right: 4px;
        font-size: 16px;
    }
    span {
        margin-top: 1px;
        display: inline-block;
    }
}

.calculator-mini-popup__footer {
    display: flex;
    justify-content: flex-end;
    margin: 0 -40px -30px;
    padding: 16px 40px;
    border-top: 1px solid #d9d9d9;
    .btn {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-left: 10px;
        border: none;
        box-shadow: none;
        padding: 8px 29px;
        border-radius: 4px;
        &--primary {
            color: $white;
            background-color: $blue-whale;
            @include hover {
                background-color: $jungle-green2;
            }
        }
        &--secondary {
            color: $jungle-green2;
            background-color: transparent;
            @include hover {
                text-decoration: underline;
            }
        }
    }
}
