.row {
	display: flex;
	height: 100vh;
	align-items: center;
	background: url('https://media.gparency.com/dev/login-landing/assets/img/map.webp') center center no-repeat;
	background-size: cover;
}
.col {
	width: 0%;
	min-height: 100vh;
	align-items: center;
	display: inline-flex;
	position: relative;
	overflow: hidden;
	margin: auto;
	justify-content: center;
}
.col-light:before {
	content: "";
	position: absolute;
	background: black;
	width: 100%;
	z-index: 1;
	height: 100%;
	opacity: 0.7;
}
.login-box {
	text-align: center;
	z-index: 10
}
.login-box-inner {
	width: 100%;
	border-radius: 10px;
	box-shadow: 0 4px 20px 0 rgba(31,31,58,.15);
	text-align: center;
	padding: 10px;
	z-index: 100;
}