.bubble-card {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.14);
    background-color: $white;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(32px, 25px) scale(0);
    opacity: 0;
    border-radius: 8px;
    padding: 16px 20px;
    max-width: 206px;
    width: 206px;
    transition: $dur $ease;
    z-index: 99;
}

.bubble-card__title {
    display: block;
    margin-bottom: 14px;
    color: $blue-whale;
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    font-family: $font-primary;
}

.bubble-card__list {
    margin-bottom: 0;
}

.bubble-card__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.top-item {
        margin-bottom: 10px;
        padding-bottom: 7px;
        border-bottom: 1px solid #d9d9d9;
    }
}

.bubble-card__item-name {
    color: $boulder;
    font-size: 10px;
    font-family: $font-primary;
    line-height: 14px;
    margin-right: auto;
    max-width: 122px;
    &.top-name {
        font-size: 12px;
    }
}

.bubble-card__item-square {
    min-width: 10px;
    height: 10px;
    border-radius: 4px;
    margin-right: 8px;

    &--dark-green {
        background-color: $blue-whale;
    }

    &--dark-gray {
        background-color: #459689;
    }

    &--gray {
        background-color: #37b1c3;
    }

    &--green {
        background-color: $jungle-green;
    }

    &--navy {
        background-color: #3366cc;
    }

    &--red {
        background-color: #fa6c3d;
    }

    &--yellow {
        background-color: #ffcb77;
    }

    &.top-square {
        width: 12px;
        height: 12px;
    }
}

.bubble-card__item-value {
    color: $boulder;
    font-size: 10px;
    font-family: $font-primary;
    line-height: 14px;
    padding: 5px 8px;
    margin-right: 3px;
    border-radius: 4px;
    &.top-value {
        background-color: $alabaster;
        font-size: 12px;
    }
}

// map cluster
.map-cluster__bubble {
    border-radius: 50%;
    min-height: 42px;
    max-height: 42px;
    height: 100%;
    width: 100%;
    max-width: 42px;
    min-width: 42px;
    display: block;

    .map-cluster__bubble-holder {
        display: block;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        background-color: $blue-whale;
        border-radius: 50%;
        min-height: 42px;
        max-height: 42px;
        height: 100%;
        width: 100%;
        max-width: 42px;
        min-width: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: 14px;
        font-family: $font-primary;
        line-height: 16px;
        cursor: context-menu;
        box-shadow: 0 0 0 0 rgba($blue-whale, 0);
        transition: $dur $ease;
        box-shadow:
            0 6px 10px rgba(0, 0, 0, 0.14),
            0 1px 18px rgba(0, 0, 0, 0.12),
            0 3px 5px -1px rgba(0, 0, 0, 0.2);

        span {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -44%);
            left: 50%;
        }
    }
}

.map-pill__inner {
    font-family: $font-primary;
    font-weight: 500;
}

.map-cluster {
    position: relative;
    min-height: 42px;
    max-height: 42px;
    height: 100%;
    width: 100%;
    max-width: 42px;
    min-width: 42px;
    display: block;
    font-weight: 500;

    &.left .bubble-card {
        right: 140px;
        left: unset;
    }

    &.bottom .bubble-card {
        top: -60px;
        bottom: unset;
    }

    &.full-bottom .bubble-card {
        top: -10px;
        bottom: unset;
    }

    &.full-top .bubble-card {
        top: -190px;
        bottom: unset;
    }

    &.map-cluster-active {
        z-index: 5;
        .bubble-card {
            transform: translate(66px, 25px) scale(1);
            opacity: 1;
        }

        .map-cluster__bubble {
            .map-cluster__bubble-holder {
                transform: scale(1.15) translate(-50%, -50%);
                box-shadow: 0 0 0 11px rgba($blue-whale, 15%);
            }
        }
    }
}

// map pills
.map-pill {
    background-color: $white;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.12);
    padding: 8px 14px;
    font-size: 16px;
    line-height: 16px;
    color: $blue-whale;
    min-width: 78px;
    display: flex;
    justify-content: center;
    width: fit-content;
    border-radius: 15px;
    transition: $dur $ease;
    cursor: pointer;

    span {
        display: block;
    }
}

.map-pill__wrapper {
    transition: $dur $ease;
    div,
    span {
        font-weight: 500;
    }

    .large {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 19px;
        padding: 3px;
        font-size: 16px;
        color: $white !important;
        background-color: $blue-whale;
        box-shadow:
            0px 1px 1px rgba(0, 0, 0, 0.14),
            0px 2px 1px -1px rgba(0, 0, 0, 0.12),
            0px 1px 3px rgba(0, 0, 0, 0.2);
        line-height: 1px;
        min-width: 18px;
        svg {
            width: 10px;
            height: 8px;
        }
    }

    &.active {
        position: relative;
        z-index: 99;

        .map-pill {
            &.large {
                padding: 4px;
                min-width: 20px;
            }

            &.blue-circle,
            &.green-circle,
            &.grey-circle {
                border-radius: 19px;
                padding: 8px 16px;
                font-size: 18px;
                background-color: $blue-whale !important;
                color: $white !important;
                line-height: 16px;
                border-radius: 50%;
                padding: 0;
                font-size: 0;
                color: $white;
                background-color: $blue-whale;
                height: 20px;
                width: 20px;
                min-width: 16px;
                border: 2px solid $blue-whale;
                box-shadow:
                    0 4px 5px rgba(0, 0, 0, 0.14),
                    0 1px 10px rgba(0, 0, 0, 0.12),
                    0 2px 4px -1px rgba(0, 0, 0, 0.2);

                @include hover {
                    background-color: $blue-whale;
                }
            }
        }
    }
    @include hover {
        .large {
            padding: 4px;
            min-width: 20px;
        }
    }

    span {
        font-family: $font-primary;
    }
}

.ant-popover-inner-content:has(.popover-pill-wrapper) {
    padding: 8px 12px 12px 12px;
}

.ant-popover-inner {
    border-radius: 8px;
}

.popover-pill-address__property-type {
    text-align: center;
    font-size: 0.85rem;
    color: #03363d;
    background: #e7f2f0;
    font-weight: 500;

    &__icon {
        margin-right: 5px;
    }
}

.popover-pill-address__title {
    text-align: left;
    color: #757575;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
}

.popover-pill-address-text {
    color: #03363d;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    user-select: none;
    white-space: nowrap;
}

.map-pill__favorite {
    pointer-events: none;
    svg {
        position: absolute;
        right: -22px;
        top: -3px;
        z-index: 1;
        transition: $dur $ease;
        &:first-child {
            color: $white;
            height: 10px;
            right: -16px;
            top: -3px;
        }
        &:last-child {
            color: $burn-orange;
            height: 8px;
            top: -2px;
            right: -15px;
        }
    }

    &.fa-green-circle,
    &.fa-blue-circle {
        svg {
            &:first-child {
                right: -16px;
                top: -3px;
            }
            &:last-child {
                top: -2px;
                right: -15px;
            }
        }
    }
}

.map-pill.large + .map-pill__favorite {
    svg {
        &:first-child {
            right: -22px;
            top: -5px;
        }
        &:last-child {
            top: -4px;
            right: -21px;
        }
    }
}

.blue-circle:hover,
.green-circle:hover,
.grey-circle:hover {
    & + .fa-green-circle,
    & + .fa-blue-circle,
    & + .fa-grey-circle {
        svg {
            &:first-child {
                right: -19px;
                top: -1px;
            }
            &:last-child {
                top: 0px;
                right: -17px;
            }
        }
    }
}

.map-pill__wrapper.active {
    .blue-circle,
    .green-circle,
    .grey-circle {
        & + .fa-green-circle,
        & + .fa-blue-circle,
        & + .fa-grey-circle {
            svg {
                &:first-child {
                    right: -22px;
                    top: -5px;
                    height: 12px;
                }
                &:last-child {
                    top: -4px;
                    right: -21px;
                    height: 10px;
                }
            }
        }
    }
}

// circle pills
.blue-circle,
.green-circle,
.grey-circle,
.navy-circle,
.red-circle,
.yellow-circle {
    height: 14px;
    width: 14px;
    min-width: 14px;
    border-radius: 19px;
    position: relative;
    border: 1px solid $white;
    box-shadow: 1px 0 1px rgba(0, 0, 0, 0.12);
    margin-right: 8px;
    transition: $dur $ease;
    padding: 0;
    font-size: 0;
    box-shadow:
        0 1px 1px rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12),
        0 1px 3px rgba(0, 0, 0, 0.2);
    &:hover {
        width: 16px;
        height: 16px;
        min-width: 16px;
    }
}

.blue-circle {
    background-color: #37b1c3;

    @include hover {
        background-color: #2a8693;
    }
}

.green-circle {
    background-color: #459689;

    @include hover {
        background-color: #306960;
    }
}

.navy-circle {
    background-color: #3366cc;

    @include hover {
        background-color: #3366cc;
    }
}

.red-circle {
    background-color: #fa6c3d;

    @include hover {
        background-color: #fa6c3d;
    }
}

.yellow-circle {
    background-color: #ffcb77;

    @include hover {
        background-color: #ffcb77;
    }
}

.grey-circle {
    background-color: #a2acba;

    @include hover {
        background-color: #a2acba;
    }
}

.location-slash {
    height: 33px;
    width: 33px;
    border-radius: 19px;
    background-color: $blue-whale;
    box-shadow:
        0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px -1px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        color: $white;
        height: 18px;
        line-height: 16px;
    }
}
