@font-face {
    font-family: "ABCMonumentGrotesk";
    src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Regular.eot");
    src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Regular.woff")
            format("woff"),
        url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Regular.ttf")
            format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

#indcs__disclaimer {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    color: $boulder2;

    @include mq($md) {
        padding-right: 20px;
    }
}

#indcs__spacer {
    height: 16px;
}

#indcs {
    overflow-x: hidden;
    position: relative;
    padding-left: 14px;
    height: 100%;
    overflow-y: auto;
    transition: $dur $ease;
    @include scroll-style($boulder, $alabaster, 0, 0.4vw, 0);
    @media screen and (max-width: 767px) {
        &.open {
            z-index: inherit;
            position: fixed;
            top: 70px;
            background-color: $white;
            height: calc(100vh - 70px);
            overflow: scroll;
        }
        &.closed {
            height: 0;
        }

        tbody {
            transform: translateY(-2px);
        }
    }

    // Supports query for dvh
    @supports (height: 100dvh) {
        @media screen and (max-width: 767px) {
            &.open {
                height: calc(100dvh - 70px);
            }
        }
    }
}

#indcs {
    @include mq($md) {
        padding-top: 16px;

        > table {
            width: 100%;
        }
    }
}

.indcs__header {
    text-align: left;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px !important;
    color: $boulder2;
    vertical-align: middle !important;
}

.indcs__header-wrap {
    display: none;
    @include mq($md) {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        padding: 0 44px 16px 30px;

        button {
            background: transparent;
            border: none;
            padding: 0;
            font-size: 10px;
            font-family: $font-primary;
            color: $jungle-green2;
            position: absolute;
            left: 0;
            top: 2px;
            padding-left: 16px;

            svg {
                font-size: 15px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        h3 {
            font-size: 14px;
            font-family: $font-primary;
            font-weight: 400;
            line-height: 14px;
            color: $blue-whale;
        }
    }
}

.indcs__change-postion {
    padding-left: 4px;
    vertical-align: middle !important;
}
.indcs__clmn-1 {
    width: 219px;

    @include mq($md) {
        width: 200px;
    }
}

.indcs__clmn-2 {
    min-width: 80px;
    text-align: right;

    @include mq($md) {
        min-width: 72px;
    }
}

#indcs___hdr-3 {
    width: 213px;
    text-align: right;
}

#indcs__c-i-r {
    vertical-align: text-top;
}

.indcs__clmn-3 {
    width: 167px;
    padding-right: 46px;
    text-align: right;

    @include mq($md) {
        padding-right: 19px;
    }
}

td {
    padding-top: 8px;
    padding-bottom: 8px;
    vertical-align: middle;

    @include mq($md) {
        padding-top: 8px;
        padding-bottom: 6px;
    }
}

.indcs__rows {
    padding: 8px;
    border-bottom: 1px solid $clear-cloud;
}

tr:last-child {
    border-bottom: none;
}

.indcs__td {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
}

.indcs__td-name-and-rate {
    color: $blue-whale;
}

.indcs__td-name-and-rate {
    color: $blue-whale;
}

.indcs__ngtv-clr {
    color: #e91c1c;
}

.indcs__unchanged-clr {
    color: $black;
}

.indcs__pstv-clr {
    color: #078364;
}

#indcs__info {
    width: 14px !important;
    height: 14px !important;
    padding-left: 6px;
    padding-right: 26px;
    color: $gray;
    cursor: pointer;
    div {
        position: absolute;
        top: 28px;
        left: 57%;
        padding: 8px;
        width: 202px;
        height: 70px;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        background-color: $blue-whale;
        font-weight: 400;
        color: $white;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        transition: 0.2s ease;
        font-weight: 400;
        font-family: $font-primary;
        &::before {
            content: "";
            position: absolute;
            width: 10;
            height: 8;
            border-width: 0 7px 11px 7px;
            border-color: transparent transparent $blue-whale transparent;
            border-style: solid;
            top: -9px;
            left: 180px;
        }

        @include mq($sm) {
            left: auto;
            right: 10px;
            top: 26px;
        }
        @media screen and (max-width: 991px) {
            left: auto;
            right: 10px;
            top: 26px;
        }

        @include mq(340px) {
            right: 6px;
        }
    }

    svg {
        &:hover + div {
            opacity: 1;
            visibility: visible;
        }
    }

    @include mq($md) {
        padding-right: 18px;
    }

    @include mq(340px) {
        padding-right: 15px;
        padding-left: 2px;
    }
}

#indcs__info-stocks {
    width: 14px !important;
    height: 14px !important;
    padding-left: 6px;
    padding-right: 26px;
    color: $gray;
    cursor: pointer;
    vertical-align: baseline !important;
    div {
        position: absolute;
        top: 28px;
        left: 57%;
        padding: 8px;
        width: 202px;
        height: 70px;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        background-color: $blue-whale;
        font-weight: 400;
        color: $white;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        transition: 0.2s ease;
        font-weight: 400;
        font-family: $font-primary;
        &::before {
            content: "";
            position: absolute;
            width: 10;
            height: 8;
            border-width: 0 7px 11px 7px;
            border-color: transparent transparent $blue-whale transparent;
            border-style: solid;
            top: -9px;
            left: 180px;
        }

        @include mq($sm) {
            left: auto;
            right: 10px;
            top: 26px;
        }

        @media screen and (max-width: 991px) {
            left: auto;
            right: 10px;
            top: 26px;
        }

        @include mq(340px) {
            right: 6px;
        }
    }

    svg {
        &:hover + div {
            opacity: 1;
            visibility: visible;
        }
    }

    @include mq($md) {
        padding-right: 18px;
    }

    @include mq(340px) {
        padding-right: 15px;
        padding-left: 2px;
    }
}
