/*--------------------------------------------------------------
## Button Component Style
--------------------------------------------------------------*/
.btn {
    font-weight: 500;
}

//Styles for PartRadioButton component

.label-tooltip {
    margin-left: 5px;
    position: relative;

    svg {
        color: $gray;
    }
    & svg:hover + .label-tooltip__text {
        opacity: 1;
        pointer-events: all;
    }
}

.label-tooltip__text {
    position: absolute;
    left: -110px;
    top: -85px;
    width: 225px;
    height: 76px;
    background: var(--primary, #03363d);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 1;
    color: $white;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    padding: 14px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;

    &:before {
        content: "";
        position: absolute;
        left: 51%;
        top: 100%;
        margin-left: -5px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid var(--primary, #03363d);
    }
}
