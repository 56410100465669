.property-card__tabs .ant-tabs-content-holder {
    scroll-behavior: smooth;
}

.property-card__tabs--documents .ant-tabs-content-holder {
    background-color: rgba($jungle-green2, 0.1);
}

.property-card__tabs--documents .ant-tabs-content {
    background-color: transparent;
}

.upload-documents {
    padding: 16px 22px 20px;

    * {
        font-family: $font-primary;
    }
    h4 {
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        color: $blue-whale;
        margin-bottom: 8px;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.2);
        border-radius: 4px;
        z-index: 1;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s ease;
    }
}

.upload-documents__wrapper {
    &.loading {
        display: flex;
        justify-content: center;
        .ant-spin {
            margin-top: 10px;
            svg {
                fill: $jungle-green2;
            }
        }
    }
}

.upload-documents__title {
    margin-top: 16px;
}

.upload-documents__dragger {
    margin-bottom: 10px;
    height: 100px;
    padding: 5px;
    background: $white;
    border-radius: 4px;

    &.error {
        background-color: transparent;
        height: auto;
        padding: 0;

        .upload-documents__error {
            margin-left: 0;
        }
    }
}

.ant-upload.ant-upload-drag {
    background-color: $white;
    border: none;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23757575' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='9' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px;
}

.ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 6px;

    svg {
        color: $boulder2;
    }
}

.ant-upload.ant-upload-drag p.ant-upload-text {
    color: #757575;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
}

.ant-upload-text-limit {
    color: #757575;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
}

.ant-upload-button {
    color: #22aa94;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    border: none;
    padding: 0;
    background-color: transparent;
}

.upload-documents__error {
    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
    color: $red;
    margin-bottom: 16px;
    margin-left: 5px;
    &.hidden {
        display: none;
    }
}

.upload-documents__item {
    min-height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
    background-color: $white;
    border-radius: 4px;
    padding: 0 38px 0 31px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 0 0 0 $jungle-green2;

    > .ant-spin {
        display: none;
    }

    &.delete-opened .upload-documents__item-main {
        opacity: 0.5;
    }

    &.is-deleting {
        pointer-events: none;
        opacity: 0.5;
        > .ant-spin {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            svg {
                fill: $jungle-green2;
            }
        }
    }

    &:hover {
        // Give it a subtle box shadow
        box-shadow: 0 0 0 1px $jungle-green2;
    }
}

.upload-documents__loader {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 20px;
    .ant-spin-dot {
        font-size: 11px !important;
    }
    svg {
        width: 100%;
        color: $jungle-green2;
    }
}

.upload-documents__link {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 40px;
}

.upload-documents__item-main {
    display: flex;
    // flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
    max-width: 245px;
    width: 100%;
    overflow: hidden;
}

.upload-documents__remove-item,
.upload-documents__remove-btn {
    cursor: pointer;
    position: absolute;
    right: 0;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.upload-documents__remove-item {
    width: 77px;
    pointer-events: none;
    transition: all 0.3s ease;
    color: $white;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;

    height: 100%;
    border: none;
    right: -82px;
    background-color: $jungle-green2;

    svg {
        margin-right: 5px;
    }
}

.upload-documents__remove-btn {
    opacity: 1;
    transition: all 0.3s ease;
    width: 19px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.3s ease;

    &:hover {
        background-color: $cloudy;
    }

    &.has-error {
        color: $red;
    }

    svg {
        color: #757575;
    }
}

.upload-documents__buttons {
    margin-left: auto;
    &.show-delete {
        .upload-documents__remove-item {
            pointer-events: all;
            right: 0;
        }
        .upload-documents__remove-btn {
            opacity: 0;
            pointer-events: none;
        }
    }
}

.upload-documents__item-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    margin-right: 8px;

    svg {
        color: $blue-whale;
        font-size: 15px;
    }
}

.upload-documents__file-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.upload-documents__item-name {
    font-weight: 500;
    font-size: 10px;
    color: $blue-whale;
}

.upload-documents__item-file-size {
    color: $boulder2;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
}

.upload-documents__no-doc-text {
    color: $boulder2;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
}

.dragging-over {
    // position: relative;
    // Make a overlay
    &:before {
        opacity: 1;
        pointer-events: all;
        transition: all 0.3s ease;
    }

    .upload-documents__dragger {
        position: relative;
        z-index: 2;
    }
}

.ant-progress {
    margin-top: -7px;
    width: 263px;
}

.ant-progress-bg {
    background-color: $jungle-green2 !important;
    height: 5px !important;
}

.ant-progress-inner {
    background-color: #e0e0e0 !important;
    height: 5px !important;
    border-radius: 10px;
}

.ant-progress-text {
    position: absolute;
    right: 10px;
    bottom: 9px;
    font-size: 10px;
    font-weight: 500;
    color: $blue-whale;
}
