.popup-favorites {
    display: flex;
    flex-direction: column;
    padding: 25px 40px 0;
    width: 704px;
    max-width: 93vw;
    height: auto;
    min-height: 588px;
    h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: $blue-whale;
        margin-bottom: 4px;
    }
    & > h3 {
        margin-bottom: 24px;
        text-align: center;
    }
    .popup__close {
        top: 24px;
        right: 40px;
    }
    p {
        font-size: 14px;
        line-height: 22px;
        color: $boulder2;
        margin-bottom: 16px;
    }
    hr {
        color: #d9d9d9;
        margin: 32px 0;
    }
    .ant-select-multiple {
        .ant-select-selector {
            width: 390px;
            height: 36px;
            border: 1px solid #c9cccf !important;
            box-shadow: none !important;
            border-radius: 4px;
        }
    }
    .ant-select-selection-overflow-item:not(:nth-last-child(2)) {
        display: none;
    }
    .ant-select-selection-item-remove {
        display: none;
    }
    .ant-select-selection-item {
        background-color: transparent;
        border: none;
    }
    .ant-select-selection-placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $boulder2;
        padding-top: 1px;
    }
    @include mq(874px) {
        padding: 25px 28px 0;
    }
    @include mq(768px) {
        .ant-select-multiple {
            margin-bottom: 20px;
        }
    }
    @include mq(767px) {
        min-height: auto;
        height: 96dvh;
    }
    @include mq(650px) {
        hr {
            margin: 22px 0;
        }
    }
    @include mq(550px) {
        hr {
            margin: 12px 0 15px;
        }
    }
    @include mq(480px) {
        .ant-select-multiple {
            .ant-select-selector {
                width: 294px;
            }
        }
    }
}

.popup-favorites__placeholder-checkbox {
    position: absolute;
    z-index: 1;
    left: 13px;
    top: 6px;
    & + .ant-select .ant-select-selection-placeholder {
        left: 38px;
    }
}

.popup-favorites__dropdown {
    z-index: 999999999;
    .ant-select-item {
        padding: 7px 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $blue-whale;
        position: relative;
    }
    .ant-checkbox-wrapper {
        margin-right: 8px;
    }
}

.popup-favorites__footer {
    text-align: right;
    padding: 16px 40px;
    margin: auto -40px 0;
    border-top: 1px solid #d9d9d9;
    button {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        background-color: $blue-whale;
        color: $white;
        padding: 8px 37px;
        box-shadow: none;
        border: 0;
        border-radius: 4px;
        transition: all 0.2s ease;
        &:hover {
            background-color: $jungle-green2;
        }
    }
}

.popup-favorites__label {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    .form-element {
        margin-bottom: 5px;
    }
    svg {
        margin-left: -4px;
        color: $gray;
        &:hover + span {
            opacity: 1;
            visibility: visible;
        }
    }
    .ant-checkbox {
        margin-bottom: -2px;
    }
}

.popup-favorites__tooltip {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    padding: 14px;
    background-color: $blue-whale;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $white;
    width: 295px;
    left: 188px;
    transition: all 0.2s ease;
    &::before {
        content: "";
        position: absolute;
        left: -8px;
        top: 23px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 9px 6px 0;
        border-color: transparent $blue-whale transparent transparent;
    }
}

// .popup-favorites__group-skeleton-wrap {
//     .my-properties__skeleton-item {
//         width: 50%;
//         height: 90px;
//     }
// }

// .popup-favorites__dropdown-skeleton-wrap {
//     .my-properties__skeleton-item {
//         width: 50%;
//     }
// }

.popup-favorites__group {
    position: relative;
    display: grid;
    grid-template-columns: minmax(0%, 28%) 38% minmax(0, 34%);
    grid-auto-rows: 30px;
    .ant-checkbox-group-item {
        margin-bottom: 8px;
        margin-right: 0;
        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(7) {
            grid-column: 1;
        }
        &:nth-child(2),
        &:nth-child(5) {
            grid-column: 2;
        }
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(8) {
            grid-column: 3;
        }
        &:nth-child(5) {
            position: absolute;
            top: 46px;
            width: 230px;
            @include mq(806px) {
                width: 206px;
            }
            @include mq(650px) {
                position: static;
                width: 50%;
            }
            @include mq(550px) {
                width: 100%;
            }
        }
    }
    @include mq(844px) {
        grid-auto-rows: 35px;
    }
    @include mq(806px) {
        grid-template-columns: minmax(0%, 26%) 41% minmax(0, 36%);
    }
    @include mq(650px) {
        display: flex;
        flex-wrap: wrap;
        .ant-checkbox-group-item {
            width: 50%;
        }
    }
    @include mq(550px) {
        display: block;
        .ant-checkbox-group-item {
            width: 100%;
        }
    }
}

.popup-favorites__group,
.popup-favorites__dropdown,
.popup-favorites__dropdown-wrap .ant-select-selection-item-content {
    .ant-checkbox {
        & + span {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $blue-whale;
        }
        .ant-checkbox-inner {
            border: 2px solid $silver;
            border-radius: 4px !important;
        }

        &.ant-checkbox-checked .ant-checkbox-inner {
            background-color: $blue-whale;
            border: 2px solid $blue-whale;
        }

        &.ant-checkbox-checked::after {
            border: 2px solid $blue-whale;
        }

        .ant-checkbox-inner::after {
            left: 20.5%;
        }

        &:hover {
            .ant-checkbox-inner {
                border-color: $blue-whale;
            }
        }
    }
}

.popup-favorites__dropdown-wrap {
    position: relative;
    display: inline-block;
    margin-top: 8px;
    & > svg {
        position: absolute;
        width: 13px;
        height: 14px;
        color: $boulder2;
        right: 12px;
        top: 11px;
        pointer-events: none;
        transition: transform 0.2s ease;
    }
    .ant-select-open {
        & + svg {
            transform: rotate(180deg);
        }
    }
    .ant-checkbox {
        margin-right: 8px;
        top: 4px;
    }
    .ant-checkbox-wrapper {
        pointer-events: none;
    }
}

.popup-favorites__skeleton-row {
    // display: flex;
    // align-items: center;
    // padding: 4px 0;
    // &:first-child {
    //     padding-top: 0;
    // }
}

.popup-favorites__skeleton-item {
    display: block;
    width: 150px;
    height: 28px;
    position: relative;
    background-color: $clear-cloud;
    margin-right: 15px;
    flex-shrink: 0;
    &:first-child {
        // width: 121px;
    }
    &:nth-child(2) {
        // width: 72px;
        &::before {
            animation-delay: 0.1s;
        }
    }
    &:nth-child(3) {
        // width: 48px;
        &::before {
            animation-delay: 0.15s;
        }
    }
    &:nth-child(4) {
        // width: 81px;
        &::before {
            animation-delay: 0.2s;
        }
    }
    &:nth-child(5) {
        // width: 81px;
        &::before {
            animation-delay: 0.25s;
        }
    }
    &:nth-child(6) {
        // width: 35px;
        &::before {
            animation-delay: 0.3s;
        }
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(
            to right,
            rgba(#000, 0.03) 0%,
            rgba(#000, 0.045) 10%,
            rgba(#000, 0.065) 20%,
            rgba(#000, 0.07) 40%,
            rgba(#000, 0.065) 70%,
            rgba(#000, 0.045) 80%,
            rgba(#000, 0.03) 100%
        );
        background-repeat: no-repeat;
        background-size: 342px 181px;
        animation: shimmer 1.7s linear infinite;
        overflow: hidden;
    }
}

.popup-favorites__skeleton-row {
    position: relative;
    display: grid;
    grid-template-columns: minmax(0%, 28%) 28% minmax(0, 36%);
    grid-auto-rows: 30px;

    @include mq(844px) {
        grid-auto-rows: 35px;
    }
    // @include mq(806px) {
    //     grid-template-columns: minmax(0%, 26%) 41% minmax(0, 36%);
    // }
    @include mq(650px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .popup-favorites__skeleton-item {
            width: 49%;
        }
    }
    @include mq(550px) {
        display: block;
        .popup-favorites__skeleton-item {
            width: 100%;
        }
    }
}

.popup-favorites__skeleton-item {
    margin-bottom: 8px;
    margin-right: 0;
    // width: 100%;
    &:nth-child(1),
    &:nth-child(4),
    &:nth-child(7) {
        grid-column: 1;
    }
    &:nth-child(2),
    &:nth-child(5) {
        grid-column: 2;
    }
    &:nth-child(3),
    &:nth-child(6),
    &:nth-child(8) {
        grid-column: 3;
    }
    &:nth-child(5) {
        position: absolute;
        top: 46px;
        // @include mq(806px) {
        //     width: 206px;
        // }
        @include mq(650px) {
            position: relative;
            // width: 50%;
            top: auto;
        }
        @include mq(550px) {
            width: 100%;
        }
    }

    // @include mq(767px) {
    //     width: 100%;
    //     max-width: 342px;
    // }
}

.popup-favorites__header {
    h3 {
        display: inline-block;
    }

    &__address {
        display: inline-block;
        font-size: 11px;
        padding-left: 20px;
        margin-top: -50px;
        &-text {
            font-size: 12px;
            padding-left: 5px;
            color: $blue-whale;
        }
    }
}

@keyframes shimmer {
    0% {
        background-position: -342px 0;
    }
    25% {
        background-position: 342px 0;
    }
    100% {
        background-position: 342px 0;
    }
}

.popup-favorites--affilliated {
    align-items: flex-start;
    min-height: 285px;
    padding: 47px 40px 0;

    & > h3 {
        margin-bottom: 5px;
    }

    .popup-favorites__footer {
        width: 100%;
        margin: 37px 0 0;
        padding: 16px 0;

        &:before {
            content: "";
            position: absolute;
            top: -1px;
            left: -100%;
            width: 100%;
            height: 1px;
            background-color: #d9d9d9;
        }

        // Do the same for after
        &:after {
            content: "";
            position: absolute;
            top: -1px;
            right: -100%;
            width: 100%;
            height: 1px;
            background-color: #d9d9d9;
        }
    }
}
