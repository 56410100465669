.property-card__tabs {
    // navigation
    .ant-tabs-nav {
        padding: 0;
        margin: 0;
    }

    .ant-tabs-ink-bar {
        display: none;
    }

    .ant-tabs-nav-list {
        width: 100%;
    }

    .ant-tabs-tab {
        padding: 12px 4px;
        display: flex;
        justify-content: center;
        flex: 1 1 25%;
        backface-visibility: hidden;

        @include mq($xl) {
            padding: 12px 9px;
        }

        @include mq($lg) {
            padding: 12px 4px;
        }

        @include hover {
            &:not(.ant-tabs-tab-active) {
                .ant-tabs-tab-btn {
                    color: $jungle-green2;
                    svg {
                        fill: $jungle-green2;
                        color: $jungle-green2;
                    }
                }
            }
        }

        &:nth-child(3) {
            .ant-tabs-tab-btn {
                @include mq($xl) {
                    // width: 21px;
                    overflow: hidden;
                }

                @include mq($md) {
                    width: auto;
                    overflow: visible;
                }
            }
        }

        &:nth-child(4) {
            .ant-tabs-tab-btn {
                @include mq($xl) {
                    width: 38px;
                    overflow: hidden;
                }

                @include mq($md) {
                    width: auto;
                    overflow: visible;
                }
            }
        }
    }

    .ant-tabs-tab-active {
        background-color: $jungle-green2;
        .ant-tabs-tab-btn {
            color: $white !important;
            text-shadow: none;
        }

        .property-card__tab-with-icon {
            svg {
                fill: $white;
                color: $white;
            }
        }
    }

    .ant-tabs-tab-btn {
        font-family: $font-primary;
        font-size: 10px;
        line-height: 12px;
        color: $boulder2;
        font-weight: 500;

        @include mq($xl) {
            font-size: 10px;
        }
    }

    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0;
    }

    .ant-tabs-nav {
        &::before {
            display: none;
        }
    }

    .ant-tabs-content-holder {
        position: relative;
        overflow: auto;
        overflow-x: hidden;
        // padding-bottom: 20px;
        cursor: auto;
        background-color: $alabaster-second;
        @include scroll-style($boulder, $alabaster, 0, 0.36vw, 0);
    }

    // content
    .ant-tabs-content {
        background-color: $alabaster-second;
        padding: 0;
        height: 270px;

        @include mq($xl) {
            padding: 0;
        }

        @include mq($lg) {
            padding: 0;
        }
    }

    .ant-tabs-nav-operations {
        display: none !important;

        .ant-tabs-nav-more {
            padding: 8px 5px;
        }
    }
    &--listing {
        .ant-tabs-content-holder {
            height: 261px;

            @include mq($xl) {
                min-height: 171px;
            }
        }
    }
    &--sale,
    &--finance {
        .ant-tabs-content-holder {
            height: 261px;
        }
    }
    &.sale-disabled .ant-tabs-tab:nth-child(3),
    &.finance-disabled .ant-tabs-tab:nth-child(4) {
        .ant-tabs-tab-btn {
            color: $gray !important;
        }
        &.ant-tabs-tab-active {
            background-color: $clear-cloud;
        }
    }
}

.property-card__history-unavailable {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: $boulder2;
    display: block;
    text-align: center;
    margin-top: 62px;
    font-family: $font-primary;

    &.finance-history {
        &::before {
            content: "";
            display: block;
            max-width: 300px;
            width: 100%;
            height: 1px;
            background-color: $alto;
            position: absolute;
            top: 20px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
        }
    }
}

// card details

.property-card__group-divider {
    width: 294px;
    height: 1px;
    background: $clear-cloud;
    margin-bottom: 4px;
}

.property-card__ownership-header {
    font-family: $font-primary;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    color: $blue-whale;
    padding-bottom: 3px;
}

.property-card__owner-info-icon {
    margin-left: 4px;
    color: $gray;

    &.info {
        right: 16px;
        text-align: center;
        svg {
            color: $gray;
            cursor: pointer;
        }

        div {
            position: absolute;
            top: 44px;
            left: 89.5px;
            padding: 4px 8px;
            width: 239px;
            height: 56px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            background-color: $blue-whale;
            color: $white;
            visibility: hidden;
            opacity: 0;
            transition: 0.2s ease;
            font-family: $font-primary;
            &::before {
                content: "";
                position: absolute;
                width: 10px;
                height: 0;
                border-width: 0 7px 11px 7px;
                border-color: transparent transparent $blue-whale transparent;
                border-style: solid;
                top: -9px;
                right: 218px;
            }
        }
    }

    &:hover div {
        opacity: 1;
        visibility: visible;
    }
}

.property-card__owner-title {
    text-align: left;
    padding-bottom: 16px;
}

.property-card__grid-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 4px 0px;
}

.property-card__group-owner-item {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: $blue-whale;
}

.property-card__group-item {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    flex-wrap: wrap;
    &.price {
        z-index: 1;
        min-height: 82px;
    }
    &.price-cta {
        min-height: 32px;
        padding: 9px;
        span {
            font-weight: 700;
            font-size: 10px;
            line-height: 14px;
            cursor: pointer;
            color: $jungle-green2;
            @include hover {
                text-decoration: underline;
            }
        }
    }
    &.agent {
        align-items: flex-start;
    }

    &.open {
        .property-card__group-icon {
            position: relative;
            top: 0;
        }
    }

    @include mq($xl) {
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: 12px;
    }

    @include mq($lg) {
        margin-bottom: 10px;
        align-items: center;
    }
}

.property-card__group-item {
    margin-right: 36px;

    @include mq($xl) {
        margin-right: 25px;
    }

    @include mq($lg) {
        margin-right: 20px;
    }

    &:last-of-type {
        margin-right: 0;
    }
}

.property-card__group-item-info {
    font-size: 14px;
    line-height: 14px;
    font-family: $font-primary;
    color: $blue-whale;
    font-weight: 500;

    &.bell {
        display: flex;
    }

    @include mq($xl) {
        font-size: 14px;
        line-height: 12px;
    }

    @include mq($lg) {
        font-size: 12px;
    }
}

.property-card__group-item-content {
    color: $blue-whale;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
}

.property-card__disclosure {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #a2acba;
}

.property-card__group-item-details {
    display: flex;
    margin-top: 3px;

    &:empty {
        margin-top: 0;
    }

    @include mq($xl) {
        margin-top: 5px;
    }

    p {
        font-size: 10px;
        line-height: 10px;
        font-family: $font-primary;
        color: $boulder;
        font-weight: 500;

        &:not(:last-of-type) {
            margin-right: 8px;
        }
    }
}

.property-card__group-icon {
    display: inline-block;
    svg {
        width: 16px;
        height: 18px;
        fill: $boulder;
        color: $boulder;
        margin-right: 8px;

        @include mq($xl) {
            &.fa-tag {
                height: 14px;
            }
        }

        @include mq($lg) {
            height: 12px;
            margin-right: 6px;
        }
    }

    &.price {
        display: flex;
        align-items: center;
        svg {
            width: 14px;
            height: 16px;
            color: $blue-whale;
        }
    }
}

.property-card__bell {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 10px;
    width: 24px;
    height: 24px;
    border: 1px solid $clear-cloud;
    border-radius: 4px;
    transition: 0.2s ease;

    svg {
        width: 11px;
        color: $boulder2;
        transition: 0.2s ease;
    }
    div {
        position: absolute;
        top: 36px;
        left: -27px;
        padding: 4px 8px;
        width: 71px;
        height: 24px;
        font-size: 12px;
        line-height: 16px;
        background-color: $blue-whale;
        font-weight: 300;
        color: $white;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        transition: 0.15s ease;
        font-weight: 400;
        font-family: $font-primary;
        &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-width: 0 7px 11px 7px;
            border-color: transparent transparent $blue-whale transparent;
            border-style: solid;
            top: -9px;
            left: 32px;
        }
    }
    &:hover {
        background-color: $jungle-green2;
        border-color: $jungle-green2;
        svg {
            color: $white;
        }
        div {
            opacity: 1;
            visibility: visible;
        }
    }

    @include mq($xl) {
        right: 6px;
    }
}

.property-card__tab-with-icon {
    display: flex;
    align-items: center;

    svg {
        margin-right: 4px;
        fill: $boulder2;
        color: $boulder2;
        height: 12px;
        transition: $dur $ease;
    }
}

.property-card__group-user-details-name {
    color: $blue-whale;
    font-family: $font-primary;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
    font-weight: 500;
}

.property-card__list-link {
    transition: $dur $ease;

    @include hover {
        color: $jungle-green2;
    }
}

.property-card__list--margin {
    margin-left: 31px;
    width: 100%;
    margin-bottom: -24px;
}

// Sale history list
.property-card__group-list {
    margin-bottom: 24px;

    &:last-of-type {
        margin-bottom: 0;
    }

    @include mq($xs-phone) {
        margin-bottom: 15px;
    }
}

.property-card__group-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    @include mq($xl) {
        margin-bottom: 7px;
    }

    @include mq($lg) {
        margin-bottom: 8px;
    }
}

.property-card__group-list-item-title {
    color: $boulder;
    font-size: 12px;
    line-height: 16px;
    font-family: $font-primary;
    min-width: 94px;
    font-weight: 500;

    @include mq($xl) {
        font-size: 10px;
        line-height: 14px;
    }
}

.property-card__group-list-item-info {
    color: $blue-whale;
    font-size: 12px;
    line-height: 16px;
    font-family: $font-primary;
    font-weight: 500;

    @include mq($xl) {
        font-size: 10px;
        line-height: 14px;
    }
}

.property-card__list-wrap {
    display: flex;
    width: 100%;
}

.property-card__list-box {
    max-width: 65%;
    width: 100%;

    @include mq($lg) {
        max-width: 100%;
    }
}

.property-card__list-box-sm {
    max-width: 35%;
    width: 100%;
}

.property-card__list-box-title {
    font-family: $font-primary;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: $boulder;
    display: block;
    margin-bottom: 4px;
    text-transform: uppercase;

    @include mq($xl) {
        font-size: 12px;
        line-height: 12px;
    }

    @include mq($lg) {
        margin-top: 6px;
        font-size: 10px;
        line-height: 12px;
    }
}

.property-card__disclosure-placement {
    position: absolute;
    bottom: 8px;
    left: 24px;
}

.property-card__disclosure {
    position: absolute;
    bottom: 7px;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    @include mq($xl) {
        position: relative;
        bottom: auto;
        padding-bottom: 10px;
        padding-right: 28px;
    }
}

.property-card__disclosure-first-line {
    color: $boulder2;
    margin-bottom: 3px;
}

.property-card__disclosure-second-line {
    color: $jungle-green2;
    padding-left: 3px;

    &::before {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 6px;
        width: 0;
        opacity: 0;
        height: 1px;
        transition: $dur $ease;
        background-color: $jungle-green;
    }

    @include hover {
        color: $jungle-green2;
        &::before {
            width: calc(100% - 6px);
            opacity: 1;
        }
    }
}

.property-card__login-wrapper {
    padding: 23px 31px 0;

    @include mq(440px) {
        padding: 23px 0 0;
    }
}

.property-card__login-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: $blue-whale;
    margin-bottom: 8px;
}

.property-card__login-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: $boulder2;
    margin-bottom: 33px;
}
.property-card__login-text-btn {
    padding: 0;
    border: none;
    background-color: transparent;
    color: #22aa94;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-decoration-line: underline;
    transition: 0.2s ease;

    &:hover {
        color: #757575;
    }
}
