.calculator-slideout {
    position: fixed;
    top: 70px;
    left: -100%;
    height: calc(100% - 70px);
    max-width: 762px;
    width: 100%;
    background-color: $white;
    z-index: 1000;
    transition: left $dur $ease;
    @media screen and (min-width: 768px) {
        z-index: 12;
    }
}

.calculator-slideout--open {
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.4);
    left: 0;
}

.calculator-slideout__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 56px;
    background: #eff7f9;
    padding: 0 16px;
}

.calculator-slideout__header__title {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    svg {
        margin-right: 18px;
        height: 24px;
    }
}

.calculator-slideout__header__title-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    color: $blue-whale;
}

.calculator-slideout__iframe {
    height: calc(100% - 56px);

    .calculator-slideout__iframe-element {
        border: none;
        &.hide {
            scale: 0;
            pointer-events: none;
        }
    }
}

.calculator-slideout__overlay-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: scale $dur $ease;
    padding: 162px 15px 0;
    position: absolute;
    width: 100%;
    height: calc(100% - 56px);
    background: rgba($white, 0.87);
    top: 56px;
    &.hide {
        scale: 0;
        pointer-events: none;
    }

    img {
        max-width: 302px;

        @include mq(500px) {
            max-width: 200px;
        }
    }

    @include mq(640px) {
        padding: 0 15px 0;
        justify-content: center;
        overflow: scroll;
    }
}

.calculator-slideout__overlay-btn {
    width: 200px;
    height: 58px;
    background: #03363d;
    border-radius: 6px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: $white;
    margin-top: 32px;

    &:hover {
        background-color: $jungle-green2;
        color: $white;
        border: $jungle-green2;
    }

    @include mq(500px) {
        width: 180px;
        height: 50px;
        font-size: 14px;
    }
}

.calculator-slideout__overlay-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: $blue-whale;
    margin-top: 24px;
    text-align: center;

    @include mq(500px) {
        font-size: 16px;
    }
}

.calculator-slideout__overlay-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $boulder2;
    margin-top: 8px;
    text-align: center;

    @include mq(500px) {
        font-size: 14px;
    }
    span {
        color: #22aa94;
        text-decoration: underline;
    }
}

.calculator-slideout__overlay-divider {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $boulder2;
    margin-top: 28px;
    position: relative;
    max-width: 363px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 0;
    &:before {
        content: "";
        position: absolute;
        top: 44%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $boulder2;
        z-index: -1;
    }

    span {
        background-color: white;
        display: block;
        padding: 0 7px;
        position: relative;
        z-index: 0;
    }
}
