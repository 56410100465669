.property-card {
    background-color: $white;
    max-width: 342px;
    min-width: 342px;
    width: 100%;
    position: absolute;
    box-shadow:
        0px 0px 24px rgba(0, 0, 0, 0.22),
        0px 24px 24px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    z-index: 1;

    &.hidden {
        display: none;
    }

    .mobile {
        display: none;
    }

    &.desktop {
        &.right {
            right: auto;
            left: 66px;

            &::after {
                content: "";
                left: -33px;
                width: 0;
                height: 0;
                border-top: 12px solid transparent;
                border-right: 34px solid $white;
                border-bottom: 12px solid transparent;
                z-index: 0;
            }
        }

        &.left {
            left: auto;
            right: 35px;

            &::after {
                content: "";
                width: 0;
                height: 0;
                right: -33px;
                border-top: 12px solid transparent;
                border-left: 34px solid $white;
                border-bottom: 12px solid transparent;
                z-index: 0;
            }
        }

        &.center {
            top: calc(50% - 15px);
            transform: translateY(-50%);

            &::after {
                position: absolute;
                top: 54%;
                transform: translateY(-50%);
            }
        }

        &.top {
            top: auto;
            transform: none;
            bottom: -38px;

            &::after {
                position: absolute;
                bottom: 19px;
                top: auto;
                transform: translateY(0);
            }
        }

        &.bottom {
            top: -9px;
            transform: none;

            &::after {
                position: absolute;
                top: 19px;
            }
        }

        &.comparables.right {
            left: 60px;
        }

        &.comparables.bottom {
            top: -9px;
        }

        &.comparables.center:not(.top):not(.bottom) {
            transform: translateY(-49%);
        }

        &.comparables.center.top {
            bottom: -41px;
        }
    }

    &.no-property {
        left: 100px;

        @include mq(767px) {
            left: 50%;
        }

        @include mq(400px) {
            left: 0;
        }

        .property-card__heading {
            padding: 7px 55px 10px 17px;
            min-height: 56px;
        }

        .property-card__type {
            display: none;
        }

        .property-card__image {
            height: 132px;
        }

        .property-card__image-placeholder {
            width: 60px;

            svg {
                height: 60px;
            }
        }

        .property-card__buttons {
            .property-card__listing-credits {
                max-width: 48%;
                width: 100%;
                font-size: 12px;
                letter-spacing: 0.15px;
                text-align: center;
                padding-left: 8px;
                margin: 0;
            }

            .property-card__link {
               max-width: 48%;
                border-radius: 6px;
            }

            .property-card__free {
                border-radius: 6px;
            }
        }
    }

    .ant-tabs-nav-operations {
        @include mq($xl) {
            display: none !important;
        }
    }

    @media screen and (max-width: 767px) {
        &.mobile {
            display: block;
            max-height: calc(100vh - 70px);
            overflow-y: auto;
        }
        &.desktop {
            display: none;
        }
    }
}

.property-card__tab {
    position: relative;
    padding: 20px 15px 40px;
    max-height: 220px;
    &--finance,
    &.short-tab {
        //height: 179px;
    }
}

.property-card__top {
    position: relative;
}

.property-card__main {
    .listings-tab .membership-cta {
        height: 45px;
        margin: 0 0 4px;
        align-items: flex-start;
        min-height: 0;
        padding: 5px 0 3px;

        @include mq($xl) {
            height: auto;
            padding: 5px 0 6px;
        }

        @include mq($lg) {
            padding: 7px 0 8px;
        }
    }

    .listings-tab {
        background-color: #fafafa;
        height: auto;
        .membership-cta__icon {
            position: relative;
            top: 9px;
            left: 8px;
            margin-right: 8px;
        }
        .membership-cta__link {
            margin-left: 23px;

            @include mq($lg) {
                line-height: 1.2;
            }
        }

        // @include mq($xl) {
        //     height: 171px;
        // }
    }

    &.no-property {
        padding: 0 0 16px;
    }

    &.free-user {
        .property-card__tabs .ant-tabs-content {
            padding: 0;
            height: 160px!important;
        }
    }

    .property-card__tabs--listing {
        .listings-tab {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .property-card__listing-credits {
                margin: 0;
            }
        }
    }
}

.listings-tab--no-listing.has-vendors {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 20px 24px 10px;
    background-color: rgba(34, 170, 148, 0.1);
    @include scroll-style($boulder, $alabaster, 0, 0.4vw);
    .property-card__listing-credits {
        display: block;
        text-align: center;
        margin-bottom: 10px !important;
        span {
            right: 30px;
            &::before {
                right: calc(50% + -10px);
            }
        }
    }
    .property-card__listing-tooltip {
        top: 28px;
        &::before {
            transform: translateX(-50%) rotate(180deg);
            bottom: unset;
            top: -7px;
        }
    }
}

.property-card__image {
    height: 132px;
    position: relative;

    @include mq($xl) {
        height: 132px;
    }

    &.no-img {
        &::before {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgb(232, 243, 245, 80%);
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom;
    }
}

.property-card__image-placeholder {
    position: absolute;
    width: 100px;
    height: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    svg {
        height: 80px;
        width: 100px;
        fill: rgba(0, 0, 0, 0.55);
        color: rgba(0, 0, 0, 0.55);
    }
}

.property-card__icon-opacity {
    opacity: 96%;
}

.property-card__icon-opacity:hover {
    opacity: 100%;
    cursor: pointer;
}

.property-card__buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 7px 2px 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    &.pipeline-overlimit {
        justify-content: center;
    }

    @include mq($xl) {
        align-items: inherit;
        padding: 7px 7px 8px;
    }

    @include mq($lg) {
        padding: 7px 12px 8px;
    }
}

.property-card__link, .property-card__free {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: $font-primary;
    line-height: 16px;
    background-color: $white;
    outline: none;
    border: none;
    font-weight: 700;
    max-width: 160px;
    padding: 13px 10px;
    background-color: $blue-whale;
    color: $white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: $dur $ease;
    color: $jungle-green;
    background-color: $white;
    position: relative;

    @include mq($xl) {
        font-size: 12px;
        line-height: 16px;
        padding: 12px 18px;
    }

    @include mq($lg) {
        font-size: 10px;
        line-height: 13px;
        padding: 8px 18px;
    }

    @include hover {
        background-color: $white;
    }
}

.one-button {
    .property-card__link {
        max-width: none;
        background-color: $blue-whale;
        color: $white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        @include hover {
            background-color: $jungle-green;
        }
    }
}

.two-buttons {
    border-top: 1px solid $clear-cloud;
    .property-card__link {
        border-radius: 8px;

        span {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                width: 0;
                opacity: 0;
                height: 1px;
                left: 0;
                transition: $dur $ease;
                background-color: $jungle-green;
            }
        }

        &:nth-child(2) {

            @include mq($xl) {
                font-size: 12px;
                font-weight: 700;
                line-height: 16px;
                padding: 13px 10px;
                max-width: 55%;
            }

            @include mq($lg) {
                max-width: 51%;
                padding: 13px 2px;
            }

        }

        @include hover {
            span {
                &::before {
                    width: 100%;
                    opacity: 1;
                }
            }
        }

        @include mq($xl) {
            max-width: 42%;
            padding: 0;
        }

        @include mq($lg) {
            font-size: 12px;
        }
    }

}
.pipeline-overlimit {
    .property-card__link, .property-card__free {
        background-color: $blue-whale;
        color: $white;
        border-radius: 8px;
        max-width: 100%;
        font-size: 14px;
        background-color: #03363d;
        margin: 0 10px;

        @include hover {
            background-color: $jungle-green;
        }
    }
    .property-card_main {
        width: 342px;
        background: rgba(255, 255, 255, 0.79);
        padding: 15px;
        div {
            padding: 3% 0;
        }
        .icon-section {
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                width: 50px;
                height: 50px;
                border-radius: 25px;
                border: solid 1px $jungle-green2;
                background-color: #e8f6f4;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                transform: rotate(315deg);
                svg {
                    width: 30px;
                    height: 30px;
                    color: $jungle-green2;
                    fill: $jungle-green2;
                }
            }
        }
        .header-section {
            display: flex;
            align-items: center;
            justify-content: center;
            h2 {
                font-family: "ABCMonumentGrotesk", Arial, Helvetica, sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #03363d;
            }
        }
        .membership-section {
            display: grid;
            align-items: center;
            justify-content: center;
            color: #757575;
        }
        .unlimited-membership-section {
            color: #757575;
        }
        .header {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
        }
        .list-item {
            text-indent: -10px;
            padding-left: 15px;
            padding-bottom: 5px;
            font-size: 12px;
        }
        .list-item::before {
            content: "• ";
            margin-right: 3px;
        }
        .learn-more {
            text-indent: 15px;
            a {
                text-decoration: underline;
                color: $jungle-green2;
            }
        }
    }
}

.property-card__close {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    cursor: pointer;
    z-index: 1;
    transition: $dur $ease;
    border-radius: 100%;

    @include mq($xl) {
        height: 24px;
        width: 24px;
    }

    svg {
        height: 16px;
        color: $boulder2;
        transition: $dur $ease;
    }

    @include hover {
        background-color: rgba(0, 0, 0, 0.32);
        svg {
            color: $white;
        }
    }
}

.property-card__heading {
    position: relative;
    padding: 8px 48px 8px 16px;
    min-height: 56px;
    display: flex;
    align-items: center;
    .property-card__address {
        display: flex;
    }
    .property-card__address-icon {
        display: flex;
        align-items: center;
        margin-right: 6px;

        @include mq($xl) {
            display: none;
        }
    }
    .property-card__address-icon svg {
        width: 12px;
        height: 16px;
        color: $blue-whale;
    }
    .property-card__type {
        margin-left: -3px;
        padding: 0;
        span,
        svg {
            color: $boulder2;
        }
    }

    @include mq($xl) {
        padding: 7px 55px 10px 18px;
    }

    // @include mq($lg) {
    //     padding: 8px 20px 10px 4px;
    // }
}

.property-card__type {
    position: absolute;
    top: 12px;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    background: rgba($white, 0.94);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    svg {
        width: 15px;
        height: 12px;
        fill: $blue-whale;
        color: $blue-whale;
        margin-right: 4px;
    }

    span {
        font-family: $font-primary;
        color: $blue-whale;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        cursor: text;
        text-transform: capitalize;

        @include mq($xl) {
            font-size: 10px;
            line-height: 10px;
        }
    }
}

.property-card__top-icon {
    position: absolute;
    height: 24px;
    width: 24px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 12px;
    right: 16px;
    border-radius: 8px;
    // z-index: 1;

    // @include mq($xl) {
    //     width: 24px;
    //     height: 24px;
    // }

    // @include mq($xl) {
    //     right: 8px;
    //     bottom: 6px;
    // }

    // @include mq($lg) {
    //     bottom: 12px;
    // }

    svg {
        width: 14px;
        height: 14px;
        fill: $blue-whale;
        color: $blue-whale;
    }

    &.street-view + div {
        right: 54px;

        // @include mq($xl) {
        //     right: 45px;
        // }
    }

    &.favorite-property {
        bottom: unset;
        top: 68px;
        right: 47px;
        svg {
            transition: all 0.2 ease;
        }
        &:hover {
            svg {
                color: $jungle-green2;
            }
            span {
                visibility: visible;
                opacity: 1;
            }
        }
        &.favorited {
            box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.25);
            svg {
                color: $jungle-green2;
                fill: $jungle-green2;
            }
        }
    }

    &.lp {
        bottom: unset;
        top: 68px;
        color: $blue-whale;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        svg {
            transition: all 0.2 ease;
        }

        .favorite-property__tooltip {
            left: auto;
            right: 0;
            top: 33px;
            width: 237px;

            &::after {
                right: 7px;
                top: -7px;
                transform: rotateZ(151deg);
            }
        }
        &:hover {
            svg {
                color: $jungle-green2;
            }
            span {
                visibility: visible;
                opacity: 1;
            }
        }
        &.active {
            box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.25);
            color: $jungle-green2;
        }
    }

    &.has-portfolio {
        color: $jungle-green2;
    }

    &.calculator-property {
        bottom: unset;
        top: 68px;
        right: 107px;

        &.calc-open svg {
            fill: #22aa94;
            color: #22aa94;
        }

        &:hover .favorite-property__tooltip {
            visibility: visible;
            opacity: 1;
        }
    }

    &.alerts {
        bottom: unset;
        top: 68px;
        right: 77px;
        &.alerts--on svg {
            fill: #22aa94;
            color: #22aa94;
        }
    }

    &.loading svg {
        fill: #22aa94;
        color: #22aa94;
        animation: spin 4s infinite linear;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    &.open-page {
        bottom: 96px;
        svg {
            width: 11px;
            height: 12px;
        }
    }

    &.flag {
        right: 16px;
        text-align: center;
        svg {
            fill: $jungle-green2;
            color: $jungle-green2;
        }
        div {
            position: absolute;
            top: 41px;
            left: -37px;
            padding: 4px 8px;
            width: 109px;
            height: 40px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            background-color: $blue-whale;
            color: $white;
            z-index: 1;
            visibility: hidden;
            opacity: 0;
            transition: 0.2s ease;
            font-family: $font-primary;
            &::before {
                content: "";
                position: absolute;
                width: 10px;
                height: 0;
                border-width: 0 7px 11px 7px;
                border-color: transparent transparent $blue-whale transparent;
                border-style: solid;
                top: -9px;
                right: 49.5px;
            }
        }
    }

    &:hover div {
        opacity: 1;
        visibility: visible;
    }
}

.property-card__list-item {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    @include mq($xs-phone) {
        margin-bottom: 15px;
    }

    &.property-card__list-item--address {
        max-width: 65%;
        //TODO: 17px !important
        margin-bottom: 16px;

        @include mq($xl) {
            max-width: 80%;
        }

        @include mq($xs-phone) {
            margin-bottom: 15px;
            max-width: 100%;
        }
    }

    &:last-of-type {
        margin-bottom: 16px;

        @include mq($xl) {
            margin-bottom: 12px;
        }

        @include mq($lg) {
            margin-bottom: 10px;
        }
    }

    &.property-card__no-data {
        display: block;
        margin-bottom: 0;
    }
}

.property-card__address-icon {
    margin-right: 12px;

    @include mq($xl) {
        margin-right: 12px;
    }

    svg {
        height: 18px;
        fill: $boulder;
        color: $boulder;
        display: inline-block;

        @include mq($xl) {
            height: 16px;
        }
    }
}

.property-card__address-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    font-family: $font-primary;
    color: $blue-whale;
    cursor: text;
    padding-top: 2px;
    white-space: pre-line;

    @include mq($xl) {
        font-size: 14px;
        line-height: 18px;
    }

    @include mq($lg) {
        font-size: 12px;
        line-height: 15px;
    }
}

.property-card__list {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    width: 100%;
}

.property-card__list-content {
    color: $boulder;
    font-family: $font-primary;
    font-size: 10px;
    line-height: 1.3;
    font-weight: 500;

    @include mq($xl) {
        font-size: 8px;
        line-height: 8px;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &--name {
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        color: #757575;
    }
}

.property-card__group--two {
    display: flex;
    align-items: center;

    &.smaller-icons {
        svg {
            width: 16px;
        }
    }
}

.property-card__group-item-link {
    color: $jungle-green;
    font-family: $font-primary;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    transition: $dur $ease;
    display: block;
    cursor: pointer;
    width: fit-content;
    position: relative;
    margin-top: 4px;

    @include mq($xl) {
        font-size: 12px;
        line-height: 10px;
        margin-top: 7px;
    }

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 0;
        opacity: 0;
        height: 1px;
        left: 0;
        transition: $dur $ease;
        background-color: $jungle-green;
    }

    @include hover {
        &::before {
            width: 100%;
            opacity: 1;
        }
    }
}

.no-property-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    border-radius: 100%;
    background-color: $blue-whale;

    svg {
        width: 22px;
        fill: $white;
    }
}

.property-card__loading-map {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.property-card__tabs {
    .membership-cta__top {
        flex-direction: row;
        margin-bottom: 13px;

        @include mq($lg) {
            margin-bottom: 6px;
        }
    }

    .membership-cta__icon {
        margin-bottom: 0;
        margin-right: 5px;

        @include mq($lg) {
            margin-right: 3px;
        }
    }

    .membership-cta {
        justify-content: flex-start;
        padding-top: 21px;
        margin-top: 23px;

        @include mq($xl) {
            padding-top: 41px;
        }

        @include mq($lg) {
            padding-top: 35px;
        }
    }
}

.property-card__listing-tooltip {
    font-family: $font-primary;
    display: block;
    padding: 14px 5px 14px 14px;
    z-index: 2;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease;
    background-color: $blue-whale;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 256px;
    color: $white;
    top: -92px;
    left: 45px;
    span {
        display: block;
        &:last-child {
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            margin-top: 8px;
        }
    }
    &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: -6px;
        width: 0;
        height: 0;
        border-width: 10px 7px 0 7px;
        border-color: $blue-whale transparent transparent transparent;
        border-style: solid;
        left: 50%;
        transform: translateX(-50%);
    }
}

.property-card__listing {
    position: relative;
    padding: 20px 24px 8px;
    background-color: rgba($jungle-green2, 0.1);
    width: 100%;
    height: 100%;
    overflow-y: auto;
    @include scroll-style($boulder, $alabaster, 0, 0.4vw);

    .property-card__group-item {
        background-color: $white;
        margin-right: 0;
        margin-bottom: 8px;
        padding: 8px 8px 9px;
        border-radius: 4px;
        min-height: 59px;
        align-items: flex-start;
    }

    @include mq($xl) {
        padding: 20px 13px 16px 24px;
    }

    @include mq($lg) {
        padding: 20px 8px 16px;
    }
}

.property-card__group-image {
    position: absolute;
    top: -10px;
    right: -12px;
}

.property-card__listing-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    h3 {
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        color: $blue-whale;
        display: flex;
        align-items: center;
        svg {
            margin-right: 4px;
            width: 18px;
            height: 14px;
        }

        @include mq($xl) {
            letter-spacing: 0.15px;
        }

        @include mq($lg) {
            font-size: 12px;
        }
    }

    &.service-providers {
        h3 {
            position: relative;
            height: 20px;
            margin-top: 4px;
            span {
                flex-shrink: 0;
            }
            > svg {
                width: 13px;
                height: 14px;
            }
        }
        .property-card__listing-tooltip-icon {
            margin-left: 4px;
            color: $gray;
            width: 14px;
            height: 14px;
            &:hover {
                & + .property-card__listing-tooltip {
                    visibility: visible;
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
    }

    @include mq($lg) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 6px;
    }
}

.property-card__service-providers-wrap {
    background-color: $white;
    border-radius: 4px;
    margin-bottom: 3px;
    overflow: hidden;
}

.property-card__service-provider {
    position: relative;
    padding: 8px;
    display: flex;
    flex-direction: column;
    background-color: $white;
    h6 {
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        color: $blue-whale;
        margin-bottom: 2px;
    }
    span {
        position: absolute;
        top: 8px;
        right: 8px;
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        color: $boulder2;
    }
    a {
        margin-bottom: 1px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &:nth-child(2n) {
        background-color: #f7f7f7;
    }
}

.property-card__listing-credits {
    position: relative;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    text-align: right;
    color: $jungle-green2;
    margin-left: auto;
    cursor: pointer;
    backface-visibility: hidden;
    z-index: 2;
    font-family: $font-primary;
    text-align: center;
    display: block;
    span {
        position: absolute;
        top: 24px;
        right: 0;
        padding: 4px 0;
        width: 225px;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        transition: $dur $ease;
        pointer-events: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: $white;
        background-color: $blue-whale;
        &::before {
            content: "";
            position: absolute;
            height: 0;
            border-width: 0 6px 7px 6px;
            border-color: transparent transparent $blue-whale transparent;
            border-style: solid;
            right: 6px;
            top: -6px;
        }

        @include mq($lg) {
            left: 0;
            width: 100%;
            font-size: 10px;
            line-height: 1.5;
            padding: 3px 4px;
            text-align: left;
        }
    }
    &:hover {
        span {
            visibility: visible;
            opacity: 1;
            pointer-events: all;
        }
    }
    &.public-data {
        span {
            width: 270px;

            @include mq($xl) {
                width: 110px;
                left: auto;
                right: 0;
                font-size: 10px;
                line-height: 1.4;
                top: 34px;
                padding: 3px 7px;
                text-align: left;
            }

            @include mq($lg) {
                top: 44px;
            }

            @include mq($md) {
                top: 24px;
            }
        }
    }
}

//Public data
.property-card__public-data {
    padding-bottom: 15px;
    h3 {
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 12px;
        font-weight: 700;
        color: $blue-whale;
        svg {
            margin-left: 0;
        }
        span {
            left: -7px;
            &::before {
                left: 8px;
            }
        }
    }
    .property-card__disclosure {
        display: inline-block;
        position: relative;
        bottom: 0;
        left: 0;
    }
}

.property-card__public-hover {
    display: inline-block;
    position: relative;
    z-index: 3;
    svg {
        color: $gray;
        margin-left: 4px;
        cursor: pointer;
    }
    span {
        position: absolute;
        left: -3px;
        top: 22px;
        background-color: $blue-whale;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: $white;
        width: 226px;
        padding: 4px 0;
        visibility: hidden;
        opacity: 0;
        transition: $dur $ease;
        pointer-events: none;
        z-index: 1;

        &::before {
            content: "";
            position: absolute;
            border-width: 0 6px 7px 6px;
            border-color: transparent transparent $blue-whale transparent;
            border-style: solid;
            left: 6px;
            top: -6px;

            @include mq($lg) {
                left: 54px;
            }
        }

        @include mq($xl) {
            width: 110px;
            font-size: 10px;
            text-align: left;
            padding: 3px 7px;
            line-height: 1.4;
        }

        @include mq($lg) {
            left: -50px;
        }
    }
    &:hover {
        span {
            visibility: visible;
            opacity: 1;
            pointer-events: all;
        }
    }
    &.center {
        span {
            left: -65px;
            &::before {
                left: 68px;
            }
        }
    }
}

.property-card__public-group {
    margin-bottom: 12px;
    h4 {
        font-weight: 700;
        color: $blue-whale;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;
        padding-bottom: 3px;
        margin-bottom: 4px;
        border-bottom: 1px solid $clear-cloud;
    }
    & + .property-card__public-data {
        margin-top: 20px;
    }
}

.property-card__public-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4px;
}

.property-card__public-name,
.property-card__public-value {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    max-width: 50%;
}

.property-card__public-name {
    color: $boulder2;
    flex-shrink: 0;
    padding-right: 5px;
}

.property-card__public-value {
    text-align: right;
    color: $blue-whale;
}

.property-card__public-cre {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    text-align: right;
    color: $jungle-green2;
    margin-left: auto;
    cursor: pointer;
}

.property-card__no-data-title {
    display: block;
    font-size: 16px;
    font-family: $font-primary;
    font-weight: 500;
    line-height: 24px;
    color: $boulder2;
    text-align: center;
    width: 100%;
    padding: 12px 24px 0;
    background-color: #fafafa;
}

.property-card__no-data-property {
    display: block;
    font-size: 16px;
    font-family: $font-primary;
    font-weight: 700;
    line-height: 24px;
    color: $blue-whale;
    text-align: center;
    width: 100%;
    padding: 0 40px 10px;
    background-color: #fafafa;
}

.property-card__no-data-info {
    display: block;
    font-size: 14px;
    font-family: $font-primary;
    font-weight: 400;
    line-height: 21px;
    color: $blue-whale;
    text-align: center;
    width: 100%;
    padding: 0 40px;
    margin-top: 10px;
}

.favorite-property__tooltip {
    position: absolute;
    left: -85px;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease;
    background-color: $blue-whale;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 8px 14px;
    color: $white;
    z-index: 3;
    &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 0 6px 9px;
        border-color: transparent transparent transparent $blue-whale;
        right: -9px;
        top: 10px;
    }
}

.property-card__service-providers-popup-trigger {
    border: none;
    background-color: transparent;
    padding: 0;
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: $jungle-green2;
    max-width: 237px;
    width: 100%;
}

.favorite-property__zip-alert-tooltip {
    width: 312px;
    height: 166px;
    display: block;
    background: $blue-whale;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 14px 15px;
    position: absolute;
    z-index: 10;
    top: 99px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;

    @include mq(767px) {
        display: none;
    }
    .favorite-property__zip-alert-title {
        color: $white;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 6px;
    }

    .favorite-property__zip-alert-text {
        margin-bottom: 2px;
    }

    .favorite-property__zip-alert-text,
    .favorite-property__zip-alert-li {
        color: $white;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    .favorite-property__zip-alert-ul {
        padding-left: 23px;
    }

    .favorite-property__zip-alert-li {
        list-style-type: disc;
    }
}

.alerts:hover + .favorite-property__zip-alert-tooltip {
    opacity: 1;
    pointer-events: all;
}
.icon-disabled {
    color: #bfbfbf !important;
    cursor: not-allowed !important;
    svg {
        color: #bfbfbf !important;
    }
}
