/*--------------------------------------------------------------
## Footer Component Style
--------------------------------------------------------------*/
.footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    text-align: center;
    padding: 20px 0;
    font-size: 20px;
    color: $orange;
}
