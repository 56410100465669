.datepicker {
    width: 148px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid #c9cccf;
    padding: 4px 6px 4px;

    input {
        color: #757575;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
    }

    .ant-picker-suffix {
        order: -1;
        margin-right: 6px;
        color: $black;
        font-size: 17px;

        .anticon svg {
            font-size: 17px;
        }
    }

    @include mq(401px) {
        margin-right: 0;
        width: 100%;
    }
}

.datepicker-label {
    color: $blue-whale;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 3px;
    display: block;
}
