/*--------------------------------------------------------------
## Basic/Common Typography Style
--------------------------------------------------------------*/

body,
button,
input,
select,
optgroup,
textarea {
    font-family: $font-primary;
    color: $black;
    font-size: 16px;
    line-height: 1;
    font-feature-settings: normal;
    font-variant: normal;
}

* {
    font-feature-settings: normal;
    font-variant: normal;
}

h1 {
    font-family: $font-primary;
    font-size: 70px;
}

h2 {
    font-family: $font-secondary;
    font-size: 40px;
}
