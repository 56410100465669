.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it's on top of other content */
}
.spinner {
  position: relative;
}
  
  .spinner-image {
    width: 80%; /* Adjust the size as needed */
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
  