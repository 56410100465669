.property-card__loader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 494px;
    border-radius: 8px;
    overflow: hidden;

    span {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            background-image: linear-gradient(
                to right,
                rgba(#000, 0.03) 0%,
                rgba(#000, 0.045) 10%,
                rgba(#000, 0.065) 20%,
                rgba(#000, 0.07) 40%,
                rgba(#000, 0.065) 70%,
                rgba(#000, 0.045) 80%,
                rgba(#000, 0.03) 100%
            );
            background-repeat: no-repeat;
            background-size: 342px 181px;
            animation: shimmer 2s linear infinite;
            overflow: hidden;
        }
    }
}

.property-card__loader-header {
    display: block;
    background-color: $clear-cloud;
    width: 100%;
    height: 181px;
}

.property-card__loader-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 0 50px 0 31px;
    margin: 17px 0 32px;
}

.property-card__loader-body-line {
    background-color: $clear-cloud;
    width: 100%;
    height: 27px;
    margin-top: 16px;

    &:first-child {
        margin-top: 0;
    }
}

.property-card__loader-body-line--cut {
    max-width: 58.3%;
    height: 28px;
}

.property-card__loader-body-line--big {
    height: 33px;
}

.property-card__loader-body-line--large {
    height: 38px;
}

.property-card__loader-body-lines {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 18px;
    margin-top: 16px;

    .property-card__loader-body-line {
        max-width: 47.8%;
        height: 16px;
        margin: 0;

        &:last-child {
            max-width: 38.8%;
        }
    }
}

.property-card__loader-footer {
    display: block;
    background-color: $clear-cloud;
    width: 100%;
    height: 58px;
}

@keyframes shimmer {
    0% {
        background-position: -342px 0;
    }
    25% {
        background-position: 342px 0;
    }
    100% {
        background-position: 342px 0;
    }
}