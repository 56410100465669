// MY PROPERTIES

.indcs__header-wrap {
    button {
        left: 13px;
    }
}

.filters-favorite__search-wrapper {
    margin-bottom: 16px;

    .ant-input-affix-wrapper {
        background: $white;
        border: 1px solid #c9cccf;
        border-radius: 4px;
        height: 36px;
        width: 100%;
        position: relative;
        padding: 4px 36px;
    }

    .ant-input-suffix {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
    }

    .ant-input-group-addon {
        display: none;
    }
}

.my-properties {
    padding: 25px 0 0;
    background-color: $white;
    position: relative;

    max-height: calc(100vh - 256px);
    overflow-y: auto;
    @include scroll-style(transparent, transparent, 0, 0, 0);

    .ant-table {
        @include mq(767px) {
            width: 100%;
            overflow: scroll;
        }
    }
    .ant-table-container {
        @include mq(540px) {
            width: 540px;
        }
    }

    .ant-table-container * {
        font-size: 12px;
    }

    .ant-table-wrapper {
        position: relative;

        .ant-spin-nested-loading,
        .ant-spin-container,
        .ant-table,
        .ant-table-container {
            height: 100%;
        }
    }

    .ant-table-row {
        cursor: pointer;
    }

    // Disable scrollbar
    .ant-table-wrapper::-webkit-scrollbar {
        width: 0;
    }

    .ant-table-thead {
        .ant-table-cell {
            border-top: 1px solid $clear-cloud;
            border-bottom: 1px solid $clear-cloud;
            background-color: $white;
            padding: 8px 5px 5px 2px;
            color: $blue-whale;
            &:first-child {
                padding-left: 29px;
            }

            &:last-child {
                padding-right: 0;
            }

            &::before {
                display: none;
            }

            @include mq(767px) {
                @include mq(590px, min) {
                    width: auto;
                }
            }
        }
    }

    .ant-table table {
        table-layout: auto !important;
    }

    .ant-table-column-sorter {
        position: absolute;
        left: -16px;
        top: 6px;
    }

    .ant-table-thead th.ant-table-column-has-sorters:hover {
        background-color: $white;
    }

    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
        color: $jungle-green2;
    }

    .ant-table-body {
        height: 100%;
        @include scroll-style($boulder, $alabaster, 0, 5px, 0);
    }

    .ant-table-tbody {
        .ant-table-cell {
            padding: 10px 5px 8px 2px;
            border: none;
            color: $blue-whale;
            &:first-child {
                padding-left: 12px;
                // @include mq(767px) {
                //     @include mq(590px, min) {
                //         width: 25%;
                //     }
                // }
            }

            @include mq(767px) {
                @include mq(590px, min) {
                    width: auto;
                }
            }
        }
    }

    .my-properties__address {
        svg {
            margin-right: 4px;
            color: $jungle-green2;
            width: 12px;
            height: 10px;
            margin-bottom: 1px;
        }
    }

    .my-properties__state {
        margin-left: -3px;
    }

    .my-properties__favorite {
        color: $jungle-green2;
        text-decoration: underline;
        margin-left: -5px;
        svg {
            margin-right: 7px;
            color: $jungle-green2;
        }
    }

    .tools-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: -16px;
    }

    .my-properties__notes,
    .my-properties__calculator,
    .my-properties__zip-alerts,
    .my-properties__documents,
    .my-properties__remove {
        display: block;
        margin: 0 0 0 4px;
        color: $jungle-green2;
        position: relative;
        cursor: pointer;
        &.disabled {
            color: $boulder2;

            &::after {
                content: "There are no notes";
            }
        }

        // Style a tooltip on the left side of the icon, with arrowpointing to the icon
        &::before {
            content: "";
            position: absolute;
            top: 37%;
            left: -14px;
            margin-top: -5px;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 9px solid $blue-whale;
            pointer-events: none;
            opacity: 0;
            transition: 200ms ease-in-out;
        }

        &::after {
            content: "View property notes";
            position: absolute;
            top: 12px;
            left: -155px;
            width: 141px;
            height: 44px;
            background-color: $blue-whale;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(-50%);
            pointer-events: none;
            opacity: 0;
            transition: 200ms ease-in-out;
            color: $white;
        }

        &:hover {
            &::before,
            &::after {
                opacity: 1;
            }
        }
    }

    .my-properties__notes {
        margin-left: 0;
    }

    .my-properties__calculator {
        margin-left: 6px;

        &::after {
            content: "Calculator";
        }

        &.disabled {
            color: $boulder2;

            &::after {
                content: "There is no calculator";
            }
        }
    }
    .my-properties__zip-alerts {
        width: 13px;
        svg {
            margin-left: 1px;
        }
        &::after {
            color: $white;
            content: "Turn off weekly alerts" !important;
        }

        &.disabled {
            &::after {
                content: "Turn on weekly alerts" !important;
            }
            svg {
                margin-left: -1px;
            }
        }
    }

    .my-properties__documents {
        &::after {
            color: $white;
            content: "Uploaded documents" !important;
        }

        &.disabled {
            &::after {
                content: "There are no documents" !important;
            }
        }
    }

    .my-properties__remove {
        color: $burn-orange;
        &::after {
            color: $white;
            margin: 0 5px 0 5px;
            content: " Remove from pipeline. " !important;
        }
    }
}

.propertiesOpen {
    @include mq(767px) {
        position: absolute;
        top: 54px;
        z-index: 100;
        height: 100%;
        width: 100%;
        max-height: 100%;
    }
}

.propertiesClosed {
    @include mq(767px) {
        display: none;
    }
}

.my-properties__switch-label {
    color: $boulder2;
    font-size: 12px;
    span{
        margin: 1px;
    }
    span.favorite-count {
        color: $jungle-green2;
        font-weight: bold;
    }
    span.pipeline-over-limit{
        color:$red;
    }
    .pipeline-over-limit_cta{
        color:$red;
        text-decoration: underline;
        cursor: pointer;
    }
}
.lender__filters-collapser {
    margin-left: auto;
    margin-bottom: -11px;
}

.my-properties__switch {
    padding: 0 12px;
    margin-bottom: 11px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .ant-switch {
        min-width: 31px;
        height: 16px;
        background-color: $catskill-white;
        background-image: none;
        margin-right: 5px;
    }

    .ant-switch-handle {
        width: 12px;
        height: 12px;
        top: 2px;
        left: 2px;
    }

    .ant-switch-checked {
        background-color: $jungle-green2;

        .ant-switch-handle {
            left: calc(100% - 13px - 2px);
            background-color: $jungle-green2;

            &::before {
                background-color: $white;
            }
        }
    }
}

.my-properties__skeleton-row {
    display: flex;
    align-items: center;
    padding: 4px 0;
    &:first-child {
        padding-top: 0;
    }
}

.my-properties__skeleton-item {
    display: block;
    width: 100px;
    height: 28px;
    position: relative;
    background-color: $clear-cloud;
    margin-right: 15px;
    flex-shrink: 0;
    &:first-child {
        width: 121px;
    }
    &:nth-child(2) {
        width: 72px;
        &::before {
            animation-delay: 0.1s;
        }
    }
    &:nth-child(3) {
        width: 48px;
        &::before {
            animation-delay: 0.15s;
        }
    }
    &:nth-child(4) {
        width: 81px;
        &::before {
            animation-delay: 0.2s;
        }
    }
    &:nth-child(5) {
        width: 81px;
        &::before {
            animation-delay: 0.25s;
        }
    }
    &:nth-child(6) {
        width: 35px;
        &::before {
            animation-delay: 0.3s;
        }
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(
            to right,
            rgba(#000, 0.03) 0%,
            rgba(#000, 0.045) 10%,
            rgba(#000, 0.065) 20%,
            rgba(#000, 0.07) 40%,
            rgba(#000, 0.065) 70%,
            rgba(#000, 0.045) 80%,
            rgba(#000, 0.03) 100%
        );
        background-repeat: no-repeat;
        background-size: 342px 181px;
        animation: shimmer 1.7s linear infinite;
        overflow: hidden;
    }
}

.my-properties .ant-table-container {
    .my-properties__no-data-wrapper {
        padding: 23px 31px 0;

        @include mq(440px) {
            padding: 23px 0 0;
        }
    }

    .my-properties__no-data-title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: $blue-whale;
        margin-bottom: 8px;
    }

    .my-properties__no-data-subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: $boulder2;
        margin-bottom: 33px;

        @include mq(640px) {
            margin-bottom: 10px;
        }
    }

    .my-properties__no-data-text {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: $boulder2;
    }

    .my-properties__results-info-text-btn {
        padding: 0;
        border: none;
        background-color: transparent;
        color: #22aa94;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        text-decoration-line: underline;
        transition: 0.2s ease;

        &:hover {
            color: #757575;
        }
    }
}

@keyframes shimmer {
    0% {
        background-position: -342px 0;
    }
    25% {
        background-position: 342px 0;
    }
    100% {
        background-position: 342px 0;
    }
}

// Filters

.filters-favorite__container {
    background-color: $white;
    padding: 10px 16px 15px 12px;
}

.filters-favorite__wrapper {
    background-color: $alabaster-second;
    padding: 15px;
    transition: opacity 0.2s ease, visibility 0.2s ease, height 0.3s ease,
        margin-bottom 0.3s ease;
    visibility: visible;
    opacity: 1;

    .lender__submit-btn {
        display: block;
        margin-left: 0;
    }
}

.filters-favorite__filters-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mq(767px) {
        justify-content: flex-start;
        gap: 11px;
    }

    .form-element {
        width: 228px;
        margin-bottom: 16px;

        @include mq(767px) {
            width: 49%;
        }

        @include mq(607px) {
            width: 100%;
            margin-bottom: 0;
        }

        label:not(.ant-radio-wrapper) {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: $blue-whale;
            margin-bottom: 8px;
            display: block;

            @include mq(607px) {
                margin-bottom: 3px;
            }
        }
    }

    .ant-select {
        width: 100%;
        height: 36px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        height: 100% !important;
        background: #ffffff;
        border: 1px solid #c9cccf;
        border-radius: 4px;
    }

    .ant-select-selection-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .select-options {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $boulder2;
        }
    }

    .ant-select-item-option {
        padding: 6px 15px;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-top: 3px;
    }

    .ant-radio-inner {
        border: 2px solid $silver;
        width: 18px;
        height: 18px;
    }

    .ant-radio-checked .ant-radio-inner {
        border: 2px solid $blue-whale;
        width: 18px;
        height: 18px;
    }

    .ant-radio-inner::after {
        background-color: $blue-whale;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border-color: #c9cccf;
        box-shadow: none;
    }

    .ant-radio:hover .ant-radio-inner {
        border-color: $jungle-green;
    }

    .execution-type-wrapper {
        .execution-type .ant-select-item {
            padding: 6px 0;
        }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $jungle-green2;
        border-color: $jungle-green2;
    }

    .ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: $jungle-green2;
    }

    .ant-checkbox-checked::after {
        display: none;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: $gray;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner {
        border-color: $jungle-green2;
    }
}

.collapsed .filters-favorite__wrapper {
    height: 0;
    margin-bottom: -26px;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
}

.favorite_filters__reset {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $jungle-green2;
    text-decoration: underline;
    margin-right: 24px;
    cursor: pointer;
}
.favorite_filters__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
    padding-right: 11px;
}
.tag-type-wrapper {
    .ant-select .ant-select-selection-placeholder {
        padding-top: 2px;

        @include mq($md) {
            padding-right: 15px;
        }
    }

    .brokers-dropdown .ant-checkbox-wrapper {
        padding: 2px 13px 0px 30px;
    }

    .ant-select-arrow {
        z-index: 2;
    }

    .ant-select-selector {
        max-width: 229px;

        @include mq($md) {
            max-width: 100%;
        }
    }

    .ant-select-multiple {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 3px;
            right: 10px;
            height: 84%;
            width: 10%;
            background-color: $white;
            filter: drop-shadow(-10px 0px 5px rgba($white, 1));
        }
    }

    .ant-select-selection-overflow {
        display: inline-flex;
        flex-wrap: nowrap;
        overflow: hidden;
        position: relative;
        max-width: 229px;
    }

    .ant-select-disabled {
        opacity: 0.5;
        .ant-select-selection-overflow::after {
            display: none;
        }
    }

    .ant-select-selection-overflow-item:not(
            .ant-select-selection-overflow-item-suffix
        ) {
        margin-left: 3px;
        &::after {
            content: ", ";
        }

        &:nth-last-child(2):after {
            content: "";
        }
    }

    .all-tag-selected,
    .ant-select-selection-overflow {
        color: #757575;
    }
}

.all-selected .ant-select-selection-overflow-item:not(:first-child) {
    display: none;
}

.all-selected .ant-select-selection-overflow-item {
    &::after {
        content: "" !important;
    }
}
.tag-type-wrapper 
.ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix)::after {
    content: ", ";
}

.tag-type {
    .ant-select-item {
        padding: 6px 0;
    }

    &.brokers-dropdown {
        box-shadow: none;

        .ant-checkbox-wrapper {
            padding: 4px 14px 0px 30px;
            pointer-events: none;
        }
    }
}
