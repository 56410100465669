.investment-tracker {
    max-width: 758px;
    width: 100%;
    height: calc(100% - 70px);
    background-color: $white;
    position: absolute;
    top: 70px;
    left: 0;
    z-index: 200;
    padding-bottom: 110px;

    @include mq(767px) {
        height: 100%;
        padding-bottom: 173px;
    }
}

.investment-tracker__header {
    min-height: 56px;
    background-color: #eff7f9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
}

.investment-tracker__header-title-logo {
    color: $blue-whale;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin-right: 15px;
}

.investment-tracker__header-title-text {
    color: $blue-whale;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.investment-tracker__header-close {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.investment-tracker__body {
    // position: relative;
    padding: 15px 15px;
    height: calc(100% - 56px);
    overflow-x: hidden;
    overflow-y: auto;

    @include mq(640px, min) {
        @include scroll-style();
    }
}

.investment-tracker__property-info-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 32px;
}
.investment-tracker__property-address {
    color: $blue-whale;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-right: 15px;

    svg {
        margin-right: 8px;
        font-size: 16px;
    }
}

.investment-tracker__property-type {
    display: inline-flex;
    padding: 6px 12px;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    border-radius: 8px;
    background-color: #e4f2f0;
    color: $blue-whale;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
}

.investment-tracker__investment-details-title,
.investment-tracker__track-distribution-title {
    color: $boulder2;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    font-family: $font-primary;
    margin-bottom: 20px;
    width: 100%;

    &.ledger-title {
        margin-left: 16px;
    }
}

.investment-tracker__track-distribution-title {
    margin-bottom: 16px;
}

.investment-tracker__investment-details-nums-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 26px;

    &:nth-child(odd) {
        padding-left: 18px;
        @include mq(415px) {
            padding-left: 0;
        }
    }

    @include mq(600px) {
        width: 33.33%;
    }

    @include mq(415px) {
        width: 100%;
    }
}

.investment-tracker__investment-details-nums-upper {
    color: $blue-whale;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 8px;
}

.investment-tracker__investment-details-nums-lower {
    color: $boulder2;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.investment-tracker__investment-details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 4px;

    @include mq(600px) {
        flex-direction: column;
    }
}

.investment-tracker__investment-details-left {
    width: 50%;

    @include mq(600px) {
        width: 100%;
    }
}

.investment-tracker__investment-details-left {
    display: flex;
    flex-wrap: wrap;
}

.investment-tracker__investment-details-right {
    border-radius: 8px;
    background: rgba(150, 204, 212, 0.15);
    width: 354px;
    min-height: 106px;
    margin-left: 8px;
    display: flex;
    align-items: flex-start;
    padding: 21px 20px 10px 16px;

    @include mq(600px) {
        width: 100%;
        margin-left: 0;
    }

    @include mq(415px) {
        padding: 10px 5px 10px 10px;
        align-items: center;
    }
}

.investment-tracker__investment-details-input-wrapper {
    padding-right: 17px;
    position: relative;
    &::after {
        content: "";
        width: 1px;
        height: 80px;
        background-color: rgba($black, 0.15);
        position: absolute;
        right: 0;
        top: -7px;
    }

    @include mq(415px) {
        padding-right: 5px;
        max-width: 155px;
        &::after {
            display: none;
        }
    }
}

.investment-tracker__investment-details-input-title,
.investment-tracker__investment-details-percentage-title {
    color: $blue-whale;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: block;
}

.investment-tracker__investment-details-input-subtitle {
    color: $boulder2;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.investment-tracker__investment-details-percentage-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: 18px;

    @include mq(415px) {
        padding-left: 5px;
    }
}

.investment-tracker__investment-details-percentage-value {
    color: $blue-whale;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin-top: 19px;

    &.no-value {
        margin-top: 27px;
        text-align: center;
        color: #757575;
        max-width: 110px;
        font-size: 12px;
    }
}

.investment-tracker__investment-details-input-holder {
    max-width: 148px;

    .form-element {
        margin-bottom: 0;
    }
}

.investment-tracker__track-distribution-inputs-wrapper {
    display: flex;
    flex-wrap: wrap;

    .form-element.dropdown {
        color: $blue-whale;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        label {
            display: block;
            margin-top: 2px;
            margin-bottom: 11px;
            color: $blue-whale;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
    }

    .ant-select {
        width: 148px;
        height: 36px;

        @include mq(401px) {
            margin-right: 0;
            width: 100%;
        }

        .ant-select-selector {
            height: 100%;
            border-radius: 4px;
            border: 1px solid #c9cccf;
        }

        .ant-select-selection-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .select-options {
            color: $boulder2;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
    }
}

.investment-tracker__track-distribution-inputs-holder {
    margin-right: 24px;
    margin-bottom: 20px;

    @include mq(401px) {
        margin-right: 0;
        width: 100%;

        .ant-space {
            width: 100%;
        }
    }
}

.investment-tracker__track-distribution-inputs-holder.target-amount {
    .form-element--input {
        margin-top: 0;
    }

    label {
        color: $blue-whale;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 3px;
        display: block;
        margin-top: 2px;
        margin-bottom: 11px;
    }

    .form-element__inner {
        width: 148px;

        @include mq(401px) {
            margin-right: 0;
            width: 100%;
        }
    }
}

.investment-tracker__track-distribution-inputs-holder.alerts {
    padding-left: 6px;
    margin-right: 0;
    .ant-radio-group {
        display: flex;
    }
    .form-element--input {
        margin-top: 0;
    }

    label {
        color: $blue-whale;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 3px;
        display: block;
        margin-top: 2px;
        margin-bottom: 11px;
        display: flex;
        align-items: center;
    }

    .ant-radio + span {
        color: $blue-whale;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-top: 7px;
    }

    .ant-radio-inner {
        border-radius: 4px;
        border: 2px solid #c3c3c3;
        width: 18px;
        height: 18px;

        &::after {
            content: "";
            position: absolute;
            top: 8px;
            left: 12px;
            width: 6px;
            height: 10px;
            border: solid $white;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
            background-color: transparent;
            border-radius: 0;
        }
    }

    .ant-radio-checked span {
        background-color: $blue-whale;
        border: 2px solid $blue-whale;
    }

    .form-element--radio {
        @include mq(767px) {
            margin-top: 0;
        }
    }

    .label-tooltip__text {
        @include mq(711px) {
            left: -58px;

            &:before {
                left: 29%;
            }
        }

        @include mq(425px) {
            left: -97px;

            &:before {
                left: 46%;
            }
        }

        @include mq(403px) {
            left: -47px;

            &:before {
                left: 24%;
            }
        }
    }
}

.investment-tracker__ledger {
    width: calc(100% + 30px);
    margin-left: -15px;
}

.ledger-table-wrapper {
    .ant-table-body,
    .ant-table-content {
        @include scroll-style();
    }
    .ant-table-thead > tr > th {
        background-color: $white;
        border-top: 1px solid #f0f0f0;
        padding: 7px 15px;
        font-size: 12px;
        color: $blue-whale;

        &::before {
            display: none;
        }
    }

    .ant-table-tbody tr:nth-child(even) {
        background-color: #f7f7f7;
    }

    .ant-table-tbody > tr > td {
        padding: 10px 16px;
        border: none;

        .has-note,
        .no-note {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .ledger-table-notes-text {
            text-wrap: nowrap;
            width: 150px;
            // overflow: hidden;
            display: block;
            color: $blue-whale;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }

        .no-note .ledger-table-notes-text {
            width: 70px;
            color: $boulder2;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background-color: transparent;
            border: none;
            padding: 0;
            height: auto;

            & + .ant-select-arrow {
                right: 0;
            }
        }
    }

    .datepicker.table-datepicker {
        height: 20px;
        padding: 0 5px;
        font-size: 13px;
        width: 87px;

        .ant-picker-suffix {
            display: none;
        }

        & > * {
            font-size: 13px;
        }
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
        color: $blue-whale;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 149px;
    }

    .ledger-table-amount-value {
        color: $blue-whale;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;

        & + svg {
            color: $boulder2;
            margin-left: 5px;
            cursor: pointer;

            &:hover {
                color: $blue-whale;
            }
        }
    }

    .ledger-table-notes-text {
        color: $blue-whale;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        & svg {
            color: $boulder2;
            margin-left: 5px;
            cursor: pointer;

            &:hover {
                color: $blue-whale;
            }
        }
    }

    .ledger-table-date-text {
        color: $blue-whale;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        & + svg {
            color: $boulder2;
            margin-left: 5px;
            cursor: pointer;

            &:hover {
                color: $blue-whale;
            }
        }
    }

    .ant-table-cell .form-element--input {
        max-width: 120px;
        width: 100%;
        margin-top: 0;
        input {
            height: 20px;
            padding: 0px 8px 0px 20px;
            font-size: 13px;
        }

        .form-element__prefix {
            left: 7px;
            top: 1px;
            font-size: 12px;
        }
    }

    .ant-select-item {
        padding: 6px 5px;
    }

    // .ant-select-single.ant-select-show-arrow {
    //     width: 150px;
    // }

    .form-element {
        margin-bottom: 0;
    }

    .ant-skeleton-element .ant-skeleton-input {
        width: 121px;
        min-width: 121px;
    }
}

.investment-tracker__add-transaction {
    color: $jungle-green2;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 8px;
    border: 0;
    padding: 0;
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
    transition: color $dur $ease;

    &:hover {
        color: $blue-whale;
    }
}

.investment-tracker__add-transaction--alt {
    margin-left: 0;
    font-size: 14px;
    margin-left: 16px;
    margin-top: 18px;
}

.delete-ledger-btn {
    cursor: pointer;

    &:hover svg {
        transition: color $dur $ease;
        color: $jungle-green2;
    }
}

.ant-popover-title {
    color: $blue-whale;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
}

.delete-ledger-row-button {
    background-color: $blue-whale;
    border: none;
    font-size: 12px;
    color: $white;
    padding: 5px 15px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color $dur $ease;

    &.ant-btn-loading-icon + span {
        color: $blue-whale;
    }

    &:hover {
        background-color: $jungle-green2;

        span {
            color: $white;
        }
    }
}

.investment-tracker__toggle {
    width: calc(100% - 30px);
    min-height: 78px;
    border-radius: 8px;
    background: var(--neutral-highlight, rgba(150, 204, 212, 0.15));
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 16px;
    left: 15px;
    padding: 16px;

    @include mq(767px) {
        padding: 5px 10px;
        bottom: 84px;
    }
}

.investment-tracker__toggle-title {
    color: $blue-whale;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    display: block;
    margin-bottom: 5px;

    @include mq(767px) {
        font-size: 14px;
    }
}

.investment-tracker__toggle-subtitle {
    color: $boulder2;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    @include mq(767px) {
        font-size: 12px;
    }
}

.investment-tracker__toggle-container {
    margin-right: 8px;
    .ant-switch {
        min-width: auto;
        width: 31px;
        height: 16px;
        background-color: $jungle-green2;
    }
    .ant-switch-handle {
        top: 2px;
        left: 2px;
    }

    .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 12px - 2px);
    }
    .ant-switch-handle::before {
        width: 12px;
        height: 12px;
        background-color: $white;
    }
}
