.transaction-popup,
.transaction-popup-overlay {
    position: absolute;

    .popup__close {
        top: 24px;
        right: 42px;
    }
}

.transaction-popup-overlay {
    padding: 15px;
}

.transaction-popup {
    max-width: 458px;
    padding: 28px 42px 1px;
    height: auto;

    @include mq(464px) {
        max-width: 100%;
        padding: 28px 15px 1px;
    }

    .popup-scrollable {
        margin-bottom: 42px;
    }
}
.transaction-popup__title {
    color: $blue-whale;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    margin-bottom: 37px;
}

.transaction-popup__inputs-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    @include mq(464px) {
        flex-direction: column;
        margin-bottom: 6px;
    }

    &.add-transaction-amount {
        width: 180px;

        @include mq(464px) {
            width: 100%;
        }
    }

    .ant-select {
        width: 180px;

        @include mq(464px) {
            width: 100%;
        }

        .ant-select-selector {
            border-radius: 4px;
            border: 1px solid #c9cccf;
            height: 36px;
            padding: 2px 11px;

            .ant-select-selection-item,
            .select-options {
                color: $boulder2;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                display: flex;
                align-items: center;
            }
        }

        & + .form-element__error-msg {
            position: absolute;
            bottom: -15px;
            left: 0;
            color: $burn-orange;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
        }
    }

    .ant-select-item {
        padding: 6px 15px;
    }

    .ant-space {
        gap: 0 !important;
    }

    .form-element label,
    .datepicker-label {
        color: $blue-whale;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 4px;
        display: block;
    }

    .form-element.locked label {
        color: $boulder2;
    }

    input[name="Amount"] {
        width: 180px;
    }

    .form-element {
        margin-bottom: 0;
        position: relative;

        @include mq(464px) {
            margin-bottom: 20px;
        }
    }

    textarea {
        font-family: $font-third;
    }
}

.transaction-popup__datepicker {
    width: 180px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid #c9cccf;
    background-color: #fff;
    color: $boulder2;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding: 0 6px;

    @include mq(464px) {
        width: 100%;
    }
}

.form-element-textarea {
    width: 100%;
    margin-top: 9px;
    label {
        color: $blue-whale;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 4px;
    }

    @include mq(464px) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.transaction-popup__loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    .loader {
        display: block;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        position: relative;
        animation: rotate 1s linear infinite;
    }
    .loader::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 5px solid $main-green;
        animation: prixClipFix 2s linear infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes prixClipFix {
        0% {
            clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
        }
        25% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
        }
        50% {
            clip-path: polygon(
                50% 50%,
                0 0,
                100% 0,
                100% 100%,
                100% 100%,
                100% 100%
            );
        }
        75% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
        }
        100% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
        }
    }
}

.text-area-char-limit {
    font-size: 12px;
    line-height: 16px;
    color: #757575;

    &.limit-reached {
        color: $burn-orange;
    }
}
