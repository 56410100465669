// Filters head
.filters__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px 10px;
    position: relative;
}

.filters__title {
    font-family: $font-primary;
    font-size: 14px;
    line-height: 14px;
    color: $blue-whale;
    text-transform: uppercase;
}

.filters__clear-link {
    font-family: $font-primary;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $jungle-green2;
    text-decoration: underline;
    cursor: pointer;
}

// Checkbox
.filters {
    margin: 0 -14px;
    height: calc(100vh - 269px);
    overflow-y: auto;
    position: relative;

    &.has-shadow + div {
        position: relative;
        .box-shadow {
            position: absolute;
            height: 12px;
            top: -11px;
            right: 5px;
            left: 0;
            box-shadow: 0 -5px 6px 0 rgb(0 0 0 / 5%) inset;
        }
    }

    &.has-top-shadow {
        box-shadow: 0 -6px 10px 0 rgba(0, 0, 0, 0.12) inset;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        box-shadow: none;
        border: none;
    }
}

.filters__form-checkboxes {
    padding: 6px 0;
    .form-element {
        padding: 4px 8px 4px 10px;
        margin-bottom: unset;
        &:last-of-type {
            margin-bottom: 0;
        }

        &.disabled {
            pointer-events: none;
            user-select: none;

            .ant-checkbox span,
            .filters__form-checkbox span {
                color: $gray;
            }

            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: $gray;
                border: 2px solid $gray;
            }

            .ant-checkbox-checked::after {
                border: 2px solid $blue-whale;
            }
        }
    }
}

.ant-checkbox,
.filters__form-checkbox {
    span {
        font-family: $font-primary;
        font-size: 14px;
        line-height: 20px;
        color: $blue-whale;
        margin-bottom: -1px;
    }

    .ant-checkbox-inner {
        border: 2px solid $silver;
        border-radius: 4px !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $blue-whale;
        border: 2px solid $blue-whale;
    }

    .ant-checkbox-checked::after {
        border: 2px solid $blue-whale;
    }

    .ant-checkbox-inner::after {
        left: 20.5%;
    }

    &:hover {
        .ant-checkbox-inner {
            border-color: $blue-whale;
        }
    }
}

.filters__dropdown-wrap {
    background-color: $white;
}

.filters__dropdowns {
    &:last-of-type {
        margin-bottom: 32px;
    }

    &.ant-collapse-item-active {
        .ant-collapse-header {
            background-color: $white;
        }
    }

    &.ant-collapse-item-disabled {
        .ant-collapse-header-text {
            color: #97abad;
        }

        .filters__dropdown-icon svg {
            color: #97abad;
            fill: #97abad;
        }

        .ant-select-selection-placeholder,
        .ant-select-selection-item-content,
        .filters__count {
            color: #cbcccb;
        }

        .filters-dropdown__icon {
            svg {
                fill: #cbcccb;
                color: #cbcccb;
            }
        }
    }

    // filter info icon
    .ant-collapse-extra {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(109px, -50%);
        z-index: 9;
    }
}

.filte-info-button {
    @include hover {
        svg {
            color: $blue-whale;
        }
    }
}

.filter-info-popup {
    background-color: $blue-whale;
    width: 213px;
    padding: 8px;
    color: $white;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.5;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 24px);
    opacity: 0;
    visibility: hidden;
    user-select: none;
    pointer-events: none;
    transition: $dur $ease;
    z-index: 999999999;

    &:after {
        content: "";
        position: absolute;
        border: 1px solid rgba(51, 51, 51, 0.19);
        border-color: $blue-whale transparent;
        border-width: 0 6px 6px 6px;
        top: -6px;
        left: 54px;
    }

    &:before {
        content: "";
        position: absolute;
        border: 1px solid $blue-whale;
    }

    &.price {
        right: -95px;
        left: unset;
        transform: none;
        top: -18px;
        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-width: 9.5px 15px 9.5px 0;
            border-color: transparent $blue-whale transparent transparent;
            border-style: solid;
            left: -11px;
            top: 16px;
        }
    }
}

.filters__dropdown-icon {
    transition: 0.3s ease;
    svg {
        display: block;
        height: 14px;
        width: 13px;
        fill: $blue-whale;
        color: $blue-whale;
    }
}

// Range
.filters__range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
        font-family: $font-primary;
        font-size: 14px;
        line-height: 14px;
        color: $boulder2;
        text-transform: uppercase;
    }
}

.filters__price-inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .form-element {
        width: 160px;
    }
    input,
    .form-element__prefix {
        font-size: 14px;
    }
}

// Select
.filters__select-title {
    display: flex;
    align-items: center;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: $blue-whale;
    margin-bottom: 12px;
    transition: 300ms $ease;
    width: 100%;
    text-align: left;

    &.large {
        font-size: 14px;
        margin-bottom: 0;
    }

    &.brokerages {
        margin-bottom: 12px;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }

    &.disabled {
        pointer-events: none;
        user-select: none;
        opacity: 0.6;
    }
}

.filters__select-legend {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    &:not(.filters__select-legend--listing) {
        width: 14px;
        height: 14px;
        border-radius: 100%;
        border: 1px solid $white;
    }
    &--listing {
        width: 32px;
        height: 14px;
        border-radius: 15px;
        font-weight: 500;
        font-size: 8px;
        line-height: 16px;
    }
    &--sale {
        background-color: #459689;
    }
    &--finance {
        background-color: #37b1c3;
        margin-left: 0px;
        position: relative;
        right: -8px;
        margin-left: 0;
    }
}

.filters__select {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #fafafa;
        border: none;
    }

    .ant-select-selection-placeholder {
        font-family: $font-primary;
        font-size: 14px;
        line-height: 14px !important;
        color: $boulder;
        left: 23px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: auto;
        padding: 9px 17px;
    }

    .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector
        .ant-select-selection-search-input {
        height: auto;
    }

    .ant-select {
        border-bottom: 1px solid $mercury;
        margin-bottom: 16px;
        width: 184px !important;

        &.ant-select-open {
            border-bottom: 1px solid $blue-whale;

            .select-icon {
                svg {
                    fill: $blue-whale;
                    color: $blue-whale;
                }
            }
        }
    }

    .ant-select-selection-item {
        font-family: $font-primary;
        font-size: 14px;
        line-height: 14px !important;
        color: $blue-whale;
        display: flex;
    }

    .ant-select-selection-search {
        top: 50% !important;
        transform: translateY(-50%);
    }

    &.filters__select--icon {
        .ant-select-selector {
            cursor: context-menu;

            &::before {
                content: "";
                background-position: center;
                background-size: contain;
                height: 14px;
                width: 13px;
                margin-right: 8px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23777777' viewBox='0 0 448 512'%3E%3Cpath d='M96 288C96 270.3 110.3 256 128 256C145.7 256 160 270.3 160 288C160 305.7 145.7 320 128 320C110.3 320 96 305.7 96 288zM320 352C337.7 352 352 366.3 352 384C352 401.7 337.7 416 320 416C302.3 416 288 401.7 288 384C288 366.3 302.3 352 320 352zM192 288C192 274.7 202.7 264 216 264H328C341.3 264 352 274.7 352 288C352 301.3 341.3 312 328 312H216C202.7 312 192 301.3 192 288zM232 360C245.3 360 256 370.7 256 384C256 397.3 245.3 408 232 408H120C106.7 408 96 397.3 96 384C96 370.7 106.7 360 120 360H232zM152 64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0C141.3 0 152 10.75 152 24V64zM48 448C48 456.8 55.16 464 64 464H384C392.8 464 400 456.8 400 448V192H48V448z' /%3E%3C/svg%3E");
                transition: $dur $ease;
                margin-top: -4px;
            }
        }
    }

    .property-types {
        &.property-types--focused {
            .ant-select {
                border-color: $blue-whale;
            }
            .ant-select-show-search {
                .ant-select-selection-item {
                    display: none;
                }
                .ant-select-selection-search {
                    margin-left: 12px;
                }
                & + .filters__count {
                    display: none;
                }
            }
        }
        .ant-select-open + .filters-dropdown__icon {
            transform: translateY(-50%) rotate(180deg);
            top: 28px;
            svg {
                fill: $blue-whale;
                color: $blue-whale;
            }
        }

        .ant-select-open {
            .ant-select-selection-placeholder,
            .ant-select-selection-item-content {
                color: $blue-whale;
            }
        }
    }

    .ant-select-selector {
        display: flex;
        align-items: center;
        width: 100%;
        box-shadow: none !important;
    }

    .ant-select-selection-item-content {
        color: $boulder;
        padding-right: 58px;
        line-height: 1.5;
    }

    .ant-select-selection-overflow {
        height: 100%;
    }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: normal !important;
    background-color: rgba(150, 204, 212, 0.15) !important;
    color: $blue-whale;
}

.ant-select-item-option-selected {
    .select-options {
        font-weight: 500 !important;
    }
}

.select-icon {
    svg {
        fill: $boulder;
        color: $boulder;
        height: 14px;
        transition: $dur $ease;
    }
}

// Options
body {
    .ant-select-dropdown {
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
            0 2px 2px 0 rgba(0, 0, 0, 0.24);
    }

    .ant-select-item {
        padding: 6px 30px;
    }

    .select-options {
        font-family: $font-primary;
        font-size: 14px;
        line-height: 20px;
        color: $blue-whale;

        &.select-options--common {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #03363d;
        }
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: rgba(150, 204, 212, 0.15);
    }
}

.select-options__divider {
    height: 1px;
    padding: 0 0;
    line-height: 0;
    display: block;
    min-height: 1px;
    border-top: 1px solid #e0e0e0;
    width: 200px;
    margin: 6px auto -8px;
    @include browser-support(firefox) {
        width: 198px;
    }
}

// filters select dropdown
.filters__select {
    position: relative;
    padding: 11px 18px 21px;
    border-radius: 8px;
    background-color: transparent;
    transition: $dur $ease;

    &:first-child {
        margin-left: -11px;
    }

    &:nth-child(3) {
        margin-right: -11px;
    }

    @include hover {
        background-color: rgba(150, 204, 212, 0.15);
    }

    .form-element {
        width: 184px;
        margin-bottom: 0;
    }

    .ant-select-selection-overflow-item {
        overflow: hidden;
    }

    .ant-select-selection-search-mirror {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .property-types {
        .ant-select-selection-search-input {
            width: 150px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .ant-select {
        width: 100%;
        margin-bottom: 0;
        transition: $dur $ease;
    }

    .ant-select-selector {
        height: 52px;
    }

    .ant-select-selection-item {
        padding: 0;
        padding-left: 15px;
        margin: 0;
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
    }

    .select-options {
        color: $boulder2;
    }

    .ant-select-open {
        .ant-select-arrow {
            transform: rotate(180deg);
        }

        .select-options {
            color: $blue-whale;
        }

        .ant-select-selector {
            box-shadow: none;
            border: none;

            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2303363D' viewBox='0 0 448 512'%3E%3Cpath d='M96 288C96 270.3 110.3 256 128 256C145.7 256 160 270.3 160 288C160 305.7 145.7 320 128 320C110.3 320 96 305.7 96 288zM320 352C337.7 352 352 366.3 352 384C352 401.7 337.7 416 320 416C302.3 416 288 401.7 288 384C288 366.3 302.3 352 320 352zM192 288C192 274.7 202.7 264 216 264H328C341.3 264 352 274.7 352 288C352 301.3 341.3 312 328 312H216C202.7 312 192 301.3 192 288zM232 360C245.3 360 256 370.7 256 384C256 397.3 245.3 408 232 408H120C106.7 408 96 397.3 96 384C96 370.7 106.7 360 120 360H232zM152 64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0C141.3 0 152 10.75 152 24V64zM48 448C48 456.8 55.16 464 64 464H384C392.8 464 400 456.8 400 448V192H48V448z' /%3E%3C/svg%3E");
            }
        }

        + .filters__count {
            color: $blue-whale;

            + .filters-dropdown__icon {
                transform: translateY(-50%) rotate(180deg);
                top: 28px;
                svg {
                    fill: $blue-whale;
                    color: $blue-whale;
                }
            }
        }
    }

    .ant-select-selection-item-remove {
        display: none;
    }

    .ant-select-selection-search {
        position: absolute;
        width: 100%;
        height: 100%;
        max-width: 165px;
    }

    .ant-select-selection-search-input {
        height: 100% !important;
        width: 100%;
    }
}

.filters__count {
    position: absolute;
    top: 50% !important;
    right: 0;
    background-color: transparent;
    padding: 0 2px;
    border-radius: 0;
    transform: translate(-29px, -50%) !important;
    color: $boulder;
    line-height: 1;
}

.filters-dropdown__icon {
    position: absolute;
    top: 19px;
    right: 14px;
    height: 14px;
    pointer-events: none;
    line-height: 1;

    svg {
        height: 14px;
        fill: $boulder;
        color: $boulder;
    }
}

.brokers-dropdown {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.24);
    padding: 0;

    label {
        position: relative;
        margin-bottom: 10px;
        margin-right: 0;
    }

    .ant-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        display: block;
        max-height: 270px;
        overflow-x: auto;
        @include scroll-style($boulder, $alabaster, 10px, 10px);
    }

    .ant-checkbox-group-item {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;

        span {
            word-break: break-word;
            line-height: 1.1;
            font-size: 12px;
        }

        @include hover {
            background-color: rgba(150, 204, 212, 0.15);
        }
    }

    .ant-checkbox {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
    }

    .ant-checkbox-wrapper {
        padding: 7px 14px 7px 30px;
        background-color: transparent;
        margin: 0;

        &:hover {
            .ant-checkbox-inner {
                border-color: $blue-whale;
            }
        }
    }

    .ant-checkbox-inner {
        border: 2px solid $mercury;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        border: 2px solid $blue-whale;
        background-color: $blue-whale;
    }

    .ant-checkbox-checked::after {
        border: 2px solid $blue-whale;
    }

    .ant-checkbox-inner::after {
        left: 20.5%;
    }
}

.form-element {
    margin-bottom: 16px;

    @include mq($lap-large) {
        margin-bottom: 0;
    }
}

.ant-select-disabled {
    .ant-select-selector::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='%23CBCBCB'%3E%3C!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M96 288C96 270.3 110.3 256 128 256C145.7 256 160 270.3 160 288C160 305.7 145.7 320 128 320C110.3 320 96 305.7 96 288zM320 352C337.7 352 352 366.3 352 384C352 401.7 337.7 416 320 416C302.3 416 288 401.7 288 384C288 366.3 302.3 352 320 352zM192 288C192 274.7 202.7 264 216 264H328C341.3 264 352 274.7 352 288C352 301.3 341.3 312 328 312H216C202.7 312 192 301.3 192 288zM232 360C245.3 360 256 370.7 256 384C256 397.3 245.3 408 232 408H120C106.7 408 96 397.3 96 384C96 370.7 106.7 360 120 360H232zM152 64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0C141.3 0 152 10.75 152 24V64zM48 448C48 456.8 55.16 464 64 464H384C392.8 464 400 456.8 400 448V192H48V448z'/%3E%3C/svg%3E") !important;
    }
    .select-options {
        color: $cloudy;
    }

    .select-icon svg {
        fill: $cloudy;
        color: $cloudy;
    }
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background: #fafafa;
}

.filters__select-block {
    position: relative;
    display: flex;
    margin-top: -22px;

    .membership-cta {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
        height: 100%;
        width: 100%;
    }
}

.filters__form-ch-wrap {
    position: relative;
    transition: $dur $ease;
    &.disabled {
        .ant-checkbox {
            visibility: hidden;
        }
    }
    &:not(.disabled) {
        @include hover {
            background-color: rgba(150, 204, 212, 0.15);
        }
    }
}

.filter-lock__holder {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: rgba(155, 209, 204, 0.25);
    border-radius: 4px;
    width: 19px;
    height: 19px;
    left: 10px;
    top: 6px;
}

.filter-lock {
    padding: 5px 10px;
    cursor: pointer;

    svg {
        font-size: 11.5px;
        color: $jungle-green2;
    }

    &:hover {
        + .lock-info-popup {
            opacity: 1;
            visibility: visible;
            user-select: all;
            pointer-events: all;
        }
    }
}

.lock-info-popup {
    background-color: $blue-whale;
    width: 213px;
    padding: 8px;
    color: $white;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.5;
    display: inline-block;
    position: absolute;
    left: 33px;
    opacity: 0;
    visibility: hidden;
    user-select: none;
    pointer-events: none;
    transition: $dur $ease;
    z-index: 9;

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-width: 9.5px 15px 9.5px 0;
        border-color: transparent $blue-whale transparent transparent;
        border-style: solid;
        left: -11px;
        top: 25px;
    }

    &:before {
        content: "";
        position: absolute;
        border: 1px solid $blue-whale;
    }
}

.filters__date-range-dds {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 20px 0px 23px;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 11px;
    & > div {
        width: 33.333%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .membership-cta__top,
    .membership-cta__icon {
        margin-bottom: 0;
    }
    .membership-cta {
        flex-direction: row;
        align-items: center;
        position: absolute;
        width: 422px;
        height: 120px;
        top: -10px;
        border-radius: 4px;
        right: 0;
        z-index: 1;
        background-color: rgba(31, 159, 138, 0.08);
        svg {
            color: $jungle-green2;
            margin-right: 8px;
        }
    }
    button {
        font-weight: 500;
    }
    .membership-cta__text {
        display: none;
    }
}

.filters__dropdowns-brokerages {
    display: flex;
    .filters__select {
        background-color: transparent !important;
        padding: 0;
        margin: 0;
        &:first-child {
            margin-right: 32px;
        }
        width: 184px;

        @include mq(767px) {
            width: 47%;
        }

        @include mq(360px) {
            width: 184px;
        }
    }
    .ant-select {
        width: 184px !important;
    }
    .ant-select-open + .filters-dropdown__icon {
        top: 50px !important;
    }
    .filters-dropdown__icon {
        top: 49px;
    }
}

.filters__info-popup-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    position: relative;
    width: 100%;

    &.listings {
        margin-bottom: 0;
    }

    &.disabled {
        .filter-info-icon {
            user-select: none;
            pointer-events: none;

            svg {
                color: $cloudy;
            }
        }
    }
}

.filter-info-icon {
    line-height: 1;
    margin-left: 6px;
    transition: 300ms ease;

    svg {
        color: $gray;
        font-size: 14px;
        transition: $dur $ease;
    }

    &:hover {
        + .filter-info-popup {
            opacity: 1;
            visibility: visible;
            user-select: all;
            pointer-events: all;
        }
    }
}

.filters__dropdowns-wrap {
    display: flex;
    align-items: center;
}

.filters__dropdowns-isMenuCollapsed {
    display: block;
    box-sizing: border-box;
    max-height: 30px;
    width: 0px;

    .filters__acq-beta,
    .filters__dropdown-end {
        display: none;
    }
}

.filters__news-flex {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 465px;
}

.filters__dropdown-end {
    left: 415px;
    font-size: 18px;
    line-height: 22px;
    color: #03363d;
    margin-left: auto;
}

.lender-collapse--calculator .filters__dropdown-end {
    margin-right: 5px;
}

.filters__dropdowns-icon {
    display: inline-block;
    margin-right: 14px;
    svg {
        font-size: 24px;
    }
}

.filters-dropdown__selection {
    margin-bottom: 8px;
    font-size: 12px;
    span {
        color: $blue-whale;
        margin-right: 8px;
    }
    svg {
        color: $blue-whale;
        margin-top: 1px;
        width: 9px;
        height: 14px;
    }
    &:first-child {
        margin-top: 8px;
    }
}

.filters-dropdown__selection-inner {
    display: inline-flex;
    align-items: center;
    background: rgba(150, 204, 212, 0.15);
    border-radius: 4px;
    padding: 4px 8px;
}

.filters-dropdown__selection-icon {
    cursor: pointer;
    display: flex;
}

.checkbox-dropdown__switch {
    position: absolute;
    top: 16px;
    left: 32px;
}

.dropdown-tooltip {
    position: absolute;
    left: 1px;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $boulder2;
    padding: 16px 14px;
    background: rgb(250, 250, 250);
    border-radius: 2px;
    max-width: 236px;
    width: 100%;
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 24%);
    transition: $dur $ease;

    &.visible {
        opacity: 1;
    }
}

.checkbox-dropdown {
    &.disabled-unchecked {
        .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked) {
            opacity: 0.5;
            pointer-events: none;
        }

        .ant-checkbox-wrapper {
            pointer-events: all;
        }
    }
}

.filters-dropdowns {
    .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix) {
        display: none;
    }
    .ant-select-selection-placeholder {
        display: none;
    }
    .ant-select-selection-search-input {
        opacity: 1 !important;
    }

    .ant-select-selection-search-input {
        padding-left: 7px !important;

        &::placeholder {
            font-family: $font-primary;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: $boulder2;
        }
    }
}

.filter-dropdown {
    .ant-select-item-option-state {
        display: none;
    }
    .ant-select-item {
        padding: 0;
    }
    .ant-checkbox-wrapper {
        pointer-events: none;
    }
}

// Remove arrows from input number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.form-element--input {
    position: relative;
    color: $boulder2;
    margin-top: 10px;
    .form-element__inner {
        position: relative;
    }
    label {
        font-family: $font-primary;
        font-weight: 700;
        color: $blue-whale;
        margin-bottom: 6px;
    }
    .form-element__prefix {
        position: absolute;
        left: 13px;
        top: 8px;
        color: $boulder2;
        font-family: $font-primary;
        line-height: 22px;
        font-size: 14px;
    }
    input {
        font-family: $font-primary;
        border-radius: 4px;
        height: 36px;
        color: $boulder2;
        outline: none;
        box-shadow: none;
        border: 1px solid #c9cccf !important;
        padding: 5px 12px 4px 24px;
        box-shadow: none;
        width: 100%;
    }
}

.filters__property-types {
    position: relative;
    &.open {
        .filters__form {
            visibility: visible;
            opacity: 1;
            height: auto;
            pointer-events: all;
        }
        .filters__dropdown-arrow {
            transform: rotate(180deg);
        }
    }
}

.filters__dropdown {
    display: flex;
    align-items: center;
    width: 157px;
    border: 1px solid #c9cccf;
    border-radius: 4px;
    padding: 8px 12px 8px 9px;
    margin-right: 16px;
    cursor: pointer;
}

.filters__dropdown-chbx {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: $blue-whale;
    margin-right: 11px;
    svg {
        width: 11px;
        color: $white;
    }
}

.filters__dropdown-initial,
.filters__dropdown-checked {
    font-size: 14px;
    line-height: 20px;
    color: $boulder2;
}

.filters__dropdown-arrow {
    margin-left: auto;
    transition: $dur $ease;
    svg {
        width: 13px;
        color: $boulder2;
    }
}

.filters__form {
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 44px;
    width: 189px;
    height: 110px;
    background-color: $alabaster-second;
    border-radius: 2px;
    box-shadow: 0px 2px 4px 1px #75757554;
    transition: $dur $ease;
}
