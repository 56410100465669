/*--------------------------------------------------------------
# Firefox
--------------------------------------------------------------*/
@supports (-moz-appearance: none) {
    .autocomplete-search {
        .css-2b097c-container {
            .css-yk16xz-control,
            .css-1pahdxg-control {
                height: 40px;
            }
        }
    }
    .css-tlfecz-indicatorContainer,
    .css-1okebmr-indicatorSeparator {
        display: none !important;
    }

    .css-tj5bde-Svg {
        display: none !important;
    }

    // ABCMonumentGrotesk
    @font-face {
        font-family: "ABCMonumentGrotesk";
        src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Medium.woff")
                format("woff"),
            url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Medium.ttf")
                format("truetype");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "ABCMonumentGrotesk";
        src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Thin.woff")
                format("woff"),
            url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Thin.ttf")
                format("truetype");
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "ABCMonumentGrotesk";
        src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Ultra.woff")
                format("woff"),
            url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Ultra.ttf")
                format("truetype");
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "ABCMonumentGrotesk";
        src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Regular.woff")
                format("woff"),
            url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Regular.ttf")
                format("truetype");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "ABCMonumentGrotesk";
        src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Bold.woff")
                format("woff"),
            url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Bold.ttf")
                format("truetype");
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "ABCMonumentGrotesk";
        src: url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Light.woff")
                format("woff"),
            url("../../assets/fonts/MonumentGrotesk/ABCMonumentGrotesk-Light.ttf")
                format("truetype");
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }
}

/*--------------------------------------------------------------
# Safari
--------------------------------------------------------------*/
html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
}

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        body,
        html,
        div,
        span,
        a,
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-variant: unicase;
            -webkit-font-smoothing: antialiased;
            -moz-font-smoothing: antialiased;
            -ms-font-smoothing: antialiased;
        }
    }

    .filters__range {
        span {
            -webkit-font-smoothing: antialiased;
            -moz-font-smoothing: antialiased;
            -ms-font-smoothing: antialiased;
            font-variant: unicase;
        }
    }
}

.ant-select-dropdown {
    div:first-child {
        width: 100%;
    }
}

/*--------------------------------------------------------------
# Firefox
--------------------------------------------------------------*/
@-moz-document url-prefix() {
    .ant-select-multiple .ant-select-selection-placeholder {
        overflow: visible;
    }
}

.ant-select-multiple .ant-select-selector:after {
    display: none !important;
}

#filters__dropdowns-holder {
    position: relative;
}
.ant-typography {
    &:hover {
        color: #1f9f8a !important;
    }
}
